import React, {useContext, useCallback, useEffect, useState} from 'react';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import {AlertContext} from '../../../../App';

import baseURL from '../../../../configs/baseURL';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

Numeral.locale('pt-br');

const useStyles = makeStyles({
  root: {
    width: '100%',
  },

  image: {
    maxWidth: 100,
    height: 80,
    width: 'auto',
  },

  statusBtnCont: {
    textAlign: 'right',
  },

  statusBtn: {
    margin: 10,
  },
});

const columns = [
  {id: 'order', label: '', width: 50},
  {id: 'img', label: 'Imagem', width: 80, align: 'center'},
  {id: 'name', label: 'Nome', minWidth: 150},
  {id: 'water', label: 'Água', width: 85, align: 'center'},
  {id: 'energy', label: 'Energia', width: 85, align: 'center'},
  {id: 'co2', label: 'CO2', width: 85, align: 'center'},
  {id: 'weight', label: 'Peso', width: 85, align: 'center'},
  {id: 'hide', label: 'Status', width: 75, align: 'center'},
];

const Recyclables = ({history}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showHidden, setShowHidden] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    await api
      .get(`/core/items${showHidden ? '?hidden=true' : ''}`)
      .then(async res => {
        if (res.data) {
          setData(res.data.products);
          setIsLoading(false);
        }
      })
      .catch(e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [showAlert, showHidden]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <Header
        title="Recicláveis"
        btnTxt="Adicionar reciclável"
        btnIcon={<AddIcon />}
        onBtn={() => history.push('/dashboard/zer0/reciclaveis/incluir')}
      />

      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      ...(column.width ? {width: column.width} : {}),
                      ...(column.minWidth ? {minWidth: column.minWidth} : {}),
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                data.map(item => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={item.id}
                      onClick={() =>
                        history.push(`/dashboard/zer0/reciclaveis/${item.id}`)
                      }
                    >
                      <TableCell>{item.order}</TableCell>

                      <TableCell align="center">
                        <img
                          src={`${baseURL.addr}/imgs/products/${item.img}`}
                          alt={item.name}
                          className={classes.image}
                        />
                      </TableCell>

                      <TableCell>{item.name}</TableCell>

                      <TableCell align="center">
                        {Numeral(item.water).format('0,0.[000]')}
                      </TableCell>

                      <TableCell align="center">
                        {Numeral(item.energy).format('0,0.[000]')}
                      </TableCell>

                      <TableCell align="center">
                        {Numeral(item.co2).format('0,0.[000]')}
                      </TableCell>

                      <TableCell align="center">
                        {Numeral(item.weight).format('0,0.[000]')}
                      </TableCell>

                      <TableCell align="center">
                        {item.hide ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box alignItems="center" flex={1} className={classes.statusBtnCont}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.statusBtn}
            onClick={() => setShowHidden(prev => !prev)}
            startIcon={showHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
          >
            {showHidden ? 'Exibir visíveis' : 'Exibir ocultos'}
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default Recyclables;

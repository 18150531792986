// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/background-quem-somos.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".sobre {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n  color: #F6F9F8;\n  background: #13a15a url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: cover;\n  background-position: bottom;\n  min-height: 505px;\n  /* justify-content: space-between; */\n}\n\n.sobre-text,\n.sobre-img {\n  max-width: 450px;\n  align-self: center;\n  justify-content: center;\n  padding: 10px;\n}\n\n.sobre p {\n  /* font-size: 16px; */\n  margin: 20px;\n  text-align: justify;\n}\n\n.img-quem-somos {\n  width: 100%;\n  height: auto;\n}\n", ""]);
// Exports
module.exports = exports;

import React, {useEffect, useState} from 'react';
import {PDFViewer} from '@react-pdf/renderer';

import Grid from '@material-ui/core/Grid';

import Loading from '../../../components/Loading';

import PDF from './NovoNordiskUsersPDF';

const NovoNordiskUsers = ({
  opName,
  start,
  end,
  data = null,
  pegadaAmbiental = {},
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [pegadas, setPegadas] = useState(null);

  useEffect(() => {
    if (result) return;

    const reducedResult = data.reduce((a, c) => {
      const newA = {...a};
      const userNN = c.oiUser.userNN ? c.oiUser.userNN.id : 'Externo';

      newA[userNN] = !newA[userNN]
        ? {[c.product_id]: {name: c.oiItem.name, quantity: c.quantity}}
        : {
            ...newA[userNN],
            [c.product_id]: !newA[userNN][c.product_id]
              ? {name: c.oiItem.name, quantity: c.quantity}
              : {
                  ...newA[userNN][c.product_id],
                  quantity: newA[userNN][c.product_id].quantity + c.quantity,
                },
          };

      return newA;
    }, {});

    const reportPegadas = Object.keys(reducedResult).reduce((acc, cur) => {
      const userPegada = pegadaAmbiental.calculate(reducedResult[cur]);
      acc[cur] = userPegada;
      return acc;
    }, {});

    setResult(reducedResult);
    setPegadas(reportPegadas);
    setIsLoading(false);
  }, [data, pegadaAmbiental, result]);

  return (
    <Grid container direction="column">
      {isLoading ? (
        <Loading />
      ) : pegadas && result ? (
        <PDFViewer style={{height: 600}}>
          <PDF
            opName={opName}
            start={start}
            end={end}
            pAs={pegadas}
            result={result}
          />
        </PDFViewer>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default NovoNordiskUsers;

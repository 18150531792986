import React, {useEffect, useState} from 'react';
import {PDFViewer} from '@react-pdf/renderer';

import Grid from '@material-ui/core/Grid';

import Loading from '../../../components/Loading';

import PDF from './ItemsPDF';

const Items = ({opName, start, end, data = null}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (results) return;

    const reducedResult = data.reduce((a, c) => {
      const newA = {...a};
      newA[c.product_id] = newA[c.product_id]
        ? {
            ...newA[c.product_id],
            quantity: newA[c.product_id].quantity + c.quantity,
          }
        : {name: c.oiItem.name, quantity: c.quantity};

      return newA;
    }, {});

    setResults(reducedResult);
    setIsLoading(false);
  }, [data, results]);

  return (
    <Grid container direction="column">
      {isLoading ? (
        <Loading />
      ) : results ? (
        <PDFViewer style={{height: 600}}>
          <PDF opName={opName} start={start} end={end} result={results} />
        </PDFViewer>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default Items;

import React, {useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';

import Loading from '../../../components/Loading';

const useStyles = makeStyles({
  container: {
    padding: 10,
  },
  csvContainer: {
    padding: 10,
    textDecoration: 'none',
  },
});

Numeral.locale('pt-br');

const headers = [
  {label: 'Data', key: 'date'},
  {label: 'Horário', key: 'time'},
  {label: 'Nome', key: 'name'},
  {label: 'Item', key: 'item'},
  {label: 'Quantidade', key: 'quantity'},
  {label: 'Peso médio', key: 'weight'},
  {label: 'Água', key: 'water'},
  {label: 'Energia', key: 'energy'},
  {label: 'CO2', key: 'co2'},
];

const CSV = ({data = null, pegadaAmbiental = {}}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (results) return;

    const calculatedResult = data.map(r => {
      const pa = pegadaAmbiental.calculateItem(r.product_id, r.quantity);
      return {
        date: format(new Date(r.createdAt), 'dd/MM/yyyy'),
        time: format(new Date(r.createdAt), 'HH:mm'),
        id: r.user_id,
        name: r.oiUser.userInformations.name,
        item: r.oiItem.name,
        quantity: r.quantity,
        weight: Numeral(pa.weight).format('0,0.000'),
        water: Numeral(pa.water).format('0,0.000'),
        energy: Numeral(pa.energy).format('0,0.000'),
        co2: Numeral(pa.co2).format('0,0.000'),
      };
    });

    setResults(calculatedResult);
    setIsLoading(false);
  }, [data, pegadaAmbiental, results]);

  return (
    <Grid container direction="column">
      {isLoading ? (
        <Loading />
      ) : results ? (
        <>
          <Typography variant="h6" className={classes.container}>
            Arquivo gerado com sucesso!
          </Typography>

          <CSVLink
            separator=";"
            filename={`zer0_${format(new Date(), 'yyyy-MM-dd_HH-mm')}.csv`}
            headers={headers}
            data={results}
            className={classes.csvContainer}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
            >
              Download
            </Button>
          </CSVLink>
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default CSV;

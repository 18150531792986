import React, {useContext, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

Numeral.locale('pt-br');

const columns = [
  {
    id: 'createdAt',
    label: 'Data',
    minWidth: 80,
    width: 150,
    format: 'date',
  },
  {
    id: 'quantity',
    label: 'Quantidade',
    width: 110,
    format: 'number',
    align: 'center',
  },
  {id: 'item', label: 'Item', minWidth: 150},
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    // maxHeight: 440,
  },
});

const UserHistory = ({match}) => {
  const showAlert = useContext(AlertContext);
  const {id: userId} = match.params;

  const classes = useStyles();

  const userStatus = useSelector(state => state.Login.status);

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [resultInfo, setResultInfo] = useState({
    filter: '',
    page: 1,
    pages: 1,
    total: 0,
    limit: 100,
    orderBy: 'date',
    sortBy: 'DESC',
  });

  const loadData = useCallback(
    async (page = 1, limit = 100) => {
      setIsLoading(true);

      await api
        .get(
          `/core/oi?${
            userStatus === 2 ? `u=${userId}&` : ''
          }p=${page}&l=${limit}`,
        )
        .then(async res => {
          // console.log('res.data', res.data);
          if (res.data) {
            const {operationItems, ...info} = res.data;
            setResult(operationItems);
            setResultInfo(info);
            setIsLoading(false);
          }
        })
        .catch(e => {
          showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
          setIsLoading(false);
        });
    },
    [showAlert, userId, userStatus],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleChangePage = (event, newPage) => {
    loadData(
      resultInfo.filter,
      newPage + 1,
      resultInfo.limit,
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  const handleChangeRowsPerPage = ({target}) => {
    loadData(
      resultInfo.filter,
      1,
      parseInt(target.value),
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  // return <></>;
  return (
    <>
      <Header title="Histórico de operações do usuário" />

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      ...(column.width ? {width: column.width} : {}),
                      ...(column.minWidth ? {minWidth: column.minWidth} : {}),
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                result.map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map(column => {
                        const {id, align, format: valFormat} = column;

                        const value =
                          column.id === 'item' ? row.oiItem.name : row[id];

                        return (
                          <TableCell key={`${id}.${valFormat}`} align={align}>
                            {valFormat === 'number' ? (
                              Numeral(value).format('0,0')
                            ) : valFormat === 'price' ? (
                              Numeral(value).format('$0,0')
                            ) : valFormat === 'date' ? (
                              format(new Date(value), 'dd/MM/yyyy HH:mm')
                            ) : valFormat === 'bool' ? (
                              <Checkbox checked={!!value} disabled />
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={resultInfo.total}
          rowsPerPage={resultInfo.limit}
          page={resultInfo.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Exibir:"
          // labelDisplayedRows={({from, to, count, page}) => {
          //   console.log('a', a);
          //   console.log('b', b);
          //   console.log('c', c);
          // }}
        />
      </Paper>
    </>
  );
};

export default UserHistory;

import React, {useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';

import Loading from '../../../components/Loading';

const useStyles = makeStyles({
  container: {
    padding: 10,
  },
  csvContainer: {
    padding: 10,
    textDecoration: 'none',
  },
});

Numeral.locale('pt-br');

const headers = [
  {label: 'Matrícula', key: 'id'},
  {label: 'Iniciais', key: 'initials'},
  {label: 'Items', key: 'total'},
  {label: 'Peso médio', key: 'weight'},
  {label: 'Água', key: 'water'},
  {label: 'Energia', key: 'energy'},
  {label: 'CO2', key: 'co2'},
];

const UsersCSVnn = ({data = null, pegadaAmbiental = {}}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (results) return;

    const initials = {};

    const reducedResult = data.reduce((a, c) => {
      const newA = {...a};
      const userNN = c.oiUser.userNN ? c.oiUser.userNN.id : 'Externo';
      initials[userNN] = c.oiUser.userNN ? c.oiUser.userNN.name_initials : '-';

      newA[userNN] = !newA[userNN]
        ? {[c.product_id]: {name: c.oiItem.name, quantity: c.quantity}}
        : {
            ...newA[userNN],
            [c.product_id]: !newA[userNN][c.product_id]
              ? {name: c.oiItem.name, quantity: c.quantity}
              : {
                  ...newA[userNN][c.product_id],
                  quantity: newA[userNN][c.product_id].quantity + c.quantity,
                },
          };

      return newA;
    }, {});

    const usersPA = Object.keys(reducedResult).reduce((acc, cur) => {
      const userPegada = pegadaAmbiental.calculate(reducedResult[cur]);
      acc[cur] = userPegada;
      return acc;
    }, {});

    Object.keys(usersPA).forEach(r => {
      const userTotal = Object.values(reducedResult[r]).reduce(
        (a3, c3) => a3 + c3.quantity,
        0,
      );

      if (userTotal === 0) {
        delete usersPA[r];
      } else {
        usersPA[r].id = r;
        usersPA[r].total = userTotal;
        usersPA[r].initials = initials[r];
      }
    });

    const accumulated = Object.keys(usersPA).map(matr => ({
      id: matr,
      initials: usersPA[matr].initials,
      total: usersPA[matr].total,
      weight: Numeral(usersPA[matr].weight).format('0,0.000'),
      water: Numeral(usersPA[matr].water).format('0,0.000'),
      energy: Numeral(usersPA[matr].energy).format('0,0.000'),
      co2: Numeral(usersPA[matr].co2).format('0,0.000'),
    }));

    setResults(accumulated);
    setIsLoading(false);
  }, [data, pegadaAmbiental, results]);

  return (
    <Grid container direction="column">
      {isLoading ? (
        <Loading />
      ) : results ? (
        <>
          <Typography variant="h6" className={classes.container}>
            Arquivo gerado com sucesso!
          </Typography>

          <CSVLink
            separator=";"
            filename={`zer0_${format(new Date(), 'yyyy-MM-dd_HH-mm')}.csv`}
            headers={headers}
            data={results}
            className={classes.csvContainer}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
            >
              Download
            </Button>
          </CSVLink>
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default UsersCSVnn;

import React, {useRef, useState, useEffect} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import api from '../../services/api';
import whichError from '../../services/whichError';

import Password from './Password';
import Input from '../../components/Input';

const Recover = ({location, history}) => {
  const formRef = useRef(null);

  const query = Object.fromEntries(new URLSearchParams(location.search));
  const {u: userId, k: key} = query;

  const emailPage = !(userId && key);

  const [checkKey, setCheckKey] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [recSent, setRecSent] = useState(false);

  useEffect(() => {
    const testCheckKey = async () => {
      setUserMsg('');

      if (!emailPage) {
        setIsLoading(true);
        const response = await api
          .get(`/recover${location.search}`)
          .catch(err => setUserMsg(whichError(err).errorMsg));

        setIsLoading(false);

        if (!response) return;

        setCheckKey(true);
      }
    };

    testCheckKey();
  }, [location.search, emailPage]);

  const handleSubmit = async (data, {reset}, event) => {
    event.preventDefault();

    setIsLoading(true);
    setUserMsg('');
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      email: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .email('Favor inserir um e-mail válido')
        .required('Favor informar um e-mail válido!'),
    });

    try {
      await schema.validate(data, {abortEarly: false});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      setIsLoading(false);
      return;
    }

    const formData = schema.cast(data);

    const response = await api
      .post('/recover', formData)
      .catch(err => setUserMsg(whichError(err).errorMsg));

    if (response) {
      reset();
      formRef.current.setErrors({});
      setRecSent(formData.email);
    }

    setIsLoading(false);
  };

  const initial = {};

  return (
    <div className="s-a login-background">
      <div className="login-space">
        <div className="login-container">
          <div className="login-content">
            <h1>Recuperação de senha</h1>

            {emailPage ? (
              recSent ? (
                <>
                  <h3>Atenção...</h3>
                  <br />
                  <p>
                    Foi enviado um e-mail para <b>{recSent}</b> com as
                    orientações necessárias para recuperação de senha
                  </p>
                </>
              ) : (
                <Form
                  onSubmit={handleSubmit}
                  ref={formRef}
                  className="login-recover"
                  initialData={initial}
                >
                  <Input name="email" placeholder="E-mail" />

                  <button type="submit" disabled={!!isLoading}>
                    Recuperar
                  </button>
                </Form>
              )
            ) : checkKey ? (
              <Password history={history} userId={userId} checkKey={key} />
            ) : null}

            <div className="user-msg-container">{userMsg}</div>

            {emailPage && (
              <button
                className="btn login-small-btn"
                type="button"
                onClick={() => history.goBack()}
              >
                Voltar
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recover;

import React from 'react';
import {
  Font,
  StyleSheet,
  Document,
  Page,
  Text,
  View,
} from '@react-pdf/renderer';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import Rubik from '../../../../../assets/fonts/Rubik.ttf';
import RubikBold from '../../../../../assets/fonts/Rubik-Bold.ttf';

import Header from './components/Header';
import Title from './components/Title';

Font.register({
  family: 'Rubik',
  fonts: [{src: Rubik}, {src: RubikBold, fontWeight: 600}],
});

Numeral.locale('pt-br');

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Rubik',
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  reportHeader: {
    fontSize: 14,
    marginTop: 15,
    marginBottom: 10,
  },
  content: {
    borderTopWidth: 2,
    paddingVertical: 6,
    fontSize: 11,
  },
  rowItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    marginTop: 8,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ccc',
  },
});

const ItemsPDF = ({opName, start, end, result}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <Header pages />

        <Title name={opName} />

        <View style={styles.reportHeader}>
          <Text>Relatório: Recicláveis</Text>
          <Text>
            Período: {format(new Date(`${start} 00:00`), 'dd/MM/yyyy')}
            {' - '}
            {format(new Date(`${end} 00:00`), 'dd/MM/yyyy')}
          </Text>
        </View>

        <View style={styles.content}>
          {Object.keys(result).map(product => (
            <View key={`${product}`} style={styles.rowItem}>
              <Text>{result[product].name}</Text>
              <Text>{Numeral(result[product].quantity).format('0,0')}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default ItemsPDF;

// useStyles.js

import {makeStyles} from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => {
const useStyles = makeStyles(() => {
  return {
    root: {
      // minWidth: 500,
    },

    title: {
      margin: '115px 0 20px 0',
      letterSpacing: '3.3px',
      textTransform: 'uppercase',
      fontWeight: '100',
      textDecoration: 'underline',
      textUnderlineOffset: '6px',
      textAlign: 'center',
    },

    calendarContainer: {
      padding: 10,
    },

    calendarContent: {
      '& .fc-daygrid-day-number': {
        fontWeight: 'bold',
        color: '#2d4c5e',
        textDecoration: 'underline',
      },
      '& .fc-button-primary': {
        backgroundColor: '#4a7b77 !important',
        border: '1px #dfe0e3 solid !important',
        color: '#dfe0e3 !important',
      },
      '& .fc table th': {
        border: '1px dotted #4a7b77 !important',
        borderTopLeftRadius: '10px !important',
        borderTopRightRadius: '10px !important',
        borderBottom: 'none !important',
        backgroundColor: '#7cb4b5 !important',
        color: '#dfe0e3 !important',
        overflow: 'hidden',
        // height: '30px !important',
      },
      '& .fc table tfoot th': {
        border: 'none !important',
        borderRadius: '10px !important',
      },
      '& .fc table td': {
        border: '1px dotted #4a7b77 !important',
      },
      '& .fc-toolbar-title': {
        fontSize: '12px',
        fontWeight: '100',
        color: `#4a7b77`,
      },
      // '& .fc-event': {
      //   borderBottom: '1px dotted #f00 !important',
      // },
      // '& .fc-dayGridMonth-view .fc-event': {
      //   display: 'none !important',
      // },
      '& .fc-dayGridMonth-view a:hover': {
        color: 'inherit !important',
      },
      '& .fc-dayGridMonth-view .fc-event:hover': {
        background: 'transparent !important',
      },
      '& .fc-daygrid-day': {
        cursor: 'pointer',
      },
    },

    item: {
      width: '100%',
      // fontSize: '1.6vw',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',
    },

    crew: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      overflow: 'hidden',
      justifyContent: 'space-evenly',
      padding: 50,
    },

    member: {
      cursor: 'pointer',
      width: 200,
      zIndex: 2,
      height: 200,
      margin: 20,
      boxShadow: '0px 0px 6px 2px #7cb4b5',
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      '&:hover': {
        backgroundColor: '#1d3b4e !important',
        boxShadow: '0px 0px 6px 2px #1d3b4e !important',
      },
    },

    selectContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      paddingRight: 10,
      height: 55,
    },

    select: {
      margin: '0 10px 40px 10px',
      color: '#7cb4b5',
      padding: '4px 8px',
      borderRadius: 5,
      '& .MuiSelect-icon': {
        color: '#7cb4b5',
      },
    },
  };
});

export default useStyles;

import React from 'react';
import {Element} from 'react-scroll';

import logoPegadaAmbiental from '../../../assets/logo-pegada-branca.png';
import zer0 from '../../../assets/logo-zer0-verde.png';

import './styles.css';

const Marcas = () => {
  return (
    <Element name="marcas" className="marcas">
      <h1 className="section-title white">CONHEÇA MAIS SOBRE DOIS DE NOSSOS PROJETOS</h1>

      <div className="marcas-imgs-container">
        <div className="marcas-imgs">
          <a href="/zero">
            <img src={logoPegadaAmbiental} alt="Pegada Ambiental" />
          </a>
        </div>
        <div className="marcas-imgs">
          <a href="/zero">
            <img src={zer0} alt="zer0" />
          </a>
        </div>
      </div>
    </Element>
  );
};

export default Marcas;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/background-dashboard.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-background {\n  background: #fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: cover;\n  background-position: center top;\n  height: 100vh;\n  box-sizing: border-box;\n  overflow: hidden;\n}\n\n.login-space {\n  margin: 10vh 20px;\n}\n\n.login-container {\n  max-height: 80vh;\n  max-width: 500px;\n  margin: 20px auto;\n  background: #fff;\n  border-radius: 30px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.75);\n  overflow: hidden;\n}\n\n.login-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 40px 10px;\n  overflow-y: auto;\n  text-align: center;\n  /* background: blue; */\n}\n\n.login-container h1 {\n  margin-bottom: 40px;\n  color: #2d853b;\n  text-shadow: 0px 2px 2px #86e86e;\n}\n\n.login-container form {\n  min-width: 250px;\n  max-width: 400px;\n  padding: 5px;\n  text-align: center;\n  display: flex;\n}\n\n.login-container form input {\n  /* flex: 1; */\n  width: 100%;\n}\n\n.login-page {\n  min-height: 430px;\n}\n\n.login-recover {\n  min-height: 145px;\n}\n\n.login-signup {\n  min-height: 420px;\n}\n\n.login-password {\n  min-height: 310px;\n}\n\n.login-container .recover {\n  align-self: center;\n  font-size: 12px;\n  min-height: 30px;\n  height: 30pxs;\n  margin: 10px 0 30px;\n  /* width: 150px; */\n}\n\n.login-small-btn {\n  width: 100px !important;\n}\n\n.user-msg-container {\n  font-size: 12px;\n  font-weight: bold;\n  color: red;\n  padding: 0 10px;\n  min-height: 20px;\n  margin-top: -8px;\n  margin-bottom: 12px;\n}\n\n.login-institucional {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  width: 100%;\n  text-align: left;\n  font-size: 10px;\n  line-height: 15px;\n  margin-top: 20px;\n  text-decoration: none;\n  color: #033;\n}\n\n.login-institucional a {\n  text-decoration: none;\n  color: #033;\n}\n", ""]);
// Exports
module.exports = exports;

import React, {useEffect, useState} from 'react';
import {PDFViewer} from '@react-pdf/renderer';

import Grid from '@material-ui/core/Grid';

import Loading from '../../../components/Loading';

import PDF from './UsersPDF';

const Users = ({opName, start, end, data = null, pegadaAmbiental = {}}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [names, setNames] = useState(null);
  const [pegadas, setPegadas] = useState(null);

  useEffect(() => {
    if (result) return;

    const userNames = {};

    const reducedResult = data.reduce((a, c) => {
      const newA = {...a};

      userNames[c.user_id] = c.oiUser.userInformations.name;

      newA[c.user_id] = !newA[c.user_id]
        ? {[c.product_id]: {name: c.oiItem.name, quantity: c.quantity}}
        : {
            ...newA[c.user_id],
            [c.product_id]: !newA[c.user_id][c.product_id]
              ? {name: c.oiItem.name, quantity: c.quantity}
              : {
                  ...newA[c.user_id][c.product_id],
                  quantity: newA[c.user_id][c.product_id].quantity + c.quantity,
                },
          };

      return newA;
    }, {});

    const reportPegadas = Object.keys(reducedResult).reduce((acc, cur) => {
      const userPegada = pegadaAmbiental.calculate(reducedResult[cur]);
      acc[cur] = userPegada;
      return acc;
    }, {});

    setResult(reducedResult);
    setPegadas(reportPegadas);
    setNames(userNames);
    setIsLoading(false);
  }, [data, pegadaAmbiental, result]);

  return (
    <Grid container direction="column">
      {isLoading ? (
        <Loading />
      ) : names && pegadas && result ? (
        <PDFViewer style={{height: 600}}>
          <PDF
            opName={opName}
            start={start}
            end={end}
            names={names}
            pAs={pegadas}
            result={result}
          />
        </PDFViewer>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default Users;

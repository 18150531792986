import React, {useRef, useState, useCallback, useEffect} from 'react';
import {SHA512} from 'crypto-js';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import api from '../../services/api';
import whichError from '../../services/whichError';

import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';

const SignUp = ({location, history}) => {
  const formRef = useRef(null);

  const appToken = localStorage.getItem('apptoken');

  const query = Object.fromEntries(new URLSearchParams(location.search));
  const {u: userId, k: key, c: verifiedContact} = query;

  const accountConfirmation = userId && key && verifiedContact;

  const [regSent, setRegSent] = useState(false);
  const [checkKey, setCheckKey] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const testCheckKey = useCallback(async () => {
    setUserMsg('');
    if (accountConfirmation) {
      setIsLoading(true);
      const response = await api
        .get(`/signup${location.search}`)
        .catch(err => setUserMsg(whichError(err).errorMsg));

      if (!response) {
        setIsLoading(false);
        return;
      }

      setTimeout(() => {
        setIsLoading(false);
        setCheckKey(true);
      }, 1000);
    }
  }, [accountConfirmation, location.search]);

  useEffect(() => {
    testCheckKey();
  }, [testCheckKey]);

  const handleSubmit = async (data, {reset}, event) => {
    event.preventDefault();

    setIsLoading(true);
    setUserMsg('');

    const schema = Yup.object().shape({
      name: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(3, 'Mínimo 3 caracteres')
        .required('Favor informar seu nome completo'),
      email: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .email('Favor inserir um e-mail válido')
        .required('Favor informar um e-mail válido!'),
      password: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(6, 'Mínimo 6 caracteres!')
        .required('Favor informar a senha'),
      confirmPassword: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(6, 'Mínimo 6 caracteres!')
        .required('Favor confirmar a senha')
        .test(
          'passwords-match',
          'As senhas devem se coincidir',
          val => formRef.current.getFieldValue('password') === `${val}`,
        ),
      agreedTerms: Yup.boolean().test(
        'is-true',
        'É preciso concordar com os termos para continuar',
        value => value === true,
      ),
    });

    try {
      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      setIsLoading(false);
      return;
    }

    const formData = schema.cast(data);

    const loginData = {
      name: formData.name,
      email: formData.email,
      password: SHA512(formData.password).toString(),
    };

    const response = await api
      .post('/signup', loginData)
      .catch(err => setUserMsg(whichError(err).errorMsg));

    if (response) {
      formRef.current.setErrors({});
      reset();
      setRegSent(formData.email);
    }

    setIsLoading(false);
  };

  // const checkboxOptions = [
  //   {id: 'node', value: 'node', label: 'Node'},
  //   {id: 'react', value: 'react', label: 'ReactJS'},
  // ];

  return (
    <div className="s-a login-background">
      <div className="login-space">
        <div className="login-container">
          <div className="login-content">
            <h1>Registro</h1>

            {appToken ? (
              <h3>Você está conectado!</h3>
            ) : accountConfirmation ? (
              <>
                {isLoading && <h3>Verificando dados...</h3>}

                {checkKey ? (
                  <>
                    <h3>E-mail verificado!</h3>
                    <p>Você já pode fazer login no sistema!</p>
                    <br />
                    <button
                      className="btn login-small-btn"
                      type="button"
                      onClick={() => history.push('/login')}
                    >
                      Login
                    </button>
                  </>
                ) : null}
              </>
            ) : regSent ? (
              <>
                <h3>Falta pouco...</h3>
                <br />
                <p>
                  Foi enviado um e-mail para <b>{regSent}</b> para confirmação e
                  conclusão do cadastro.
                </p>
              </>
            ) : (
              <Form
                onSubmit={handleSubmit}
                ref={formRef}
                className="login-signup"
              >
                <Input name="name" placeholder="Nome completo" />
                <Input name="email" placeholder="E-mail" />
                <Input name="password" placeholder="Senha" type="password" />
                <Input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirme a senha"
                />

                <Checkbox
                  name="agreedTerms"
                  label="Estou de acordo com a Política de Privacidade* e os Termos de Serviço**"
                />

                <button type="submit">Criar Conta</button>
              </Form>
            )}

            <div className="user-msg-container">{userMsg}</div>

            {!accountConfirmation && (
              <button
                className="btn login-small-btn"
                type="button"
                onClick={() => history.goBack()}
              >
                Voltar
              </button>
            )}

            {!accountConfirmation && !regSent && (
              <div className="login-institucional">
                <a href="/institucional/privacidade">
                  * Ler a Política de Privacidade
                </a>
                <a href="/institucional/termos">** Ler os Termos de Serviço</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

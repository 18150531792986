// Header.js
import React from 'react';
import {useHistory} from 'react-router-dom';

import UiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';

import MenuIcon from '@material-ui/icons/Menu';

import logoSA from '../../../assets/logo-suporte-ambiental.png';

const AppBar = ({classes, toggleDrawer}) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLink = link => {
    switch (link) {
      case 'perfil':
        history.push('/dashboard/perfil');
        break;

      case 'senha':
        history.push('/password');
        break;

      case 'logout':
        // history.push('/logout/pegada');
        history.push('/logout/login');
        break;

      default:
        break;
    }
  };

  return (
    <UiAppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <a href="/" className={classes.appTitle}>
          <img src={logoSA} className={classes.logo} alt="Suporte Ambiental" />
        </a>

        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleLink('perfil')}>Perfil</MenuItem>
            <Divider />
            <MenuItem onClick={() => handleLink('senha')}>
              Trocar Senha
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleLink('logout')}>
              Desconectar
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </UiAppBar>
  );
};

export default AppBar;

import React, {useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import RadioUI from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  option: {
    alignItems: 'flex-start',
  },
  label: {
    paddingTop: 8.5,
  },
}));

const Radio = ({
  label = null,
  options = [],
  helperText = '',
  onRadioChange = () => null,
  error = false,
}) => {
  const classes = useStyles();

  const [value, setValue] = useState('');
  const [err, setErr] = useState(error);
  const [helperTxt, setHelperTxt] = useState(helperText);

  const handleRadioChange = event => {
    // setErr(false);
    // setHelperTxt(' ');
    setValue(event.target.value);
    onRadioChange(event.target.value);
  };

  // const hand = event => {
  //   event.preventDefault();

  //   if (value === 'best') {
  //     setHelperTxt('You got it!');
  //     setErr(false);
  //   } else if (value === 'worst') {
  //     setHelperTxt('Sorry, wrong answer!');
  //     setErr(true);
  //   } else {
  //     setHelperTxt('Please select an option.');
  //     setErr(true);
  //   }
  // };

  return (
    <FormControl
      component="fieldset"
      error={err}
      className={classes.formControl}
    >
      {label && (
        <FormLabel component="legend">Pop quiz: Material-UI is...</FormLabel>
      )}

      <RadioGroup
        aria-label="quiz"
        name="quiz"
        value={value}
        onChange={handleRadioChange}
      >
        {options.map(opt => (
          <FormControlLabel
            key={`${Math.random()}`}
            control={<RadioUI />}
            value={opt.value}
            label={opt.label}
            classes={{
              root: classes.option,
              label: classes.label,
            }}
          />
        ))}
      </RadioGroup>

      <FormHelperText>{helperTxt}</FormHelperText>
    </FormControl>
  );
};

export default Radio;

// Drawer.js
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Divider from '@material-ui/core/Divider';
import UiDrawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import LabelIcon from '@material-ui/icons/Label';

import useStyles from './styles';

const DrawerItems = ({toggleDrawer = () => null, classes: parentClasses}) => {
  const history = useHistory();

  const changePassword = localStorage.getItem('changePassword');
  const userStatus = useSelector(state => state.Login.status);

  const classes = {...parentClasses, ...useStyles()};

  const handleLink = link => {
    history.push(`/dashboard${link}`);
    toggleDrawer('close');
  };

  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />

      {!(userStatus === 9 || changePassword) && (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Painel zer0
            </ListSubheader>
          }
          className={classes.drawerList}
        >
          <ListItem button onClick={() => handleLink('')}>
            <ListItemIcon>
              <LabelIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          {userStatus === 3 && (
            <ListItem button onClick={() => handleLink('/parcerias')}>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Parcerias" />
            </ListItem>
          )}

          {userStatus === 4 && (
            <ListItem button onClick={() => handleLink('/historico')}>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Histórico" />
            </ListItem>
          )}
        </List>
      )}

      {userStatus === 2 && (
        <>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Configurações do Site
              </ListSubheader>
            }
            className={classes.drawerList}
          >
            <ListItem button>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Instagram" />
            </ListItem>

            <ListItem button onClick={() => handleLink('/mkt')}>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Marketing" />
            </ListItem>
          </List>

          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {/* <Link component={RouterLink} to="/dashboard/zer0"> */}
                Dashboard zer0
                {/* </Link> */}
              </ListSubheader>
            }
            className={classes.drawerList}
          >
            <ListItem button onClick={() => handleLink('/zer0/usuarios')}>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Usuários" />
            </ListItem>

            <ListItem button onClick={() => handleLink('/zer0/reciclaveis')}>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Recicláveis" />
            </ListItem>

            <ListItem button onClick={() => handleLink('/zer0/maquinas')}>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Máquinas" />
            </ListItem>

            <ListItem button onClick={() => handleLink('/zer0/locais')}>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Localizações" />
            </ListItem>

            <ListItem button onClick={() => handleLink('/zer0/operacoes')}>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Operações" />
            </ListItem>

            <ListItem button onClick={() => handleLink('/zer0/parcerias')}>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Parcerias" />
            </ListItem>

            <ListItem button onClick={() => handleLink('/zer0/notificacoes')}>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText primary="Notificações" />
            </ListItem>
          </List>
        </>
      )}
    </div>
  );
};

const Drawer = ({classes, open, toggleDrawer}) => {
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <UiDrawer
          variant="temporary"
          // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={open}
          onClose={toggleDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerItems classes={classes} toggleDrawer={toggleDrawer} />
        </UiDrawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <UiDrawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <DrawerItems classes={classes} toggleDrawer={toggleDrawer} />
        </UiDrawer>
      </Hidden>
    </nav>
  );
};

export default Drawer;

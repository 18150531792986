import React, {
  useContext,
  forwardRef,
  useState,
  useImperativeHandle,
} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

// import {validate} from '../../../../rules/form';
// import addEPSchema from '../../../../rules/form/Booking/addBooking';

import FilePicker from '../FilePicker';

import useStyles from './useStyles';

const AddNNUsers = forwardRef((_, ref) => {
  const classes = useStyles();
  const showAlert = useContext(AlertContext);

  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const add = () => setVisible(true);
  useImperativeHandle(ref, () => ({add}));

  const handleSubmit = async () => {
    if (!file) {
      showAlert({title: 'Atenção', msg: 'Selecione um arquivo'});
      return;
    }

    const formData = new FormData();
    formData.append('list', file);

    setIsSaving(true);

    const save = await api
      .post(`/core/external/novo`, formData)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setIsSaving(false);

    if (save) {
      showAlert({
        title: 'Atenção',
        msg: 'A lista está sendo processada. Aguarde a inclusão.',
      });
      setVisible(false);
    }
  };

  const handleFile = image => setFile(image);

  const onClose = () => setVisible(false);

  return (
    visible && (
      <Dialog open={!!visible} onClose={onClose}>
        <DialogTitle className={classes.dialogTitle}>
          Inclusão de Colaboradores
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Selecione o arquivo .CSV com a lista de colaboradores
            <br />
            <br />
            <b>Exemplo:</b>
            <code style={{fontSize: 14}}>
              <br />
              MATR;INICIAIS;EMAIL
              <br />
              12345;ABCD;ABCD@novonordisk.com;
              <br />
              23456;EFGH;EFGH@novonordisk.com;
              <br />
              34567;IJKL;IJKL@novonordisk.com;
            </code>
            <br />
          </DialogContentText>

          <div>
            <b>Observações:</b>
            <ul>
              <li>
                1ª linha = <code>MATR;INICIAIS;EMAIL</code> (obrigatoriamente)
              </li>
              <li>Cada linha corresponde a 1 usuário</li>
              <li>
                Ponto-e-vírgula &apos; <b>;</b> &apos; na separação de dados e
                no fim da linha
              </li>
              <li>Não deve haver linhas em branco</li>
            </ul>
          </div>

          <br />

          <FilePicker height={153} width={272} onChange={handleFile} />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
            variant="contained"
            color="primary"
            disabled={isSaving}
          >
            Cancelar
          </Button>

          <Button onClick={handleSubmit} variant="contained" color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
});

export default AddNNUsers;

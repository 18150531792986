import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';

import {useToggle} from './hooks';

import AppBar from './components/AppBar';
import Drawer from './components/Drawer';
import Routes from './routes';

import useStyles from './styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      //   light: '#757ce8',
      main: '#2d853b',
      //   dark: '#002884',
      //   contrastText: '#000',
    },
    // secondary: {
    //   light: '#ff7961',
    //   main: '#f44336',
    //   dark: '#ba000d',
    //   contrastText: '#FFF',
    // },
  },
});

const Dashboard = () => {
  const classes = useStyles();

  const [open, toggleDrawer] = useToggle(false);

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <AppBar classes={classes} toggleDrawer={toggleDrawer} />

        <BrowserRouter>
          <Drawer classes={classes} open={open} toggleDrawer={toggleDrawer} />
          <Routes classes={classes} />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default Dashboard;

import React, {useContext, useCallback, useEffect, useState} from 'react';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import AddToQueueRoundedIcon from '@material-ui/icons/AddToQueueRounded';

import {AlertContext} from '../../../../App';

import baseURL from '../../../../configs/baseURL';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

Numeral.locale('pt-br');

const columns = [
  {id: 'img', label: 'Imagem', width: 80, align: 'center'},
  {id: 'name', label: 'Nome', minWidth: 150},
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },

  container: {
    // maxHeight: 440,
  },

  image: {
    maxWidth: 100,
    height: 80,
    width: 'auto',
  },

  statusBtnCont: {
    textAlign: 'right',
  },

  statusBtn: {
    margin: 10,
  },
});

const Recyclables = ({history}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    await api
      .get(`/core/machines`)
      .then(async res => {
        if (res.data) {
          setData(res.data.machines);
          setIsLoading(false);
        }
      })
      .catch(e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <Header
        title="Máquinas"
        btnTxt="Adicionar máquina"
        btnIcon={<AddToQueueRoundedIcon />}
        onBtn={() => history.push('/dashboard/zer0/maquina/incluir')}
      />

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      ...(column.width ? {width: column.width} : {}),
                      ...(column.minWidth ? {minWidth: column.minWidth} : {}),
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                data.map(item => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={item.id}
                      onClick={() =>
                        history.push(`/dashboard/zer0/maquina/${item.id}`)
                      }
                    >
                      <TableCell align="center">
                        <img
                          src={`${baseURL.addr}/imgs/products/${item.img}`}
                          alt={item.name}
                          className={classes.image}
                        />
                      </TableCell>

                      <TableCell>{item.name}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default Recyclables;

// import React, {createContext, useMemo, useState} from 'react';
import React, {createContext, useRef, useMemo} from 'react';
import socketio from 'socket.io-client';
import {useSelector} from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';

import baseURL from './configs/baseURL';

import Facebook from './components/Facebook';
import AlertDialog from './components/AlertDialog';

import Routes from './routes';

export const SocketContext = createContext(null);
export const AlertContext = createContext(null);

export default function App() {
  const alert = useRef();
  const {Login} = useSelector(state => state);
  const {userId} = Login;

  const socket = useMemo(
    () =>
      socketio(baseURL.socket, {
        query: {userId},
      }),
    [userId],
  );

  socket.on('alertMsg', async ({title, msg}) => {
    alert.current.set({title, msg});
  });

  return (
    <>
      <CssBaseline />

      <Facebook />

      <AlertDialog ref={alert} />

      <AlertContext.Provider value={params => alert.current.set(params)}>
        <SocketContext.Provider value={socket}>
          <Routes />
        </SocketContext.Provider>
      </AlertContext.Provider>
    </>
  );
}

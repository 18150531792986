import React, {
  useRef,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import {Link} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ListIcon from '@material-ui/icons/ListAltOutlined';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';
import OptionsBtn from '../../components/OptionsBtn';
import AddNNUsers from '../../components/AddNNUsers';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },

  optionsBtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const Operation = ({history, match}) => {
  const showAlert = useContext(AlertContext);
  const {params} = match;
  const {id} = params;
  const addNN = useRef();

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);

  const [isLoadingPegada, setIsLoadingPegada] = useState(true);
  const [pegada, setPegada] = useState({water: 0, energy: 0, co2: 0});

  const loadPegada = useCallback(
    async eventId => {
      setIsLoadingPegada(true);

      await api
        .get(`/pegada?o=${eventId}`)
        .then(async res => {
          setPegada(res.data);
          setIsLoadingPegada(false);
        })
        .catch(async e => {
          showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
          setIsLoadingPegada(false);
        });
    },
    [showAlert],
  );

  const loadData = useCallback(async () => {
    setIsLoading(true);

    await api
      .get(`/core/operations/${id}`)
      .then(async res => {
        setResult(res.data);
        loadPegada(res.data.id);
        setIsLoading(false);
      })
      .catch(async e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [id, loadPegada, showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData, loadPegada]);

  const handleDelete = async () => {
    setIsLoading(true);

    await api
      .delete(`/core/operations/${id}`)
      .then(async () => {
        setIsLoading(false);
        history.goBack();
      })
      .catch(async e => {
        showAlert({
          title: 'Não foi possível apagar',
          msg: whichError(e).errorMsg,
        });
        setIsLoading(false);
      });
  };

  const askDelete = () => {
    showAlert({
      title: 'Confirmação',
      msg: 'Deseja realmente apagar esta operação?',
      onOk: handleDelete,
    });
  };

  const onSelectOpts = optParams => {
    const {divider, addExternal, deleteOp} = optParams;

    if (divider) return;
    if (deleteOp) askDelete();
    if (addExternal) addNN.current.add();
  };

  return (
    <>
      <Header title="Detalhes da Operação" />

      <Paper className={classes.root}>
        {isLoading ? (
          <Loading />
        ) : !result ? (
          // <p>REDIRECIONAR</p>
          <></>
        ) : (
          <>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Typography color="textPrimary" variant="h6">
                {result.name}
              </Typography>

              <OptionsBtn
                options={[
                  ...(result.name === 'Novo Nordisk'
                    ? [
                        {label: 'Adicionar colaboradores', addExternal: true},
                        {divider: true},
                      ]
                    : []),
                  {label: 'Apagar operação', deleteOp: true},
                ]}
                onSelect={onSelectOpts}
              />
            </Grid>

            <br />

            <Typography variant="subtitle1">Descrição</Typography>
            <Typography>{result.description || '- sem descrição -'}</Typography>

            <br />

            <Typography variant="subtitle1">Localização</Typography>
            <Link
              component={RouterLink}
              to={`/dashboard/zer0/local/${result.location_id}`}
            >
              <Typography>{result.eventLocation.name}</Typography>
            </Link>

            <br />

            <Typography variant="subtitle1">Máquina</Typography>
            <Link
              component={RouterLink}
              to={`/dashboard/zer0/maquina/${result.machine_id}`}
            >
              <Typography>{result.eventMachine.name}</Typography>
            </Link>

            <br />

            <Typography variant="subtitle1">Pegada</Typography>
            {isLoadingPegada ? (
              <Loading />
            ) : (
              <>
                <Typography variant="subtitle2">
                  Água: {pegada.water}
                </Typography>
                <Typography variant="subtitle2">
                  Energia: {pegada.energy}
                </Typography>
                <Typography variant="subtitle2">CO2: {pegada.co2}</Typography>
              </>
            )}

            <Box mt={3} />
            <Divider pt={2} />
            <Box mt={3} />

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() =>
                  history.push(`/dashboard/zer0/operacao/${id}/historico`)
                }
                startIcon={<ListIcon />}
                className={classes.editBtn}
              >
                Histórico
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() =>
                  history.push(`/dashboard/zer0/operacao/${id}/relatorios`)
                }
                startIcon={<ListIcon />}
                className={classes.editBtn}
              >
                Relatórios
              </Button>
            </Grid>

            <AddNNUsers ref={addNN} />
          </>
        )}
      </Paper>
    </>
  );
};

export default Operation;

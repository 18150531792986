import React from 'react';

import Privacidade from './Privacidade';
import Uso from './Termos';

import logoSA from '../../../assets/logo-suporte-ambiental.png';

import './styles.css';

const Institucional = ({location}) => {
  const {pathname} = location;
  const target = pathname.substr(15);

  return (
    <div className="politicas">
      <div className="politicas-header">
        <a href="https://suporteambiental.com">
          <img src={logoSA} alt="Suporte Ambiental" />
        </a>

        <h1>Institucional</h1>
      </div>

      <h2>
        {target === 'termos'
          ? 'Termos de Uso do Serviço'
          : target === 'privacidade'
          ? 'Política de Privacidade'
          : ''}
      </h2>

      <div className="institucional-text">
        {target === 'termos' ? (
          <Uso />
        ) : target === 'privacidade' ? (
          <Privacidade />
        ) : (
          <div className="institucional-index">
            <p>
              <a href="/institucional/termos">- Termos de Uso do Serviço</a>
            </p>
            <p>
              <a href="/institucional/privacidade">- Política de Privacidade</a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Institucional;

import React from 'react';
import {Element} from 'react-scroll';

import quemSomos from '../../../assets/img-quem-somos1.png';

import './styles.css';
import '../../../css/css.scss'

const Sobre = () => {
  return (
    <Element name="sobre" className="sobre">
      <div className="sobre-text">
        <h1 className="section-title">Quem Somos</h1>

        <p className="size-18">
        A Suporte Ambiental oferece soluções inovadoras, eficientes e sustentáveis no âmbito das Engenharias Ambiental e Sanitária, que agregam valores ao seu empreendimento e beneficiam toda sociedade.
        </p>

        <p className="size-18">
        Nossa atuação vai desde a elaboração do Laudo Técnico de Ruído Ambiental e dos Planos de Gerenciamento de Resíduos, até o Monitoramento de Condicionantes Ambientais, com diversos projetos já implementados. 
        </p>
      </div>

      <div className="sobre-img">
        <img src={quemSomos} className="img-quem-somos" alt="Quem Somos" />
      </div>
    </Element>
  );
};

export default Sobre;

import React, {useContext, useCallback, useEffect, useState} from 'react';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';
import {objById} from '../../../../tools/dataManager';
import PegadaAmbiental from '../../../../tools/PegadaAmbiental';

import DatePicker from '../../../../components/DatePicker';
import Radio from '../../../../components/Radio';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

import PA from './reports/PegadaAmbiental';
import Items from './reports/Items';
import Users from './reports/Users';
import NNUsers from './reports/NovoNordiskUsers';
import Daily from './reports/DailyDetailed';
import UD from './reports/UserDailyDetailed';
import NNUD from './reports/NovoNordiskUserDailyDetailed';
import CSV from './reports/CSV';
import CSVnn from './reports/CSVnovo';
import UsersCSV from './reports/UsersCSV';
import UsersCSVnn from './reports/UsersCSVnn';

Numeral.locale('pt-br');

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  operationName: {
    margin: 10,
  },
  filter: {
    margin: 10,
  },
  button: {
    margin: 10,
  },
});

const OperationReports = ({match}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();

  const {id: opId} = match.params;

  const [isLoading, setIsLoading] = useState(true);
  const [operation, setOperation] = useState(null);
  const [pegada, setPegada] = useState(null);

  const [filterStart, setFilterStart] = useState(false);
  const [filterEnd, setFilterEnd] = useState(false);
  const [filterReport, setFilterReport] = useState(false);
  // const [filterStart, setFilterStart] = useState('2021-03-01');
  // const [filterEnd, setFilterEnd] = useState('2021-03-20');
  // const [filterReport, setFilterReport] = useState('usersCSV');

  const [report, setReport] = useState(false);
  const [result, setResult] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    const op = api.get(`/core/operations/${opId}`);
    const items = api.get(`/core/items?all=true`);

    const error = [];
    const get = await Promise.all(
      [op, items].map((p, i) =>
        p.catch(e => {
          error[i] = whichError(e).errorMsg;
        }),
      ),
    );

    const errors = [...new Set(error)];
    if (errors.length > 1) {
      showAlert({
        title: 'Atenção',
        msg: `Ocorreram os seguintes erros:\n\n${errors.join('\n')}`,
      });
    } else if (errors.length >= 1) {
      showAlert({
        title: 'Atenção',
        msg: `Ocorreu o seguinte erro:\n\n${errors[0]}`,
      });
    }

    const Pegada = new PegadaAmbiental(objById(get[1].data.products, 'id'));
    setPegada(Pegada);
    setOperation(get[0].data);

    setIsLoading(false);
  }, [opId, showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!filterReport || !filterStart || !filterEnd) {
      showAlert({title: 'Atenção', msg: 'Preencha todos os dados'});
      return;
    }

    setIsLoading(true);
    setReport(false);
    setResult(false);

    // Separar relatorios --- Colocar Relatorios em Parcerias e criar uma
    // especie de array pra cada parceria com seus respectivos relatorios

    const usersReports = ['userDaily', 'users', 'csv', 'usersCSV'];
    const users = usersReports.indexOf(filterReport) >= 0;

    const novoUserReports = ['nnUserDaily', 'nnUsers', 'nnCSV', 'usersCSVnn'];
    const novoUsers = novoUserReports.indexOf(filterReport) >= 0;

    await api
      .get(
        `/core/oi?u=all&zer0report=true&operation=${opId}&start=${filterStart}&end=${filterEnd}${
          users ? '&users=true' : ''
        }${novoUsers ? '&novoUsers=true' : ''}`,
      )
      .then(async res => {
        if (res.data) {
          const {operationItems} = res.data;
          // console.log('operationItems :>> ', operationItems);
          setResult(operationItems);
          setReport(filterReport);
          setIsLoading(false);
        }
      })
      .catch(e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  };
  const onFilterChange = (filterInput, value) => {
    const filterDate =
      filterInput === 'start' || filterInput === 'end'
        ? format(value, 'yyyy-MM-dd')
        : false;

    switch (filterInput) {
      case 'start':
        setFilterStart(filterDate);
        break;

      case 'end':
        setFilterEnd(filterDate);
        break;

      case 'report':
        setFilterReport(value);
        break;

      default:
        break;
    }
  };

  return !operation ? (
    <Loading />
  ) : (
    <>
      <Header title="Relatórios da Operação" />

      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.operationName}>
          {operation.name}
        </Typography>

        <Radio
          options={[
            {label: 'Pegada Ambiental', value: 'pegadaAmbiental'},
            {label: 'Recicláveis', value: 'items'},
            {label: 'Acumulado do Usuário', value: 'users'},
            {label: 'Acumulado do Usuário (Novo Nordisk)', value: 'nnUsers'},
            {label: 'Acumulado Diário (items)', value: 'daily'},
            {label: 'Acumulado Diário (Usuários)', value: 'userDaily'},
            {label: 'Acumulado Diário (Novo Nordisk)', value: 'nnUserDaily'},
            {label: 'CSV', value: 'csv'},
            {label: 'CSV (Novo Nordisk)', value: 'nnCSV'},
            {label: 'CSV Acumulado', value: 'usersCSV'},
            {label: 'CSV Acumulado (Novo Nordisk)', value: 'usersCSVnn'},
          ]}
          onRadioChange={radioValue => onFilterChange('report', radioValue)}
        />

        <Grid container direction="row">
          <DatePicker
            label="Início"
            onDateChange={date => onFilterChange('start', date)}
            className={classes.filter}
            id="op-report-filter-start"
          />

          <DatePicker
            label="Fim"
            onDateChange={date => onFilterChange('end', date)}
            className={classes.filter}
            id="op-report-filter-end"
          />
        </Grid>

        <Button
          onClick={handleSubmit}
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          Gerar relatório
        </Button>
      </Paper>

      <br />
      <br />

      {report && result && (
        <Paper className={classes.report}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {report === 'pegadaAmbiental' && (
                <PA
                  opName={operation.name}
                  start={filterStart}
                  end={filterEnd}
                  data={result}
                  pegadaAmbiental={pegada}
                />
              )}

              {report === 'items' && (
                <Items
                  opName={operation.name}
                  start={filterStart}
                  end={filterEnd}
                  data={result}
                />
              )}

              {report === 'users' && (
                <Users
                  opName={operation.name}
                  start={filterStart}
                  end={filterEnd}
                  data={result}
                  pegadaAmbiental={pegada}
                />
              )}

              {report === 'nnUsers' && (
                <NNUsers
                  opName={operation.name}
                  start={filterStart}
                  end={filterEnd}
                  data={result}
                  pegadaAmbiental={pegada}
                />
              )}

              {report === 'daily' && (
                <Daily
                  opName={operation.name}
                  start={filterStart}
                  end={filterEnd}
                  data={result}
                  pegadaAmbiental={pegada}
                />
              )}

              {report === 'userDaily' && (
                <UD
                  opName={operation.name}
                  start={filterStart}
                  end={filterEnd}
                  data={result}
                  pegadaAmbiental={pegada}
                />
              )}

              {report === 'nnUserDaily' && (
                <NNUD
                  opName={operation.name}
                  start={filterStart}
                  end={filterEnd}
                  data={result}
                  pegadaAmbiental={pegada}
                />
              )}

              {report === 'csv' && (
                <CSV data={result} pegadaAmbiental={pegada} />
              )}

              {report === 'nnCSV' && (
                <CSVnn data={result} pegadaAmbiental={pegada} />
              )}

              {report === 'usersCSV' && (
                <UsersCSV data={result} pegadaAmbiental={pegada} />
              )}

              {report === 'usersCSVnn' && (
                <UsersCSVnn data={result} pegadaAmbiental={pegada} />
              )}
            </>
          )}
        </Paper>
      )}
    </>
  );
};

export default OperationReports;

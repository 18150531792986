import React from 'react';
import {Element, Link as Scroll, scroller} from 'react-scroll';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import Menu, {Item as MenuItem, Divider} from 'rc-menu';

import logoSA from '../../../assets/logo-suporte-ambiental.png';
import iconFB from '../../../assets/icon-facebook.png';
import iconIG from '../../../assets/icon-instagram.png';

import './styles.css';

const Principal = () => {
  const onClick = ({item}) => {
    scroller.scrollTo(item.props.link, {smooth: true});
  };

  const NavMenu = () => (
    <Menu onClick={onClick} selectable={false}>
      <MenuItem key="1" link="sobre">
        Sobre
      </MenuItem>

      <Divider />

      <MenuItem key="2" link="servicos">
        Nossos Serviços
      </MenuItem>

      <Divider />

      <MenuItem key="3" link="social">
        Redes Sociais
      </MenuItem>

      <Divider />

      <MenuItem key="4" link="contato">
        Contato
      </MenuItem>
    </Menu>
  );

  return (
    <Element name="principal" className="principal">
      <header className="principal-header">
        <a href="https://suporteambiental.com">
          <img src={logoSA} className="Logo" alt="Suporte Ambiental" />
        </a>

        <div className="principal-nav">
          <nav className="nav-items">
            <Scroll to="sobre" smooth className="nav-item">
              SOBRE
            </Scroll>

            <Scroll to="servicos" smooth className="nav-item">
              SERVIÇOS
            </Scroll>

            <Scroll to="social" smooth className="nav-item">
              REDES
            </Scroll>

            <Scroll to="contato" smooth className="nav-item">
              CONTATO
            </Scroll>

            
          </nav>
        </div>

        <div className="responsive-menu">
          <Dropdown
            arrow
            trigger={['click']}
            overlay={NavMenu}
            animation="slide-up"
          >
            <button onClick={null} className="menu-btn" type="button">
              &nbsp;
            </button>
          </Dropdown>
        </div>
      </header>

      <div className="principal-content">
        <div className="principal-slogan">
          <h1 className="slogan">SOMOS SUA SOLUÇÃO AMBIENTAL</h1>
        </div>

        <div className="principal-btn-container">
          <Scroll to="sobre" smooth>
            <button type="button">SOBRE</button>
          </Scroll>
        </div>
      </div>
    </Element>
  );
};

export default Principal;

import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import {AlertContext} from '../../../../../App';

import api from '../../../../../services/api';
import whichError from '../../../../../services/whichError';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

const Upload = ({onUploaded = () => null}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();

  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = async file => {
    const formData = new FormData();

    if (!file) return;

    formData.append('img', file);

    setIsUploading(true);

    const save = await api
      .post(`/core/mkt/media`, formData)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setIsUploading(false);

    if (save) {
      onUploaded();
    }
  };

  return (
    <div className={classes.root}>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={({target}) => handleUpload(target.files[0])}
        disabled={isUploading}
      />

      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          Upload
        </Button>
      </label>
    </div>
  );
};

export default Upload;

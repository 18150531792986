import React, {useContext, useRef, useState} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Input from '../../../../components/Form/Input';
import ImgPicker from '../../../../components/ImgPicker';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },

  textInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const MachineAdd = ({history}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();
  const formRef = useRef(null);

  const [img, setImg] = useState(null);

  const handleSubmit = async (unformData, {reset}) => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      name: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor informar o nome'),
      description: Yup.string()
        // .transform((cv, ov) => (ov === '' ? null : cv))
        // .nullable(true)
        .trim(),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);
    // console.log('unformData :>> ', unformData);
    // return;

    // unformData.imgAction =
    //   userHasImg && imgAction && img === null
    //     ? 'remove'
    //     : userHasImg && !imgAction && img === null
    //     ? 'keep'
    //     : 'update';

    // eslint-disable-next-line no-undef
    const formData = new FormData();

    Object.entries(data).forEach(field =>
      formData.append(
        field[0],
        field[1] === undefined || field[1] === null ? '' : field[1],
      ),
    );

    if (img) formData.append('img', img);

    const save = await api
      .post(`/core/machines`, formData)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (save) {
      reset();
      history.goBack();
    }
  };

  const iD = {
    // name: 'Teste',
  };

  return (
    <>
      <Header title="Adicionar Máquina" />

      <Paper className={classes.root}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={iD}>
          <ImgPicker height={150} width={150} onChange={setImg} />

          <Input
            label="Nome do máquina *"
            name="name"
            className={classes.textInput}
            variant="outlined"
            fullWidth
          />

          <br />
          <br />

          <Input
            label="Descrição"
            name="description"
            multiline
            variant="outlined"
            fullWidth
            className={classes.description}
            // inputProp
          />

          <br />
          <br />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            className={classes.button}
            startIcon={<AddIcon />}
          >
            Adicionar
          </Button>
        </Form>
      </Paper>
    </>
  );
};

export default MachineAdd;

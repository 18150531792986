import React, {useContext, useRef, useState} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {makeStyles} from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';

import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';

const useStyles = makeStyles(() => ({
  paper: {maxWidth: 600, padding: '16px 12px'},
  body: {minHeight: 508},
}));

const Notifications = () => {
  const showAlert = useContext(AlertContext);

  const classes = useStyles();
  const formRef = useRef(null);

  const [isToUser, setIsToUser] = useState(false);
  // const [isToUser, setIsToUser] = useState(true);

  const handleSubmit = async (unformData, {reset}) => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      topic: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor informar o destino da notificação'),
      user: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(36, 'id. incorreto.')
        .max(36, 'id. incorreto.'),
      title: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'Mínimo 1 caractere')
        .max(256, 'Máximo 256 caracteres'),
      body: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'Mínimo 1 caractere')
        .max(256, 'Máximo 256 caracteres')
        .required('Favor informar o conteúdo da notificação'),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);

    if (data.topic === 'user' && !data.user) {
      formRef.current.setErrors({user: 'Favor informar o id. do usuário'});
      return;
    }

    const data2send = {
      topic: data.topic === 'user' ? data.user : data.topic,
      title: data.title,
      body: data.body,
    };

    const send = await api
      .post(`/core/fcm/send`, data2send)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (send) {
      reset();
      showAlert({title: 'Sucesso', msg: 'Notificação enviada!'});
    }
  };

  const iD = {
    // topic: 'user',
    // user: '2bef6866-0564-404d-8070-2e74b9c13f8b',
    // title: 'Título',
    // body: 'Conteúdo da notificação',
  };

  const onChangeValue = selectedVal => setIsToUser(selectedVal === 'user');

  return (
    <>
      <Header title="Notificações" />

      <Paper className={classes.paper}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={iD}>
          <Select
            label="Destino *"
            name="topic"
            options={[
              {value: 'public', label: 'Todos usuários'},
              {value: 'user', label: 'Usuário específico'},
              {value: 'loggedIn', label: 'Logados'},
              {value: 'loggedOut', label: 'Deslogados'},
            ]}
            onChangeValue={onChangeValue}
            variant="outlined"
            fullWidth
            className={classes.textInput}
          />

          {isToUser && (
            <Input
              label="Id. do Usuário"
              name="user"
              className={classes.textInput}
              variant="outlined"
              fullWidth
            />
          )}

          <Input
            label="Título"
            name="title"
            className={classes.textInput}
            variant="outlined"
            fullWidth
          />

          <Input
            label="Mensagem *"
            name="body"
            multiline
            rows={3}
            className={classes.body}
            variant="outlined"
            fullWidth
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            className={classes.button}
            startIcon={<SendIcon />}
          >
            Enviar
          </Button>
        </Form>
      </Paper>
    </>
  );
};

export default Notifications;

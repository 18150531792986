import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import {menuList} from '../../../../tools/dataManager';

import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },
}));

const categoryOptions = [
  {value: 10, label: 'Coleta'},
  {value: 12, label: 'Corporativo'},
  {value: 9, label: 'Troca de Pontos'},
];

const PartnershipAdd = ({history}) => {
  const showAlert = useContext(AlertContext);
  const formRef = useRef(null);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState(null);

  const [category, setCategory] = useState(null);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    const users = api.get('/core/users?&c=3');
    const machines = api.get('/core/machines');
    const locations = api.get('/core/locations');

    const error = [];
    const get = await Promise.all(
      [users, machines, locations].map((p, i) =>
        p.catch(e => {
          error[i] = whichError(e).errorMsg;
        }),
      ),
    );

    const errors = [...new Set(error)];
    if (errors.length > 1) {
      showAlert({
        title: 'Atenção',
        msg: `Ocorreram os seguintes erros:\n\n${errors.join('\n')}`,
      });
    } else if (errors.length >= 1) {
      showAlert({
        title: 'Atenção',
        msg: `Ocorreu o seguinte erro:\n\n${errors[0]}`,
      });
    }

    setParams(
      get[0] && get[1] && get[2]
        ? {
            users: menuList(
              get[0].data.users,
              ['userInformations', 'name'],
              'id',
            ),
            machines: menuList(get[1].data.machines, 'name', 'id'),
            locations: menuList(get[2].data.locations, 'name', 'id'),
          }
        : null,
    );

    setIsLoading(false);
  }, [showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = async (unformData, {reset}) => {
    // console.log('unformData :>> ', unformData);
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      category: Yup.number()
        .transform((cv, or) =>
          or === '' || or === '0' ? null : or === '$null$' ? 0 : cv,
        )
        .nullable(true)
        .typeError('Favor informar um valor válido')
        .positive('Favor informar um valor válido')
        .required('Favor informar a categoria'),
      partner: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .typeError('Favor informar um valor válido')
        .min(36, 'Favor informar um valor válido')
        .max(36, 'Favor informar um valor válido')
        .required('Favor informar o usuário (parceiro)'),
      ...(category === '12'
        ? {
            machine: Yup.string()
              .transform((cv, ov) => (ov === '' ? null : cv))
              .nullable(true)
              .typeError('Favor informar um valor válido')
              .min(36, 'Favor informar um valor válido')
              .max(36, 'Favor informar um valor válido')
              .required('Favor informar a máquin'),
          }
        : {}),
      location: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .typeError('Favor informar um valor válido')
        .min(36, 'Favor informar um valor válido')
        .max(36, 'Favor informar um valor válido')
        .required('Favor informar a localização'),
      name: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .typeError('Favor informar um valor válido')
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor o nome da parceria'),
      ...(category === '9'
        ? {
            points: Yup.number()
              .transform((cv, or) => (or === '' ? null : cv))
              .nullable(true)
              .typeError('Informe um valor válido')
              .positive('Informe um valor válido')
              .integer('Informe um valor válido')
              .required('Informe os pontos'),
          }
        : {}),
      ...(category === '9'
        ? {
            description: Yup.string()
              .transform((cv, ov) => (ov === '' ? null : cv))
              .nullable(true)
              .trim()
              .required('Informe a descrição'),
          }
        : {
            description: Yup.string()
              .transform((cv, ov) => (ov === '' ? null : cv))
              .nullable(true)
              .trim(),
          }),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);
    // console.log('data :>> ', data);
    // return;

    const save = await api
      .post(`/core/partnerships`, data)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (save) {
      reset();
      history.goBack();
    }
  };

  return (
    <>
      <Header title="Adicionar Parceria" />

      <Paper className={classes.root}>
        {isLoading ? (
          <Loading />
        ) : !params ? (
          <></>
        ) : (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Select
              label="Categoria *"
              name="category"
              options={categoryOptions}
              variant="outlined"
              fullWidth
              // onChangeValue={val => setMachine(!!(val === '12'))}
              onChangeValue={val => setCategory(val)}
              className={classes.textInput}
            />

            <Select
              label="Usuário (Parceiro) *"
              name="partner"
              options={params.users}
              variant="outlined"
              fullWidth
              className={classes.textInput}
            />

            {category === '12' && (
              <Select
                label="Máquina *"
                name="machine"
                options={params.machines}
                variant="outlined"
                fullWidth
                className={classes.textInput}
              />
            )}

            <Select
              label="Localização *"
              name="location"
              options={params.locations}
              variant="outlined"
              fullWidth
              className={classes.textInput}
            />

            <Input
              label={
                category === '9' ? 'Item de troca *' : 'Nome da parceria *'
              }
              name="name"
              className={classes.textInput}
              variant="outlined"
              fullWidth
            />

            {category === '9' && (
              <Input
                label="Pontos *"
                name="points"
                className={classes.textInput}
                variant="outlined"
                fullWidth
                inputProps={{
                  step: 1,
                  type: 'number',
                }}
              />
            )}

            <Input
              label="Descrição"
              name="description"
              multiline
              variant="outlined"
              fullWidth
              className={classes.description}
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              className={classes.button}
              startIcon={<AddCircleOutlineOutlinedIcon />}
            >
              Adicionar
            </Button>
          </Form>
        )}
      </Paper>
    </>
  );
};

export default PartnershipAdd;

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import TimerIcon from '@material-ui/icons/Timer';

const useStyles = makeStyles({
  root: {
    width: 192,
  },
  input: {
    width: 42,
  },
});

export default function TimeSlider({itemIndex, timer, onTimerChange}) {
  const classes = useStyles();

  const handleSliderChange = (event, newValue) => {
    onTimerChange(itemIndex, newValue);
  };

  const handleInputChange = event => {
    onTimerChange(
      itemIndex,
      event.target.value === '' ? '' : Number(event.target.value),
    );
  };

  const handleBlur = () => {
    if (timer <= 1) {
      onTimerChange(itemIndex, 1);
    } else if (timer > 100) {
      onTimerChange(itemIndex, 100);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <TimerIcon />
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof timer === 'number' ? timer : 1}
            min={1}
            max={100}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Input
            className={classes.input}
            value={timer}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: 1,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

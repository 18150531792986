import React, {useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import {SHA512} from 'crypto-js';

import api from '../../services/api';
import whichError from '../../services/whichError';

import Input from '../../components/Input';

const PwdForm = ({userId, checkKey}) => {
  const formRef = useRef(null);
  const history = useHistory();

  const Login = useSelector(state => state.Login);

  const user = userId || Login.userId;

  const [userMsg, setUserMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pwdChanged, setPwdChanged] = useState(false);

  const handleSubmit = async (data, {reset}, event) => {
    event.preventDefault();

    setIsLoading(true);
    setUserMsg('');
    formRef.current.setErrors({});

    const yupP = !checkKey
      ? Yup.string()
          .transform((cv, ov) => (ov === '' ? null : cv))
          .nullable(true)
          .trim()
          .min(1, 'Mínimo 1 caractere')
          .required('Favor informar a senha')
      : null;

    const yupPN = Yup.string()
      .transform((cv, ov) => (ov === '' ? null : cv))
      .nullable(true)
      .trim()
      .min(6, 'Mínimo 6 caracteres!')
      .required('Favor informar a nova senha');

    const yupCPN = Yup.string()
      .transform((cv, ov) => (ov === '' ? null : cv))
      .nullable(true)
      .trim()
      .min(6, 'Mínimo 6 caracteres!')
      .required('Favor confirmar a senha')
      .test(
        'passwords-match',
        'As senhas devem se coincidir',
        val => formRef.current.getFieldValue('passwordNew') === `${val}`,
      );

    const np = {passwordNew: yupPN, confirmPasswordNew: yupCPN};
    const schema = Yup.object().shape(checkKey ? np : {...np, password: yupP});

    try {
      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
        // console.log('errorMessages :>> ', errorMessages);
      }

      setIsLoading(false);
      return;
    }

    const formData = schema.cast(data);

    const pwdData = {
      password: SHA512(formData.password).toString(),
      passwordNew: SHA512(formData.passwordNew).toString(),
    };

    const headers = checkKey ? {user, ckey: checkKey} : {user};
    const response = await api
      .post('/password', pwdData, {headers})
      .catch(err => setUserMsg(whichError(err).errorMsg));

    if (response) {
      localStorage.removeItem('changePassword');
      formRef.current.setErrors({});
      reset();
      setPwdChanged(true);
    }

    setIsLoading(false);
  };

  return pwdChanged ? (
    <>
      <h3>Senha Alterada</h3>
      <br />
      {checkKey && (
        <button
          className="btn login-small-btn"
          type="button"
          onClick={() => history.push('/login')}
        >
          Voltar
        </button>
      )}
    </>
  ) : (
    <Form onSubmit={handleSubmit} ref={formRef} className="login-password">
      {!checkKey && (
        <Input name="password" placeholder="Senha" type="password" />
      )}

      <Input name="passwordNew" placeholder="Senha" type="password" />
      <Input
        name="confirmPasswordNew"
        placeholder="Confirme a senha"
        type="password"
      />

      <button type="submit" disabled={isLoading}>
        Alterar Senha
      </button>

      <div className="user-msg-container">{userMsg}</div>
    </Form>
  );
};

const Password = ({history, userId, checkKey}) => {
  return checkKey ? (
    <PwdForm userId={userId} checkKey={checkKey} />
  ) : (
    <div className="s-a login-background">
      <div className="login-space">
        <div className="login-container">
          <div className="login-content">
            <h1>Alteração de senha</h1>

            <PwdForm userId={userId} checkKey={checkKey} />

            <button
              className="btn login-small-btn"
              type="button"
              onClick={() => history.goBack()}
            >
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;

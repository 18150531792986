import {ADD_LOGIN_USER, RESET_LOGIN} from '../actions/login';

const initialState = {
  userId: null,
  name: null,
  img: null,
  points: null,
  used: null,
  status: null,
  token: null,
  sToken: null,
};

export default function Login(state = initialState, action) {
  switch (action.type) {
    case ADD_LOGIN_USER: {
      return {...state, ...action.userParams};
    }

    case RESET_LOGIN: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

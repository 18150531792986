import React, {useState} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const DatePicker = ({label = null, onDateChange = () => null, className}) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = date => {
    setSelectedDate(date);
    onDateChange(date);
  };

  return (
    <div className={className}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          // margin="normal"
          id="date-picker-inline"
          label={label}
          value={selectedDate}
          onChange={handleDateChange}
          autoOk
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />

        {/* <KeyboardDatePicker
        margin="normal"
        id="date-picker-dialog"
        label={label}
        format="dd/MM/yyyy"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      /> */}
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePicker;

import React from 'react';
import {Element} from 'react-scroll';

import servicosEmpresas from '../../../assets/img-servicos-empresas.png';
import servicosCidades from '../../../assets/img-servicos-cidades.png';

import './styles.css';

const Servicos = () => {
  return (
    <Element name="servicos" className="servicos">
      <h1 className="servicos-title">SERVIÇOS</h1>

      <div className="servicos-cards">
        <div className="servicos-pre-card">
          <div className="servicos-icons-container">
            <img
              src={servicosEmpresas}
              className="servicos-icons"
              alt="Serviços"
            />
            <h3>CONSULTORIA AMBIENTAL</h3>
            <h3 className="white">EMPRESAS</h3>
          </div>

          <div className="servicos-card size-18">
            <ul>
              <li>
                Estudos - Projetos - Consultoria - (água, efluentes, resíduos)
              </li>
              <li>Licenciamento Ambiental Simplificado.</li>
              <li>Gerenciamento de Programas Ambientais</li>
              <li>Relatório Técnico de Avaliação de Ruído Ambiental </li>
              <li>Plano de Controle Ambiental - PCA</li>
              <li>Relatório de Controle Ambiental- RCA</li>
              <li>Estudo de Impacto Ambiental - EIA</li>
              <li> Relatório de Impacto Ambiental - RIMA</li>
              <li>Estudo de Impacto de Vizinhança – EIV</li>
              <li>Plano de Recuperação de Áreas Degradadas–PRAD;</li>
              <li>Projeto Técnico de Restituição de Flora -PTRF;</li>
              <li>Plano de Gerenciamento de Resíduos Sólidos – PGRS</li>
            </ul>
          </div>
        </div>

        <div className="servicos-pre-card">
          <div className="servicos-icons-container">
            <img
              src={servicosCidades}
              className="servicos-icons"
              alt="Serviços"
            />
            <h3>CONSULTORIA AMBIENTAL</h3>
            <h3 className="white">CIDADES</h3>
          </div>

          <div className="servicos-card size-18">
            <ul>
              <li>Gestão Ambiental Municipal;</li>
              <li>Suporte Técnico para CODEMA;</li>
              <li>Elaboração do Plano Municipal de Saneamento Básico;</li>
              
              <li>
                Elaboração do Plano de Gerenciamento Integrado de Resíduos
                Sólidos;
              </li>
              <li>
                Suporte técnico para implantação do plano de gerenciamento
                integrado de resíduos sólidos;
              </li>
              <li>
                Acompanhamento para cumprimento de Termo de ajustamento de
                conduta – Tacs;
              </li>
              <li>
                Elaboração e execução de projetos de educação e sensibilização
                ambiental;
              </li>
              <li>Desenvolvimento de ferramentas de gestão ambiental;</li>
              <li>Palestras, cursos e capacitação de pessoal.</li>
            </ul>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Servicos;

// useStyles.js
import {makeStyles} from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles(theme => {
  // console.log('theme.breakpoints', theme.breakpoints.up('sm'));
  // console.log('theme.mixins.toolbar', theme.mixins.toolbar);

  return {
    root: {
      display: 'flex',
    },

    logo: {
      maxHeight: 50,
    },

    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },

    appBar: {
      zIndex: theme.zIndex.drawer + 100,

      // marginLeft: drawerWidth,
      // [theme.breakpoints.up('sm')]: {
      //   width: `calc(100% - ${drawerWidth}px)`,
      // },
    },

    appTitle: {
      flexGrow: 1,
    },

    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },

    toolbar: theme.mixins.toolbar,

    drawerPaper: {
      width: drawerWidth,
    },

    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  };
});

export default useStyles;

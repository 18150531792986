import React from 'react';

import iconSA from '../../../assets/icon-suporte-ambiental.png';

import './styles.css';

const Principal = () => {
  return (
    <div className="footer">
      <div className="links">
        <div className="footer-section">
          <h5>Institucional</h5>

          <p>
            <a href="https://policy.suporteambiental.com/privacidade">
              Política de Privacidade
            </a>
          </p>
          <p>
            <a href="https://policy.suporteambiental.com/termos">
              Termos de Uso do Serviço
            </a>
          </p>
        </div>

        <div className="footer-section">
          <h5>Nossas Marcas</h5>
          <p>
            <a href="/pegada">Pegada Ambiental</a>
          </p>
          <p>
            <a href="/pegada">zer0</a>
          </p>
        </div>

        <div className="footer-section icon-sa">
          <img src={iconSA} alt="Suporte Ambiental" />
        </div>
      </div>

      
    </div>
  );
};

export default Principal;

import React, {useEffect, useState} from 'react';
import {PDFViewer} from '@react-pdf/renderer';

import Grid from '@material-ui/core/Grid';

import Loading from '../../../components/Loading';

import PDF from './PegadaAmbientalPDF';

const PegadaAmbiental = ({
  opName,
  start,
  end,
  data = null,
  pegadaAmbiental = {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (result) return;

    const reducedResult = data.reduce((a, c) => {
      const newA = {...a};
      newA[c.product_id] = newA[c.product_id]
        ? {
            ...newA[c.product_id],
            quantity: newA[c.product_id].quantity + c.quantity,
          }
        : {name: c.oiItem.name, quantity: c.quantity};

      return newA;
    }, {});

    setResult(pegadaAmbiental.calculate(reducedResult));
    setIsLoading(false);
  }, [data, pegadaAmbiental, result]);

  return (
    <Grid container direction="column">
      {isLoading ? (
        <Loading />
      ) : result ? (
        <PDFViewer style={{height: 600}}>
          <PDF opName={opName} start={start} end={end} result={result} />
        </PDFViewer>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default PegadaAmbiental;

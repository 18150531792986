// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/background-pegada.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.download {\n  background-color: #fff;\n}\n\n.download p {\n  font-size: 16px;\n  padding: 4vw 12vw;\n}\n\n.download-now {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n  align-items: center;\n  padding-bottom: 50px;\n}\n\n.download-stores {\n  text-align: center;\n  padding-bottom: 50px;\n}\n\n.icon-store {\n  \n  width: 250px;\n}\n\n.download-stores h1 {\n  margin-top: 20px;\n  color: #5bbc75;\n}\n\n.img-app-zero {\n  width: 90vw;\n  max-width: 400px;\n  padding: 30px;\n}\n\n\n\n\n.grid {\n  display: grid;\n}\n\n.center {\n  display: grid;\n  place-content: center;\n\n}\n.pegada-sobre {\n  background: #f5f5f5 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: cover;\n  background-attachment: fixed !important;\n  background-position: center top;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: #fff;\n}\n@media (min-width: 768px) {\n  .d-none-lg {\n    display: none;\n  }\n  .d-none-md {\n    display: block;\n  }\n}\n@media (max-width: 768px) {\n  \n  .d-none-lg {\n    display: block;\n  }\n  .d-none-md {\n    display: none;\n  }\n}", ""]);
// Exports
module.exports = exports;

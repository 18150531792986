import React, {useRef} from 'react';
import {Element} from 'react-scroll';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import api from '../../../services/api';
import whichError from '../../../services/whichError';

import Input from '../../../components/Input';

import contatoPin from '../../../assets/icon-contatos-pin.png';
import contatoEmail from '../../../assets/icon-contatos-email.png';
import contatoFacebook from '../../../assets/icon-contatos-facebook.png';
import contatoInstagram from '../../../assets/icon-contatos-instagram.png';
import contatoWhatsapp from '../../../assets/icon-contatos-whatsapp.png';
import * as icon from 'react-icons/fa';

import './styles.css';

const Contato = () => {
  const formRef = useRef(null);

  const handleSubmit = async (data, {reset}) => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .transform((cv, ov) => (ov === '' ? null : cv))
          .nullable(true)
          .trim()
          .min(1, 'O nome deve ter no mínimo 1 caractere.')
          .required('Favor informar o seu nome'),
        email: Yup.string()
          .transform((cv, ov) => (ov === '' ? null : cv))
          .nullable(true)
          .trim()
          .email('Favor informar um email válido.')
          .required('Favor informar o seu e-mail'),
        phone: Yup.string()
          .transform((cv, ov) => (ov === '' ? null : cv))
          .nullable(true)
          .trim(),
        msg: Yup.string()
          .transform((cv, ov) => (ov === '' ? null : cv))
          .nullable(true)
          .trim()
          .min(10, 'A mensagem deve ter no mínimo 10 caracteres.')
          .required('Favor informar a mensagem'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const formData = schema.cast(data);

      const send = await api
        .post(`/message`, formData)
        // eslint-disable-next-line no-alert
        .catch(err => alert(whichError(err).errorMsg));

      // console.log('send :>> ', send);

      // eslint-disable-next-line no-alert
      alert(`A mensagem ${!send ? 'não ' : ''} foi enviada!`);

      formRef.current.setErrors({});
      reset();
    } catch (err) {
      // console.log('error: ', err);
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
    }

    return false;
  };

  return (
    <Element name="contato" className="contato">
      <h1 className="section-title yellow">Contato</h1>
      <div className="contato-content">
        <div className="contato-dados">
          <h2>ONDE NOS ENCONTRAR</h2>

          <div className="contato-item-container">
            <div className="contato-item">
              <div className='contato-item-icon'>
              <icon.FaMapMarkerAlt className='icon' />
              </div>
              <span>
                Rua Rivadavio Lucas Mendes, 81 - Morada do Sol - Montes
                Claros/MG
              </span>
            </div>
          </div>

          <div className="contato-item-container">
            <div className="contato-item">
            <div className='contato-item-icon'>
              <a href="mailto:suporte@suporteambiental.com">
              <icon.FaEnvelope className='icon'/>
              </a>
              </div>
              <a href="mailto:suporte@suporteambiental.com">
                suporte@suporteambiental.com
              </a>
              
            </div>
            <div className="contato-item">
            <div className='contato-item-icon'>
              <a href="https://wa.me/553899739220">
              <icon.FaWhatsapp className='icon'/>
              </a>
              </div>
              <span>whatsapp</span>
              
            
            </div>
          </div>

          
        </div>

        <div className="contato-form">
        <h2>CONTATO POR E-MAIL</h2>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="contato-form-container"
          >
            <div className="box_input">
            <icon.FaUser className='icon_form'/>
            <Input name="name" placeholder="Nome" />
            </div>
            <div className="box_input">
            <icon.FaAt className='icon_form'/>
            <Input name="email" placeholder="E-mail" />
            </div>
            <div className="box_input">
            <icon.FaPhone className='icon_form'/>
            <Input name="phone" placeholder="Telefone" />
            </div>
            <Input name="msg" multiline placeholder="Mensagem" className="ta" />

            <button type="submit">Enviar</button>
          </Form>
        </div>
      </div>
    </Element>
  );
};

export default Contato;

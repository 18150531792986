import React, {useContext, useCallback, useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import VideoIcon from '@material-ui/icons/YouTube';
import PhotoIcon from '@material-ui/icons/PhotoSizeSelectActualTwoTone';

import {AlertContext} from '../../../../App';

import baseURL from '../../../../configs/baseURL';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

import NewMkt from './components/NewMkt';

const useStyles = makeStyles(() => ({
  currentCampaign: {maxWidth: 450},
}));

const Mkt = () => {
  const showAlert = useContext(AlertContext);

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);

  const loadData = useCallback(async () => {
    await api
      .get(`/mkt`)
      .then(async ({data}) => setResult(data))
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));
    setIsLoading(false);
  }, [showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const refreshCurrentCampaign = newCampaign => {
    setResult(newCampaign);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Header title="Marketing" />

      <div>
        <Typography variant="h6">Campanha em andamento</Typography>

        {!result || result.length === 0 ? (
          <p>Não há campanha em andamento</p>
        ) : (
          <Paper className={classes.currentCampaign}>
            <TableContainer>
              <Table aria-label="sticky table">
                <TableBody>
                  {result.map((campaignItem, index) => {
                    return (
                      <TableRow
                        hover
                        style={{height: 120}}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${index}.${campaignItem.img || campaignItem.yt}`}
                      >
                        <TableCell style={{width: 80, textAlign: 'center'}}>
                          <Typography>{index + 1}</Typography>
                        </TableCell>

                        <TableCell style={{width: 60, textAlign: 'center'}}>
                          {campaignItem.img ? <PhotoIcon /> : <VideoIcon />}
                        </TableCell>

                        {/* <TableCell>
                          {campaignItem.img ? (
                            <>
                              <img
                                src={`${baseURL.addr}/imgs/mkt/${campaignItem.img}`}
                                alt={campaignItem.img}
                                style={{width: 192, height: 108}}
                              />

                              <Typography>
                                {campaignItem.timer} segundos
                              </Typography>
                            </>
                          ) : (
                            <img
                              src={`https://img.youtube.com/vi/${campaignItem.yt}/mqdefault.jpg`}
                              alt="Video Thumbnail Preview"
                              style={{width: 192, height: 108}}
                            />
                          )}
                        </TableCell> */}

                        <TableCell>
                          <img
                            src={
                              campaignItem.img
                                ? `${baseURL.addr}/imgs/mkt/${campaignItem.img}`
                                : `https://img.youtube.com/vi/${campaignItem.yt}/mqdefault.jpg`
                            }
                            alt={campaignItem.img || 'Video Thumbnail Preview'}
                            style={{width: 192, height: 108}}
                          />

                          <Typography>
                            {campaignItem.img
                              ? `${campaignItem.timer} segundos`
                              : `${campaignItem.yt}`}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </div>

      <Box mt={3} />
      <Divider pt={2} />
      <Box mt={3} />

      <NewMkt refreshCurrentCampaign={refreshCurrentCampaign} />
    </>
  );
};

export default Mkt;

import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import SaveIcon from '@material-ui/icons/SaveOutlined';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import ImgPicker from '../../../../components/ImgPicker';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },

  textInput: {
    maxWidth: 250,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  number: {
    width: 121,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  description: {
    maxWidth: 508,
  },
}));

const options = {
  5: {value: 5, label: 'Metal'},
  6: {value: 6, label: 'Plástico'},
  7: {value: 7, label: 'Vidro'},
  8: {value: 8, label: 'Papel'},
};

const visibility = {
  0: {value: 'false', label: 'Visível'},
  1: {value: 'true', label: 'Oculto'},
};

const Recyclable = ({history, match}) => {
  const showAlert = useContext(AlertContext);
  const {params} = match;
  const {id: itemId} = params;

  const classes = useStyles();
  const formRef = useRef(null);

  const [img, setImg] = useState(null);
  const [itemData, setItemData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    await api
      .get(`/core/items/${itemId}`)
      .then(async res => {
        // console.log('res.data :>> ', res.data);
        setItemData(res.data);
        setIsLoading(false);
      })
      .catch(async e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [itemId, showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = async (unformData, {reset}) => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      category: Yup.number()
        .transform((cv, or) =>
          or === '' || or === '0' ? null : or === '$null$' ? 0 : cv,
        )
        .nullable(true)
        .typeError('Favor informar um id válido')
        .positive('Favor informar um id válido')
        .required('Favor informar o id da categoria'),
      name: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor informar o id da publicação 1'),
      weight: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido')
        .required('Informe o peso'),
      water: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido')
        .required('Informe a economia de água'),
      energy: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido')
        .required('Informe a economia de energia'),
      co2: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido')
        .required('Informe a economia de CO2'),
      description: Yup.string()
        // .transform((cv, ov) => (ov === '' ? null : cv))
        // .nullable(true)
        .trim(),
      order: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido'),
      // .trim(),
      hide: Yup.boolean().required('Informe a visibilidade'),
      // .oneOf([true], 'The terms and conditions must be accepted.'),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);

    // unformData.imgAction =
    //   userHasImg && imgAction && img === null
    //     ? 'remove'
    //     : userHasImg && !imgAction && img === null
    //     ? 'keep'
    //     : 'update';

    // eslint-disable-next-line no-undef
    const formData = new FormData();

    Object.entries(data).forEach(field =>
      formData.append(
        field[0],
        field[1] === undefined || field[1] === null ? '' : field[1],
      ),
    );

    if (img) formData.append('img', img);

    const save = await api
      .put(`/core/items/${itemId}`, formData)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (save) {
      reset();
      history.goBack();
    }
  };

  // const handleDelete = async () => {
  //   const delItem = await api
  //     .delete(`/core/items/${itemId}`)
  //     .catch(err => alert(whichError(err).errorMsg));

  //   if (delItem) {
  //     history.goBack();
  //   }
  // };

  const iD = {
    category: itemData ? options[itemData.productCategory.id].value : '',
    name: itemData ? itemData.name : '',
    weight: itemData ? itemData.weight : '',
    water: itemData ? itemData.water : '',
    energy: itemData ? itemData.energy : '',
    co2: itemData ? itemData.co2 : '',
    description: itemData ? itemData.description : '',
    order: itemData ? itemData.order : '',
    hide: itemData ? (itemData.hide === false ? 'false' : 'true') : '',
  };

  return (
    <>
      <Header title="Detalhes do reciclável" />

      <Paper className={classes.root}>
        {isLoading ? (
          <Loading />
        ) : (
          <Form ref={formRef} onSubmit={handleSubmit} initialData={iD}>
            {/* <ImgPicker height={153} width={272} onChange={setImg} /> */}
            <ImgPicker
              height={150}
              width={150}
              image={itemData ? itemData.img : null}
              onChange={setImg}
            />

            <Select
              label="Categoria *"
              name="category"
              options={Object.values(options)}
              variant="outlined"
              fullWidth
              className={classes.textInput}
            />

            <Input
              label="Nome do reciclável *"
              name="name"
              className={classes.textInput}
              variant="outlined"
              fullWidth
            />

            <br />
            <br />

            <Input
              label="Água *"
              name="water"
              className={classes.number}
              variant="outlined"
              inputProps={{
                step: 0.001,
                min: 0.001,
                max: 100,
                type: 'number',
              }}
            />

            <Input
              label="Energia *"
              name="energy"
              className={classes.number}
              variant="outlined"
              inputProps={{
                step: 0.001,
                min: 0.001,
                max: 100,
                type: 'number',
              }}
            />

            <Input
              label="CO2 *"
              name="co2"
              className={classes.number}
              variant="outlined"
              inputProps={{
                step: 0.001,
                min: 0.001,
                max: 100,
                type: 'number',
              }}
            />

            <Input
              label="Peso *"
              name="weight"
              className={classes.number}
              variant="outlined"
              inputProps={{
                step: 0.001,
                min: 0.001,
                max: 100,
                type: 'number',
              }}
            />

            <br />
            <br />

            <Input
              label="Descrição"
              name="description"
              multiline
              variant="outlined"
              fullWidth
              className={classes.description}
              // inputProp
            />

            <br />
            <br />

            <Select
              label="Visibilidade *"
              name="hide"
              options={Object.values(visibility)}
              variant="outlined"
              fullWidth
              className={classes.number}
            />

            <Input
              label="Ordem"
              name="order"
              variant="outlined"
              fullWidth
              className={classes.number}
              inputProps={{
                step: 1,
                min: 1,
                max: 100,
                type: 'number',
              }}
            />

            <br />
            <br />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Salvar alterações
            </Button>
          </Form>
        )}
      </Paper>
    </>
  );
};

export default Recyclable;

import React, {useContext, useState, useCallback, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import {Link} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ListIcon from '@material-ui/icons/ListAltOutlined';
import AddIcon from '@material-ui/icons/Add';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';
import AddColeta from '../../components/AddColeta';
import AddPegada from '../../components/AddPegada';

import PointsTrade from './components/PointsTrade';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },

  optionsBtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const categories = {
  // coleta: {id: 10, subTitle: 'Coleta'},
  // pontos: {id: 9, subTitle: 'Troca de Pontos'},
  9: 'Troca de Pontos',
  10: 'Coleta',
};

const Partnership = ({history, match}) => {
  const showAlert = useContext(AlertContext);
  const {params} = match;
  const {id} = params;

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);

  const [isLoadingPegada, setIsLoadingPegada] = useState(true);
  const [pegada, setPegada] = useState({water: 0, energy: 0, co2: 0});

  const [addColeta, setAddColeta] = useState(false);
  const [addPegada, setAddPegada] = useState(false);

  const loadPegada = useCallback(
    async eventId => {
      setIsLoadingPegada(true);

      await api
        .get(`/pegada?o=${eventId}`)
        .then(async res => {
          setPegada(res.data);
          setIsLoadingPegada(false);
        })
        .catch(async e => {
          showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
          setIsLoadingPegada(false);
        });
    },
    [showAlert],
  );

  const loadData = useCallback(async () => {
    setIsLoading(true);

    await api
      .get(`/core/partnerships/${id}`)
      .then(async res => {
        setResult(res.data);
        loadPegada(res.data.event_id);
        setIsLoading(false);
      })
      .catch(async e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [id, loadPegada, showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData, loadPegada]);

  const onCloseColeta = () => {
    setAddColeta(false);
    loadPegada(result.event_id);
  };

  const onClosePegada = () => {
    setAddPegada(false);
    loadPegada(result.event_id);
  };

  return (
    <>
      <Header
        title="Parceiro"
        subTitle={isLoading ? '' : categories[result.category_id] || ''}
      />

      <Paper className={classes.root}>
        {isLoading ? (
          <Loading />
        ) : !result ? (
          // <p>REDIRECIONAR</p>
          <></>
        ) : result.category_id === 9 ? (
          <PointsTrade result={result} />
        ) : (
          <>
            <Typography color="textPrimary" variant="h5">
              {result.name}
            </Typography>

            <br />

            <Typography variant="h6">Descrição:</Typography>
            <Typography>{result.description || '- sem descrição -'}</Typography>

            <br />

            <Typography variant="h6">Usuário:</Typography>
            <Link
              component={RouterLink}
              to={`/dashboard/zer0/usuario/${result.partner_id}`}
            >
              <Typography>{result.partner.userInformations.name}</Typography>
            </Link>

            {result.category_id === 10 && (
              <>
                <br />

                <Typography variant="h6">Pegada</Typography>

                {isLoadingPegada ? (
                  <Loading />
                ) : (
                  <>
                    <Typography variant="subtitle2">
                      Água: {pegada.water}
                    </Typography>
                    <Typography variant="subtitle2">
                      Energia: {pegada.energy}
                    </Typography>
                    <Typography variant="subtitle2">
                      CO2: {pegada.co2}
                    </Typography>
                  </>
                )}
              </>
            )}

            <Box mt={3} />
            <Divider pt={2} />
            <Box mt={3} />

            {result.category_id === 10 && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.optionsBtns}
                  onClick={() => setAddColeta(result.event_id)}
                  startIcon={<AddIcon />}
                >
                  Adicionar coleta
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.optionsBtns}
                  onClick={() => setAddPegada(result.id)}
                  startIcon={<AddIcon />}
                >
                  Adicionar pegada
                </Button>
              </Box>
            )}

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.optionsBtns}
              onClick={() =>
                result.category_id === 10 || result.category_id === 12
                  ? history.push(
                      `/dashboard/zer0/operacao/${result.event_id}/historico/`,
                    )
                  : null
              }
              startIcon={<ListIcon />}
            >
              Histórico
            </Button>

            {addColeta && (
              <AddColeta
                eventId={addColeta}
                userId={result.partner_id}
                onClose={onCloseColeta}
              />
            )}

            {addPegada && (
              <AddPegada
                type="partnership"
                eventId={addPegada}
                onClose={onClosePegada}
              />
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default Partnership;

import React, {
  useContext,
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';

// import MetaTags from 'react-meta-tags';

import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {Dots} from 'react-activity';
import 'react-activity/dist/react-activity.css';

import {SocketContext} from '../../App';

import api from '../../services/api';

import logoPegadaAmbientalG from '../../assets/display-logo-pegada-g.png';
import iconWater from '../../assets/display-icon-water.png';
import iconEnergy from '../../assets/display-icon-energy.png';
import iconCO2 from '../../assets/display-icon-co2.png';

import './styles.css';

Numeral.locale('pt-br');

const alias = {
  montesul: '36eae8db-240b-466f-8730-9baabf5ddd23',
  varanda: 'ae1755d0-7b6e-486e-b92d-ffe567fdd8ec',
};

const PegadaDisplay = ({match}) => {
  const io = useContext(SocketContext);

  const {operation} = match.params;
  const operationId = alias[operation] || operation;

  const [pegada, setPegada] = useState({water: 0, energy: 0, co2: 0});
  const [isLoading, setIsLoading] = useState(true);
  const [msg, setMsg] = useState('carregando...');

  const loadData = useCallback(async () => {
    const getPegada = await api
      .get(`/pegada${operation ? `?o=${operationId}` : ''}`)
      .catch(() => setMsg('Erro ao buscar dados!'));
    if (getPegada) {
      setPegada(getPegada.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setMsg('Erro ao buscar dados...');
    }
  }, [operation, operationId]);

  useLayoutEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    io.on('RefreshDisplay', () => {
      window.location.reload();
    });
  }, [io]);

  useEffect(() => {
    io.on('pegada', data => {
      // if (operation === )
      setPegada(data);
    });
  }, [io]);

  return (
    <div className="display-container">
      {/* <MetaTags>
        <meta httpEquiv="refresh" content="600" />
      </MetaTags> */}

      {isLoading ? (
        <div className="display-rotated">
          <h1>{msg}</h1>
        </div>
      ) : (
        <div className="display-rotated">
          <div className="display-view-container">
            <div className="display-geral-container">
              <img
                src={logoPegadaAmbientalG}
                className="display-pegada-logo"
                alt="Pegada Ambiental"
              />

              <div className="display-pegada-item">
                <div className="display-pegada-icon-container">
                  <img src={iconWater} alt="Água" />
                </div>

                <div className="display-pegada-text-container">
                  {isLoading ? (
                    <Dots color="#727981" size={10} speed={1} animating />
                  ) : (
                    <p>
                      {`${Numeral(pegada.water).format('0,0')}`} litros de água
                    </p>
                  )}
                </div>
              </div>

              <div className="display-pegada-item">
                <div className="display-pegada-icon-container">
                  <img src={iconEnergy} alt="Energia" />
                </div>

                <div className="display-pegada-text-container">
                  {isLoading ? (
                    <Dots color="#727981" size={10} speed={1} animating />
                  ) : (
                    <p>
                      {`${Numeral(pegada.energy).format('0,0')}`} kWh de energia
                    </p>
                  )}
                </div>
              </div>

              <div className="display-pegada-item">
                <div className="display-pegada-icon-container">
                  <img src={iconCO2} alt="CO2" />
                </div>

                <div className="display-pegada-text-container">
                  {isLoading ? (
                    <Dots color="#727981" size={10} speed={1} animating />
                  ) : (
                    <p>{`${Numeral(pegada.co2).format('0,0')}`} kg de CO₂eq</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PegadaDisplay;

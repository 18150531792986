import React, {useState} from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import UISelect from '@material-ui/core/Select';

const Select = ({
  name,
  label,
  options,
  selected,
  empty = true,
  onChangeValue = () => null,
  ...rest
}) => {
  const [selectedValue, setSelectedValue] = useState(selected || undefined);

  const handleChange = ({target}) => {
    const {value} = target;
    onChangeValue(value);
    setSelectedValue(value);
  };

  return (
    <FormControl {...rest}>
      <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
      <UISelect
        native
        value={selectedValue}
        onChange={handleChange}
        label={label}
      >
        {empty && <option aria-label="None" value="" />}

        {options.map(opt => (
          <option key={`${opt.value}:${opt.label}`} value={`${opt.value}`}>
            {opt.label}
          </option>
        ))}
      </UISelect>
    </FormControl>
  );
};

export default Select;

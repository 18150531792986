export const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const unique = (data, keyGetter) => {
  return [...new Set(data.map(keyGetter))];
};

export const mapById = (data, indexProp) => {
  const idMap = new Map();
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    idMap.set(item[indexProp], item);
  }
  return idMap;
};

export const objById = (data, indexProp) => {
  const object = {};
  data.forEach(item => {
    if (Array.isArray(indexProp)) {
      const valArray = indexProp.map(i => item[i]);
      const index = valArray.join('_');
      object[index] = item;
    } else {
      object[item[indexProp]] = item;
    }
  });
  return object;
};

export const section = (data, keyGetter) => {
  const categories = unique(data, keyGetter);
  const items = groupBy(data, keyGetter);

  const epSectionList = [];
  categories.forEach(item => {
    epSectionList.push({title: item, data: items.get(item)});
  });

  return epSectionList;
};

export const headerList = (data, keyGetter, wrapSection = false) => {
  const list = [];
  const indices = [0];
  const sectionData = section(data, keyGetter);

  sectionData.forEach(group => {
    list.push({header: true, section: group.title});
    if (wrapSection) {
      list.push({header: false, sectionData: group.data});
    } else {
      group.data.forEach(item => {
        list.push({header: false, section: group.title, item});
      });
    }
  });

  list.forEach(obj => {
    if (obj.header && list.indexOf(obj) !== 0) {
      indices.push(list.indexOf(obj));
    }
  });

  return {data: list, indices};
};

// essa função exige que os dados já venham odernados por categoria
export const headerList2 = (data, keyGetter) => {
  const list = [];

  data.forEach(item => {
    if (list.length === 0 || list[list.length - 1].section !== keyGetter(item))
      list.push({header: true, section: keyGetter(item)});

    list.push({header: false, section: keyGetter(item), item});
  });

  // arr.push(0);
  // this.setState({
  //   stickyHeaderIndices: arr,
  // });

  // console.log('list :', list);

  return null;
};

export const menuList = (data, labelGetter, valueGetter) =>
  data.map(x => ({
    label: Array.isArray(labelGetter)
      ? x[labelGetter[0]][labelGetter[1]]
      : x[labelGetter],
    value: x[valueGetter],
  }));

export const menuGroup = (data, keyGetter, valueGetter) => {
  const list = headerList(data, keyGetter);

  const menu = list.data.map(x => {
    return x.item
      ? {label: x.item[valueGetter[0]], value: x.item[valueGetter[1]]}
      : {label: x.section, value: '$section$'};
  });

  return {data: menu, indices: list.indices};
};

export const clone = object => {
  return JSON.parse(JSON.stringify(object));
};

import React, {useContext, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import Typography from '@material-ui/core/Typography';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

const Home = () => {
  const showAlert = useContext(AlertContext);

  const changePassword = localStorage.getItem('changePassword');

  const userStatus = useSelector(state => state.Login.status);

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);

  const loadData = useCallback(async () => {
    await api
      .get(`/core/profile`)
      .then(async ({data}) => {
        if (data) {
          setResult(data);
          setIsLoading(false);
        }
      })
      .catch(e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [showAlert]);

  useEffect(() => {
    if (changePassword) {
      setIsLoading(false);
      showAlert({
        title: 'Atenção',
        msg:
          'A senha usada é temporária e para ter acesso ao Painel é preciso criar uma nova senha.',
      });
      return;
    }
    loadData();
  }, [changePassword, loadData, showAlert]);

  return (
    <>
      <Header title="Painel zer0" />

      {isLoading ? (
        <Loading />
      ) : !result ? (
        <></>
      ) : (
        <>
          <Typography variant="h6">Olá {result.name}</Typography>
          <br />

          {userStatus === 3 && <Typography>{`${' '}`}</Typography>}

          {userStatus === 4 && (
            <Typography>Sua pontuação é {result.points || 0}</Typography>
          )}
        </>
      )}
    </>
  );
};

export default Home;

import React from 'react';

const Termos = () => {
  return (
    <div>
      <h4>
        <strong>1.</strong> Disposições Gerais
      </h4>
      <p>
        <strong>1.1.</strong> Os presentes Tópicos regulam os serviços
        oferecidos na plataforma <strong>ZER0</strong>, que consistem em coletar
        informações de coleta de recicláveis e seus benefícios através da
        contribuição dos usuários.{' '}
      </p>
      <p>
        <strong>1.2.</strong> A plataforma <strong>ZER0</strong> e suas
        funcionalidades são apresentadas aos usuários da maneira como estão
        disponíveis, podendo passar por constantes aprimoramentos e
        atualizações, obrigando-se a <strong>ZER0</strong> a:
      </p>
      <p>
        <strong>a)</strong> Preservar as funcionalidades da plataforma{' '}
        <strong>ZER0</strong>, com links não-quebrados, utilizando-se de layouts
        que respeitem e privilegiem a usabilidade e navegabilidade;
      </p>
      <p>
        <strong>1.3.</strong> A <strong>ZER0</strong> reafirma todos seus
        esforços na prestação contínua e permanente e eficaz de sua plataforma.
        Entretanto, ressalvando que poderá ocorrer, eventualmente, alguma
        indisponibilidade temporária decorrente de manutenção necessária ou
        mesmo gerada por motivo de força maior, como desastres naturais, falhas
        ou colapsos nos sistemas centrais de comunicação e acesso à internet ou,
        fatos de terceiro que fogem de sua esfera de vigilância e
        responsabilidade.
      </p>
      <p>
        <strong>1.3.1.</strong> A <strong>ZER0</strong> fará tudo que estiver ao
        seu alcance para restabelecer, em caso de indisponibilidade, o mais
        breve possível, o acesso à plataforma, dentro das possibilidades
        técnicas de seus serviços próprios e dos de terceiros.
      </p>
      <p>
        <strong>1.4.</strong> Eventuais procedimentos de manutenção necessária
        da plataforma <strong>ZER0</strong> serão informados, sempre que
        possível, com a devida antecedência e, através dos canais oficiais de
        comunicação da <strong>ZER0</strong>.
      </p>
      <h4>
        <strong>2.</strong> Cadastro de usuários
      </h4>
      <p>
        <strong>2.1.</strong> O cadastro na plataforma <strong>ZER0</strong> é
        gratuito, no entanto, os serviços oferecidos serão cobrados de acordo
        com o estabelecido pela <strong>ZER0</strong> em seus Termos.
      </p>
      <p>
        <strong>2.1.2.</strong> Realizado o cadastramento, o usuário se obriga a
        utilizar a plataforma <strong>ZER0</strong> de maneira ética e adequada
        aos propósitos estabelecidos nestes Termos, atendendo ao disposto nas
        leis brasileiras, em especial a Lei nº 9.613 de 1998 (“Lei de Lavagem de
        Dinheiro”), comprometendo-se a se abster de qualquer atividade que
        constitua violação das referidas normas.
      </p>
      <p>
        <strong>2.2.</strong> Os usuários devem fornecer informações exatas e
        verdadeiras, assumindo o compromisso de atualizar seus dados pessoais e
        dados de identificação da pessoa, física ou jurídica.
      </p>
      <p>
        <strong>2.2.1.</strong> Se tivermos motivos que indiquem que quaisquer
        informações cadastradas não são verdadeiras, teremos o direito de
        suspender ou encerrar a conta do usuário, bem como nos recusar a prover
        quaisquer de nossos serviços.
      </p>
      <p>
        <strong>2.2.2.</strong> A <strong>ZER0</strong> não se responsabiliza
        pela veracidade ou assertividade dos dados inseridos no cadastro, sendo
        que a criação de perfis falsos na plataforma <strong>ZER0</strong>{' '}
        poderá ser indiciado por crime de falsa identidade, falsidade ideológica
        ou estelionato.
      </p>
      <p>
        <strong>2.3.</strong> A <strong>ZER0</strong> poderá solicitar
        documentos pessoais ou outras formas de comprovação, por telefone,
        e-mail ou outro meio hábil, caso verifique a existência de atividade
        suspeita, ou transação que apresente considerável risco de fraude,
        poderá, a qualquer tempo e a seu exclusivo critério, confirmar a
        identidade e os dados pessoais do usuário,..{' '}
      </p>
      <p>
        <strong>2.3.1.</strong> Qualquer erro ou atraso no processo de envio ou
        confirmação da identidade do usuário que gere prejuízo ou dano de
        qualquer natureza será de responsabilidade do usuário.
      </p>
      <h4>
        <strong>3.</strong> ACESSIBILIDADE À <strong>ZER0</strong>
      </h4>
      <p>
        <strong>3.1.</strong> Os usuários acessarão sua conta através de login e
        senha pessoais e intransferíveis, ou através de contas pessoais providas
        por terceiros, como Facebook. De qualquer maneira, os usuários se
        comprometem a não informar à terceiros tais dados, responsabilizando-se
        integralmente pelo uso que deles seja feito, se comprometendo a
        notificar a <strong>ZER0</strong>, imediatamente, acerca de quaisquer
        usos não autorizados de sua conta ou quaisquer outras violações de
        segurança. A <strong>ZER0</strong> não será responsável por quaisquer
        perdas e danos resultantes de acessos não autorizados ou uso da conta.
      </p>
      <p>
        <strong>3.2.</strong> Apenas o titular da conta na <strong>ZER0</strong>{' '}
        poderá ter acesso aos dados relativos à mesma, tendo em vista seu
        caráter pessoal. Eventuais alterações em dados cadastrados somente
        poderão ser feitas pelo próprio usuário, devidamente logado. Em regra, a{' '}
        <strong>ZER0</strong> não realiza esse tipo de alteração,
        independentemente da razão alegada ou circunstância.
      </p>
      <h4>
        <strong>4.</strong> Direitos de Propriedade Intelectual
      </h4>
      <p>
        <strong>4.1.</strong> O uso comercial da expressão &quot;{' '}
        <strong>ZER0</strong> &quot; como marca, nome empresarial ou nome de
        domínio, bem como os conteúdos das telas relativas aos serviços da{' '}
        <strong>ZER0</strong>, assim como os programas são de titularidade da{' '}
        <strong>ZER0</strong> e estão protegidos pelas leis e tratados
        internacionais de direito autoral, marcas, patentes, modelos e desenhos
        industriais. O uso indevido e a reprodução total ou parcial dos
        referidos conteúdos são proibidos, salvo a autorização prévia e expressa
        por escrito da <strong>ZER0</strong>.
      </p>
      <h4>
        <strong>5.</strong> Privacidade dos usuários e Política de Privacidade
      </h4>
      <p>
        <strong>5.1.</strong> A <strong>ZER0</strong> possui documento próprio,
        denominado “Política de Privacidade”, que regula o tratamento dado às
        informações e dados pessoais coletados através da plataforma{' '}
        <strong>ZER0</strong> e suas funcionalidades.
      </p>
      <p>
        <strong>5.1.1.</strong> A “Política de Privacidade” é parte integrante e
        inseparável dos Termos de Uso da plataforma <strong>ZER0</strong> e pode
        ser acessada em link específico.
      </p>
      <p>
        <strong>5.1.2.</strong> Caso alguma disposição da “Política de
        Privacidade” conflite com qualquer outra do presente documento, deverá
        prevalecer o descrito na norma mais específica.
      </p>
      <h4>
        <strong>6.</strong> Disposições gerais
      </h4>
      <p>
        <strong>6.1.</strong> Ao navegar pela plataforma <strong>ZER0</strong> e
        utilizar suas funcionalidades, os usuários aceitam todo o disposto nos
        Termos de Uso e demais políticas legais que se encontram vigentes na
        data de acesso. Por isso, é recomendável que os usuários se mantenham
        atualizados.{' '}
      </p>
      <p>
        <strong>6.2.</strong> Os presentes Termos de Uso estão sujeitos a
        constante melhoria e aprimoramento. Assim, a <strong>ZER0</strong> se
        reserva o direito de modificá-los a qualquer momento, conforme a
        finalidade da plataforma <strong>ZER0</strong>, tal qual para adequação
        e conformidade legal de disposição de lei ou norma que tenha força
        jurídica equivalente, cabendo aos usuários verificá-los sempre que
        efetuar o acesso à plataforma <strong>ZER0</strong>.
      </p>
    </div>
  );
};

export default Termos;

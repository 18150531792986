import React from 'react';
import {
  Font,
  StyleSheet,
  Document,
  Page,
  Text,
  View,
} from '@react-pdf/renderer';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import Rubik from '../../../../../assets/fonts/Rubik.ttf';
import RubikBold from '../../../../../assets/fonts/Rubik-Bold.ttf';

import Header from './components/Header';
import Title from './components/Title';
import Pegada from './components/Pegada';

Font.register({
  family: 'Rubik',
  fonts: [{src: Rubik}, {src: RubikBold, fontWeight: 600}],
});

Numeral.locale('pt-br');

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Rubik',
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  reportHeader: {
    fontSize: 14,
    marginTop: 15,
    marginBottom: 10,
  },
  dateContainer: {
    borderTopWidth: 2,
    paddingVertical: 3,
    marginBottom: 20,
    fontSize: 12,
  },
  date: {
    fontSize: 13,
    marginBottom: 4,
    fontWeight: 'bold',
  },
  userContainer: {
    borderTopWidth: 1,
    fontSize: 12,
    paddingVertical: 6,
  },
  user: {
    fontWeight: 'bold',
  },
  itemTitle: {
    marginTop: 10,
  },
  rowItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    marginTop: 8,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ccc',
  },
});

const NovoNordiskUserDailyDetailedPDF = ({
  opName,
  start,
  end,
  dates,
  pAs,
  result,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <Header pages />

        <Title name={opName} />

        <View style={styles.reportHeader}>
          <Text>Relatório: Individualizado Diário</Text>
          <Text>
            Período: {format(new Date(`${start} 00:00`), 'dd/MM/yyyy')}
            {' - '}
            {format(new Date(`${end} 00:00`), 'dd/MM/yyyy')}
          </Text>
        </View>

        {dates.map(date => (
          <View style={styles.dateContainer} key={`${date}`}>
            <Text style={styles.date}>
              Data: {format(new Date(`${date} 00:00`), 'dd/MM/yyyy')}
            </Text>

            {Object.keys(result[date]).map(user => (
              <View
                style={styles.userContainer}
                key={`${date}.${user}`}
                wrap={false}
              >
                <Text>
                  Matrícula: <Text style={styles.user}>{user}</Text>
                </Text>

                <Text style={styles.itemTitle}>Itens:</Text>

                {Object.keys(result[date][user]).map(product => (
                  <View
                    key={`${date}.${user}.${product}`}
                    style={styles.rowItem}
                  >
                    <Text>{result[date][user][product].name}</Text>
                    <Text>
                      {Numeral(result[date][user][product].quantity).format(
                        '0,0',
                      )}
                    </Text>
                  </View>
                ))}

                <Text style={styles.itemTitle}>Pegada Ambiental:</Text>

                <Pegada
                  water={pAs[date][user].water || 0}
                  energy={pAs[date][user].energy || 0}
                  co2={pAs[date][user].co2 || 0}
                />
              </View>
            ))}
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default NovoNordiskUserDailyDetailedPDF;

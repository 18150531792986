import React from 'react';
import {
  Font,
  StyleSheet,
  Document,
  Page,
  Text,
  View,
} from '@react-pdf/renderer';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import Rubik from '../../../../../assets/fonts/Rubik.ttf';
import RubikBold from '../../../../../assets/fonts/Rubik-Bold.ttf';

import Header from './components/Header';
import Title from './components/Title';
import Pegada from './components/Pegada';

Font.register({
  family: 'Rubik',
  fonts: [{src: Rubik}, {src: RubikBold, fontWeight: 600}],
});

Numeral.locale('pt-br');

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Rubik',
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  reportHeader: {
    fontSize: 14,
    marginTop: 15,
    marginBottom: 10,
  },
  userContainer: {
    borderTopWidth: 2,
    paddingVertical: 6,
    fontSize: 12,
  },
  user: {
    fontWeight: 'bold',
  },
  itemTitle: {
    marginTop: 10,
  },
  rowItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    marginTop: 8,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ccc',
  },
});

const NovoNordiskUsersPDF = ({opName, start, end, pAs, result}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <Header pages />

        <Title name={opName} />

        <View style={styles.reportHeader}>
          <Text>Relatório: Acumulado do Usuário</Text>
          <Text>
            Período: {format(new Date(`${start} 00:00`), 'dd/MM/yyyy')}
            {' - '}
            {format(new Date(`${end} 00:00`), 'dd/MM/yyyy')}
          </Text>
        </View>

        {Object.keys(result).map(userId => (
          <View style={styles.userContainer} key={`${userId}`} wrap={false}>
            <Text>
              Matrícula: <Text style={styles.user}>{userId}</Text>
            </Text>

            <Text style={styles.itemTitle}>Itens:</Text>

            {Object.keys(result[userId]).map(product => (
              <View key={`${userId}.${product}`} style={styles.rowItem}>
                <Text>{result[userId][product].name}</Text>
                <Text>
                  {Numeral(result[userId][product].quantity).format('0,0')}
                </Text>
              </View>
            ))}

            <Text style={styles.itemTitle}>Pegada Ambiental:</Text>

            <Pegada
              water={pAs[userId].water || 0}
              energy={pAs[userId].energy || 0}
              co2={pAs[userId].co2 || 0}
            />
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default NovoNordiskUsersPDF;

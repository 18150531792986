/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState, useMemo} from 'react';
import clsx from 'clsx';

import {makeStyles} from '@material-ui/core/styles';

import baseURL from '../../configs/baseURL';

import camera from '../../assets/camera.svg';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 100,
    minWidth: 100,
    marginTop: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#ddd',
    borderStyle: 'dashed',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  noBorder: {
    borderWidth: 0,
  },
  none: {
    display: 'none',
  },
});

const ImgPicker = ({
  height = 100,
  width = 100,
  image = null,
  onChange = () => null,
}) => {
  const classes = useStyles();

  const [thumbnail, setThumbnail] = useState(null);

  // const preview = useMemo(() => {
  //   return thumbnail ? URL.createObjectURL(thumbnail) : null;
  // }, [thumbnail]);
  const preview = useMemo(() => {
    return thumbnail
      ? URL.createObjectURL(thumbnail)
      : image
      ? `${baseURL.addr}/imgs/products/${image}`
      : null;
  }, [image, thumbnail]);

  const handleOnChange = img => {
    // console.log('img :>> ', img);
    setThumbnail(img);
    onChange(img);
  };

  return (
    <div className={classes.root}>
      <label
        // className={clsx(classes.label, {
        //   [classes.noBorder]: thumbnail,
        // })}
        className={classes.label}
        style={{backgroundImage: `url(${preview})`, width, height}}
      >
        <input
          type="file"
          onChange={event => handleOnChange(event.target.files[0])}
          className={classes.none}
        />

        <img
          src={camera}
          alt="Select img"
          className={clsx({
            [classes.none]: thumbnail,
            [classes.none]: image,
          })}
        />
      </label>
    </div>
  );
};

export default ImgPicker;

import React, {useContext, useCallback, useEffect, useState} from 'react';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import FilterListIcon from '@material-ui/icons/FilterList';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import {menuList} from '../../../../tools/dataManager';

import DatePicker from '../../../../components/DatePicker';
import Select from '../../../../components/Select';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

Numeral.locale('pt-br');

const columns = [
  {
    id: 'createdAt',
    label: 'Data',
    minWidth: 80,
    width: 150,
    format: 'date',
  },
  {
    id: 'quantity',
    label: 'Quantidade',
    width: 110,
    format: 'number',
    align: 'center',
  },
  {id: 'item', label: 'Item', minWidth: 150},
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },

  filter: {
    marginTop: 10,
    marginRight: 10,
  },
});

const OperationHistory = ({match}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();

  const {id: opId} = match.params;

  const [filter, setFilter] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState(null);
  const [details, setDetails] = useState(null);
  const [result, setResult] = useState([]);
  const [resultInfo, setResultInfo] = useState({
    filter: '',
    page: 1,
    pages: 1,
    total: 0,
    limit: 100,
    orderBy: 'date',
    sortBy: 'DESC',
  });

  const [filterStart, setFilterStart] = useState(false);
  const [filterEnd, setFilterEnd] = useState(false);
  const [filterItem, setFilterItem] = useState(false);

  const loadData = useCallback(
    async (page = 1, limit = 100, start = null, end = null, item = null) => {
      setIsLoading(true);

      if (!details) {
        await api
          .get(`/core/operations/${opId}`)
          .then(async ({data}) => {
            if (data) setDetails(data);
          })
          .catch(e => {
            showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
            setIsLoading(false);
          });
      }

      if (!items) {
        await api
          .get(`/core/items?all=true`)
          .then(async ({data}) => {
            if (data) setItems(menuList(data.products, 'name', 'id'));
          })
          .catch(e => {
            showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
            setIsLoading(false);
          });
      }

      await api
        .get(
          `/core/oi?u=all&operation=${opId}&p=${page}&l=${limit}${
            filterStart ? `&start=${filterStart}` : ''
          }${filterEnd ? `&end=${filterEnd}` : ''}${
            filterItem ? `&item=${filterItem}` : ''
          }`,
        )
        .then(async res => {
          if (res.data) {
            const {operationItems, ...info} = res.data;
            setResult(operationItems);
            setResultInfo(info);
            setIsLoading(false);
          }
        })
        .catch(e => {
          showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
          setIsLoading(false);
        });
    },
    [details, filterEnd, filterItem, filterStart, items, opId, showAlert],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleChangePage = (event, newPage) => {
    loadData(
      resultInfo.filter,
      newPage + 1,
      resultInfo.limit,
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  const handleChangeRowsPerPage = ({target}) => {
    loadData(
      resultInfo.filter,
      1,
      parseInt(target.value),
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  const handleFilter = () => {
    if (filter) {
      setFilterStart(null);
      setFilterEnd(null);
      setFilterItem(null);
    }

    setFilter(p => !p);
  };

  const onFilterChange = (filterInput, value) => {
    const filterDate =
      filterInput === 'start' || filterInput === 'end'
        ? format(value, 'yyyy-MM-dd')
        : false;

    switch (filterInput) {
      case 'start':
        setFilterStart(filterDate);
        break;

      case 'end':
        setFilterEnd(filterDate);
        break;

      case 'item':
        setFilterItem(value);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Header
        title="Histórico de Operação"
        // subTitle={isLoading ? '' : details.name || ''}
      />

      <Button
        color="primary"
        size="small"
        className={classes.optionsBtns}
        onClick={handleFilter}
        startIcon={<FilterListIcon />}
      >
        Filtrar
      </Button>

      <Collapse
        in={filter}
        timeout="auto"
        unmountOnExit
        className={classes.filters}
      >
        <Grid
          container
          direction="row"
          // justify="space-between"
          // alignItems="flex-end"
        >
          <DatePicker
            label="Início"
            onDateChange={date => onFilterChange('start', date)}
            className={classes.filter}
          />
          <DatePicker
            label="Fim"
            onDateChange={date => onFilterChange('end', date)}
            className={classes.filter}
          />
          <Select
            label="Item"
            options={items || []}
            onChangeValue={val => onFilterChange('item', val)}
            className={classes.filter}
          />
        </Grid>
      </Collapse>

      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      ...(column.width ? {width: column.width} : {}),
                      ...(column.minWidth ? {minWidth: column.minWidth} : {}),
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                result.map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map(column => {
                        const {id, align, format: valFormat} = column;

                        const value =
                          column.id === 'item' ? row.oiItem.name : row[id];

                        return (
                          <TableCell key={`${id}.${valFormat}`} align={align}>
                            {valFormat === 'number' ? (
                              Numeral(value).format('0,0')
                            ) : valFormat === 'price' ? (
                              Numeral(value).format('$0,0')
                            ) : valFormat === 'date' ? (
                              format(new Date(value), 'dd/MM/yyyy HH:mm')
                            ) : valFormat === 'bool' ? (
                              <Checkbox checked={!!value} disabled />
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={resultInfo.total}
          rowsPerPage={resultInfo.limit}
          page={resultInfo.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Exibir:"
          // labelDisplayedRows={({from, to, count, page}) => {
          //   console.log('a', a);
          //   console.log('b', b);
          //   console.log('c', c);
          // }}
        />
      </Paper>
    </>
  );
};

export default OperationHistory;

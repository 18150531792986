import React, {useEffect, useState} from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import {format} from 'date-fns';

import Grid from '@material-ui/core/Grid';

import Loading from '../../../components/Loading';

import PDF from './DailyDetailedPDF';

const DailyDetailed = ({
  opName,
  start,
  end,
  data = null,
  pegadaAmbiental = {},
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [dates, setDates] = useState(null);
  const [pegadas, setPegadas] = useState(null);

  useEffect(() => {
    if (result) return;

    const reducedResult = data.reduce((a, c) => {
      const oiDate = format(new Date(c.createdAt), 'yyyy-MM-dd');

      const newA = {...a};
      newA[oiDate] = !newA[oiDate]
        ? {[c.product_id]: {name: c.oiItem.name, quantity: c.quantity}}
        : {
            ...newA[oiDate],
            [c.product_id]: !newA[oiDate][c.product_id]
              ? {name: c.oiItem.name, quantity: c.quantity}
              : {
                  ...newA[oiDate][c.product_id],
                  quantity: newA[oiDate][c.product_id].quantity + c.quantity,
                },
          };

      return newA;
    }, {});

    const reportPegadas = Object.keys(reducedResult).reduce((acc, rDate) => {
      const userPegada = pegadaAmbiental.calculate(reducedResult[rDate]);
      acc[rDate] = userPegada;
      return acc;
    }, {});

    const orderByDate = Object.keys(reducedResult).sort();

    setResult(reducedResult);
    setPegadas(reportPegadas);
    setDates(orderByDate);
    setIsLoading(false);
  }, [data, pegadaAmbiental, result]);

  return (
    <Grid container direction="column">
      {isLoading ? (
        <Loading />
      ) : dates && pegadas && result ? (
        <PDFViewer style={{height: 600}}>
          <PDF
            opName={opName}
            start={start}
            end={end}
            dates={dates}
            pAs={pegadas}
            result={result}
          />
        </PDFViewer>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default DailyDetailed;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/background-contato-form.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".contato {\n  background-color: #13a15a;\n  padding: 50px 0;\n}\n\n.contato-content {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n}\n\n.contato-dados {\n  padding-top: 100px;\n}\n\n.contato-dados h2 {\n  color: #f6f9f8;\n  padding: 10px;\n}\n\n.contato-form {\n  width: 580px;\n  height: 550px;\n  padding: 110px 20px 0 20px;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\n    no-repeat;\n  background-size: contain;\n  background-position: center;\n  color: #fff;\n  display: grid;\n  justify-content: center;\n}\n@media (max-width: 400px) {\n.contato-form {\n  background-size: cover\n}\n}\n\n.contato-form-container {\n  flex: 1 1;\n  position: relative;\n  max-width: 250px;\n}\n\n.ta {\n  min-height: 80px;\n}\n\n.contato-item-container {\n  width: 300px;\n  display: grid;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.contato-item {\n  display: flex;\n  /* justify-content: space-between; */\n  margin: 7px;\n}\n\n/* .contato-item img {\n  max-height: 30px;\n  margin-right: 10px;\n} */\n.contato-item-icon{\n  display: grid;\n  \n  margin-right:10px;\n  height: 40px;\n  color: #fafafa;\n}\n.icon{\n  width:30px;\n  height:30px;\n}\n\n.contato-item a,\n.contato-item span {\n  align-self: center;\n  text-decoration: none;\n  color: inherit;\n}\n\n.contato-redes {\n  margin-top: 20px;\n  background: red;\n  /* display: flex; */\n  display: grid;\n  place-items: center;\n  align-content: center;\n  /* flex-direction: row; */\n  /* justify-content: space-between; */\n}\n.contato-redes img{\n  width: 40px;\n  height: 40px;\n}\n\n@media (max-width: 900px) {\n  .contato-dados {\n    padding-top: 0;\n  }\n\n  .contato-form {\n    margin-top: 30px;\n  }\n}\n", ""]);
// Exports
module.exports = exports;

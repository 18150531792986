import React from 'react';

import {makeStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
  title: {
    marginTop: 10,
    minWidth: 200,
  },
  btn: {
    marginTop: 10,
  },
  divider: {
    marginTop: 15,
    marginBottom: 25,
  },
});

const Header = ({
  title = '',
  subTitle = false,
  btnTxt = false,
  btnIcon = false,
  onBtn = () => null,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
      >
        <Grid>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          {subTitle && <Typography variant="subtitle1">{subTitle}</Typography>}
        </Grid>

        {btnTxt && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onBtn}
            startIcon={btnIcon}
            className={classes.btn}
          >
            {btnTxt}
          </Button>
        )}
      </Grid>

      <Divider pt={2} className={classes.divider} />
    </>
  );
};

export default Header;

import React, {useContext,useRef, useState, useCallback, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Element, Link as Scroll, scroller } from 'react-scroll';
import { useHistory, Link } from 'react-router-dom';

import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import Menu, { Item as MenuItem, Divider } from 'rc-menu';

import logoSA from '../../../assets/logo-suporte-ambiental.png';
import iconFB from '../../../assets/icon-facebook.png';
import iconIG from '../../../assets/icon-instagram.png';
import logoPA from '../../../assets/logo-pegada-ambiental-escuro.png';
import logoZero from '../../../assets/logo-zer0-verde.png';

import Calculadora from './Calculadora';
import ReactPlayer from 'react-player'

import * as icon from 'react-icons/fa';
import google from '../../../assets/icon-download-google.png';
import zer01 from '../../../assets/zero-init.jpeg';
import video from '../../../assets/videos/reportagem.mp4'
import { motion } from "framer-motion/dist/framer-motion";
import ios from '../../../assets/icon-download-ios.png';

import './styles.scss';


function StoreS() {
  
  return (
    <div className="download-store">
      <h5 className='vitrine-titles'>Baixe nosso app <p>"PEGADA AMBIENTAL"</p></h5>
      <br /> 
      <a href="https://apps.apple.com/br/app/apple-store/id1533757263">
        <img src={ios} alt="AppStore" className="icon-store" />
      </a>
      <br />

      {/* <a href="https://play.google.com/store/apps/details?id=com.zer0"> */}
      <a href="https://api.suporteambiental.com/app/zer0.apk">
        <img src={google} alt="Download" className="icon-store" />
      </a>
    </div>
  )
}

const Principal = () => {
  const imgs = [

    { img: zer01, icon: <icon.FaUserPlus />, title: <StoreS />, text: '' },
    { img: zer01, icon: <icon.FaWineBottle />, title: 'Selecione as embalagens', text: 'No Aplicativo, selecione as embalagens que irá depositar na zer0' },
    { img: zer01, icon: <icon.FaMapMarkedAlt />, title: 'Vá ate um ecoponto', text: 'Encontre a zer0 mais próxima de você e deposite suas embalagens' },
    { img: zer01, icon: <icon.FaRecycle />, title: 'Visualize sua pegada embiental', text: 'Assim que depositar suas embalagens, acompanhe em tempo real, a economia abiental que vocé prpoporcionou e quantos pontos acumulou no nosso programa de cashback.' },
    { img: zer01, icon: <icon.FaRegGrinStars />, title: 'Troque seus pontos por benefícios', text: 'Localize no aplicativo as empresas parceiras do projeto e troque seus pontos por descontos exclusivos.' },
  ]
  const item = useRef()
  const history = useHistory();
  const [muted, setMuted] = useState(true)
  const userId = useSelector(state => state.Login.userId);

  const onClick = ({ item }) => {
    const { link } = item.props;
    if (link === 'login') {
      history.push(userId ? '/dashboard' : '/login/pegada');
    } else {
      scroller.scrollTo(link, { smooth: true });
    }
  };

  const NavMenu = () => (
    <Menu onClick={onClick} selectable={false}>
      <MenuItem key="1" link="sobre">
        Sobre
      </MenuItem>

      <Divider />

      <MenuItem key="2" link="projetos">
        Projetos
      </MenuItem>

      <Divider />

      <MenuItem key="3" link="calculadora">
        Calculadora
      </MenuItem>

      <Divider />

      <MenuItem key="4" link="download">
        Download
      </MenuItem>

      <MenuItem key="4" link="login">
        {userId ? 'Painel' : 'Login'}
      </MenuItem>
    </Menu>
  );

  return (
    <Element name="pegada-principal" className="pegada-principal">
      <header className="pegada-principal-header">
        <a href="https://suporteambiental.com">
          <img
            src={logoSA}
            className="pegada-principal-logo"
            alt="Suporte Ambiental"
          />
        </a>

        <div className="pegada-principal-nav">
          <nav className="nav-items">
            <Scroll to="sobre" smooth className="nav-item">
              SOBRE
            </Scroll>

            <Scroll to="projetos" smooth className="nav-item">
              PROJETOS
            </Scroll>

            <Scroll to="calculadora" smooth className="nav-item">
              CALCULADORA
            </Scroll>

            <Scroll to="download" smooth className="nav-item">
              DOWNLOAD
            </Scroll>

            <Link
              to={userId ? '/dashboard' : '/login/pegada'}
              className="nav-item"
            >
              {userId ? 'PAINEL' : 'LOGIN'}
            </Link>

          </nav>
        </div>

        <div className="pegada-responsive-menu">
          <Dropdown
            arrow
            trigger={['click']}
            overlay={NavMenu}
            animation="slide-up"
          >
            <button onClick={null} className="menu-btn" type="button">
              &nbsp;
            </button>
          </Dropdown>
        </div>
      </header>



      <div className="pegada-content">
        <Element name="sobre" className="pegada-sobre">
          <div className='grid center'>
            <div className="d-flex">
              <div className='grid center home'>
                <div className='home-content center'>
                <div className='grid center'>
                <img src={logoZero} alt="Pegada Ambiental" className="pegada-logo" />
                </div>
                  <p className='descricao'>
                    A Zer0 é uma máquina automatizada para logística reversa de embalagens pós consumo que traz, como premissa máxima, o resultado compartilhado pelo ciclo de vida das embalagens recicláveis.
                  </p>
                  <p className='descricao'>
                    Ao depositar as suas embalagens recicláveis na zer0 você consegue visualizar o ganho ambiental que está proporcionando e ainda acumula cashback para trocar em descontos exclusivos nas empresas parceiras do projeto.
                  </p>
                </div>
              </div>

              <div className='center'>
                <div className='video d-none-lg'>
                <ReactPlayer width={"350px"} height={"250px"}  playing={true} loop={true} muted={muted} onClick={()=> muted== false ? setMuted(true):setMuted(false)} url={video} />
                </div>
                <div className='video d-none-md'>
                <ReactPlayer width={"650px"} height={"450px"}  playing={true} loop={true} muted={muted} onClick={()=> muted== false ? setMuted(true):setMuted(false)} url={video} />
                </div>
              </div>
              

            </div>
          </div>
          
          <div className="container-app">
        {
          imgs.map((v, i) => (
            <div className='container-vitrine'>
              {/* <img src={v.img} alt="zer0" /> */}
              <div ><motion.div className="img img-icon" ref={item}>{v.icon}</motion.div></div>
              <div className='vitrine'>
                <div className='vitrine-title'>
                  {v.title}

                </div>
                <div className='vitrine-text'>
                  {v.text}
                </div>
              </div>
            </div>
          ))

        }

      </div>

        </Element>
        <div className="pegada-dados">
          <Calculadora />
        </div>
      </div>
    </Element>
  );
};

export default Principal;

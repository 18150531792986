import React from 'react';

import Principal from './Principal';
import Latinhas from './Latinhas';
import DownloadApp from './DownloadApp';
import Footer from './Footer';

export default function PegadaAmbiental() {
  return (
    <div className="s-a">
      <Principal />

      {/* <Latinhas /> */}

      <DownloadApp />

      <Footer />
    </div>
  );
}

import React from 'react';
import {Font, StyleSheet, View, Text, Image} from '@react-pdf/renderer';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import Rubik from '../../../../../../assets/fonts/Rubik.ttf';
import RubikBold from '../../../../../../assets/fonts/Rubik-Bold.ttf';

import iconWater from '../../../../../../assets/report-icon-water.png';
import iconEnergy from '../../../../../../assets/report-icon-energy.png';
import iconCO2 from '../../../../../../assets/report-icon-co2.png';

Numeral.locale('pt-br');

Font.register({
  family: 'Rubik',
  fonts: [{src: Rubik}, {src: RubikBold, fontWeight: 600}],
});

const styles = StyleSheet.create({
  rowPegada: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 3,
    marginTop: 8,
    marginBottom: 4,
  },
  pegadaContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 50,
    paddingRight: 5,
    width: 180,
  },
  icon: {
    height: 20,
    margin: 4,
  },
  pegada: {
    flex: 1,
    textAlign: 'center',
    fontFamily: 'Rubik',
  },
});

const PegadaAmbiental = ({water, energy, co2}) => (
  <View style={styles.rowPegada} wrap={false}>
    <View style={styles.pegadaContainer}>
      <Image src={iconWater} style={styles.icon} />
      <Text style={styles.pegada}>
        Água: {Numeral(water || 0).format('0,0.0')}L
      </Text>
    </View>

    <View style={styles.pegadaContainer}>
      <Image src={iconEnergy} style={styles.icon} />
      <Text style={styles.pegada}>
        Energia: {Numeral(energy || 0).format('0,0.0')} kWh
      </Text>
    </View>

    <View style={styles.pegadaContainer}>
      <Image src={iconCO2} style={styles.icon} />
      <Text style={styles.pegada}>
        CO₂: {Numeral(co2 || 0).format('0,0.0')} kg CO₂eq
      </Text>
    </View>
  </View>
);

export default PegadaAmbiental;

import React, {useContext, useRef, useState} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {makeStyles} from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Input from '../../../../components/Form/Input';

const useStyles = makeStyles(theme => ({
  input: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const AddPegada = ({eventId = false, onClose = () => null}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();
  const formRef = useRef(null);

  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async (unformData, {reset}) => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      weight: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido'),
      water: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido'),
      energy: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido'),
      co2: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido'),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);

    setIsSaving(true);

    const save = await api
      .post(`/core/partnership/${eventId}/pegada`, data)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setIsSaving(false);

    if (save) {
      reset();
      onClose();
    }
  };

  return (
    <Dialog
      open={!!eventId}
      onClose={() => (isSaving ? null : onClose())}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Adicionar Pegada</DialogTitle>

      <DialogContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            label="Água"
            name="water"
            className={classes.input}
            variant="outlined"
            inputProps={{
              step: 1,
              type: 'number',
            }}
            disabled={isSaving}
          />

          <Input
            label="Energia"
            name="energy"
            className={classes.input}
            variant="outlined"
            inputProps={{
              step: 1,
              type: 'number',
            }}
            disabled={isSaving}
          />

          <Input
            label="CO2"
            name="co2"
            className={classes.input}
            variant="outlined"
            inputProps={{
              step: 1,
              type: 'number',
            }}
            disabled={isSaving}
          />

          <Input
            label="Peso"
            name="weight"
            className={classes.input}
            variant="outlined"
            inputProps={{
              step: 1,
              type: 'number',
            }}
            disabled={isSaving}
          />
        </Form>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Cancelar
        </Button>

        <Button
          onClick={() => formRef.current.submitForm()}
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPegada;

import React from 'react';
import {
  Font,
  StyleSheet,
  Document,
  Page,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import Rubik from '../../../../../assets/fonts/Rubik.ttf';
import RubikBold from '../../../../../assets/fonts/Rubik-Bold.ttf';

import logoPegada from '../../../../../assets/display-logo-pegada-g.png';
import iconWater from '../../../../../assets/report-icon-water.png';
import iconEnergy from '../../../../../assets/report-icon-energy.png';
import iconCO2 from '../../../../../assets/report-icon-co2.png';

import Header from './components/Header';
import Title from './components/Title';

Font.register({
  family: 'Rubik',
  fonts: [{src: Rubik}, {src: RubikBold, fontWeight: 600}],
});

Numeral.locale('pt-br');

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Rubik',
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  reportHeader: {
    fontSize: 14,
    marginTop: 15,
    marginBottom: 10,
  },
  content: {
    flexDirection: 'column',
    alignItems: 'center',
    borderTopWidth: 2,
    paddingVertical: 6,
    fontSize: 25,
  },
  logoPegada: {
    height: 200,
    marginBottom: 20,
  },
  pegadaContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 4,
    borderRadius: 100,
    paddingRight: 5,
    width: 450,
    marginBottom: 20,
  },
  icon: {
    height: 50,
    margin: 20,
  },
  pegada: {
    flex: 1,
    textAlign: 'center',
    fontFamily: 'Rubik',
  },
});

const PegadaAmbientalPDF = ({opName, start, end, result}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <Header />

        <Title name={opName} />

        <View style={styles.reportHeader}>
          <Text>
            Período: {format(new Date(`${start} 00:00`), 'dd/MM/yyyy')}
            {' - '}
            {format(new Date(`${end} 00:00`), 'dd/MM/yyyy')}
          </Text>
        </View>

        <View style={styles.content}>
          <Image src={logoPegada} style={styles.logoPegada} />

          <View style={styles.pegadaContainer}>
            <Image src={iconWater} style={styles.icon} />
            <Text style={styles.pegada}>
              Água: {Numeral(result.water || 0).format('0,0.0')}L
            </Text>
          </View>

          <View style={styles.pegadaContainer}>
            <Image src={iconEnergy} style={styles.icon} />
            <Text style={styles.pegada}>
              Energia: {Numeral(result.energy || 0).format('0,0.0')} kWh
            </Text>
          </View>

          <View style={styles.pegadaContainer}>
            <Image src={iconCO2} style={styles.icon} />
            <Text style={styles.pegada}>
              CO₂: {Numeral(result.co2 || 0).format('0,0.0')} kg CO₂eq
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PegadaAmbientalPDF;

import React from 'react';
import {Element} from 'react-scroll';

import latinhas from '../../../assets/img-latinhas-reciclagem.png';

import './styles.css';

const Servicos = () => {
  return (
    <Element name="latinhas" className="latinhas">
      <div className="latinhas-content">
        <img src={latinhas} alt="Reciclagem" className="latinhas-reciclagem" />
        <h1>12 LATINHAS</h1>
        <p>
          = 20 DIAS DE ÁGUA POTÁVEL
          <br /> PARA UM SER HUMANO
          <br /> BEBER
        </p>
      </div>
    </Element>
  );
};

export default Servicos;

import React from 'react';

const Privacidade = () => {
  return (
    <div>
      <p>
        Este aplicativo é mantido e operado por{' '}
        <strong>Suporte Ambiental</strong>.
      </p>
      <p>
        Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles
        que utilizam nosso aplicativo. Ao fazê-lo, agimos na qualidade de{' '}
        <strong>controlador</strong> desses dados e estamos sujeitos às
        disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de
        Dados Pessoais - LGPD).
      </p>
      <p>
        Nós cuidamos da proteção de seus dados pessoais e, por isso,
        disponibilizamos esta política de privacidade, que contém informações
        importantes sobre:
      </p>
      <p>
        - Quem deve utilizar nosso aplicativo
        <br />- Quais recursos do dispositivo solicitamos e suas finalidades;
        <br />- Quais dados coletamos e o que fazemos com eles;
        <br />- Seus direitos em relação aos seus dados pessoais; e<br />- Como
        entrar em contato conosco.
      </p>

      <br />
      <h4>Quem deve utilizar nosso aplicativo</h4>
      <p>
        A plataforma <strong>ZER0</strong> não possui restrições de uso. Não
        contém material impróprio para crianças.
      </p>

      <br />
      <h4>Uso dos recursos do disposito e suas finalidades </h4>
      <p>
        <strong>- Câmera:</strong> fazer leitura do QRCode das máquinas de
        retorno de recicláveis;
        <br />
        <strong>- Localização:</strong> sugerir a máquina de retorno de
        recicláveis mais próxima;
        <br />
        <strong>- Conexões do dispositivo:</strong> fazer e monitorar a conexão
        entre o dispositivo e a máquina;
        <br />
        <strong>- Gravação e Leitura de Dados armazenados:</strong> Fotografar
        ou selecionar imagens para avatar do perfil;
      </p>

      <br />
      <h4>Dados que coletamos e motivos da coleta </h4>

      <p>
        Nosso aplicativo coleta e utiliza alguns dados pessoais de nossos
        usuários, de acordo com o disposto nesta seção.
      </p>
      <p>
        <strong>
          1. Dados pessoais fornecidos expressamente pelo usuário
          <strong>
            <br />
          </strong>
        </strong>
      </p>
      <p>
        Nós coletamos os seguintes dados pessoais que nossos usuários nos
        fornecem expressamente ao utilizar nosso aplicativo:
      </p>
      <p>
        <strong>
          - Nome Completo,
          <br />- E-mail
          <br />- Número do telefone
        </strong>
      </p>
      <p>A coleta destes dados ocorre nos seguintes momentos:</p>
      <p>
        <strong>ao se cadastrar</strong>
      </p>
      <p>
        Os dados fornecidos por nossos usuários são coletados com as seguintes
        finalidades:
      </p>
      <p>
        <strong>cadastro de usuários</strong>
      </p>

      <br />
      <h4>Dados sensíveis</h4>
      <p>
        <strong>1. Não</strong> serão coletados dados sensíveis de nossos
        usuários, assim entendidos aqueles definidos nos arts. 11 e seguintes da
        Lei de Proteção de Dados Pessoais. Assim, <strong>não</strong> haverá
        coleta de dados sobre origem racial ou étnica, convicção religiosa,
        opinião política, filiação a sindicato ou a organização de caráter
        religioso, filosófico ou político, dado referente à saúde ou à vida
        sexual, dado genético ou biométrico, quando vinculado a uma pessoa
        natural.
      </p>

      <p>
        <strong>
          <strong>
            <strong>
              <br />
            </strong>
          </strong>
        </strong>
        <strong>
          <strong>2. </strong>
        </strong>
        <strong>Compartilhamento de dados pessoais com terceiros</strong>
      </p>
      <p>
        Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso,
        é possível que o façamos para cumprir alguma determinação legal ou
        regulatória, ou, ainda, para cumprir alguma ordem expedida por
        autoridade pública.
      </p>
      <p>
        <strong>
          <strong>
            <strong>
              <br />
            </strong>
          </strong>
        </strong>
        <strong>
          <strong>3. </strong>
        </strong>
        <strong>Por quanto tempo seus dados pessoais serão armazenados</strong>
      </p>
      <p>
        Os dados pessoais coletados pelo aplicativo são armazenados e utilizados
        por período de tempo que corresponda ao necessário para atingir as
        finalidades elencadas neste documento e que considere os direitos de
        seus titulares, os direitos do controlador do aplicativo e as
        disposições legais ou regulatórias aplicáveis.
      </p>
      <p>
        Uma vez expirados os períodos de armazenamento dos dados pessoais, eles
        são removidos de nossas bases de dados ou anonimizados, salvo nos casos
        em que houver a possibilidade ou a necessidade de armazenamento em
        virtude de disposição legal ou regulatória.
      </p>
      <p>
        <strong>
          <strong>
            <strong>
              <br />
            </strong>
          </strong>
        </strong>
        <strong>
          <strong>4. Bases legais para o tratamento de dados pessoais</strong>
        </strong>
        <strong>
          <br />
        </strong>
      </p>
      <p>
        Uma base legal para o tratamento de dados pessoais nada mais é que um
        fundamento jurídico, previsto em lei, que justifica o justifica. Assim,
        cada operação de tratamento de dados pessoais precisa ter uma base legal
        a ela correspondente.
        <strong>
          <strong>
            <br />
          </strong>
        </strong>
      </p>
      <p>
        Nós tratamos os dados pessoais de nossos usuários nas seguintes
        hipóteses:
      </p>
      <p>
        - para o cumprimento de obrigação legal ou regulatória pelo controlador
      </p>
      <p>
        <strong>
          1. Cumprimento de obrigação legal ou regulatória pelo controlador
          <br />
        </strong>
      </p>
      <p>
        Algumas operações de tratamento de dados pessoais, sobretudo o
        armazenamento de dados, serão realizadas para que possamos cumprir
        obrigações previstas em lei ou em outras disposições normativas
        aplicáveis às nossas atividades.
      </p>
      <p>
        <strong>
          <strong>
            <strong>
              <br />
            </strong>
          </strong>
        </strong>
        <strong>
          <strong>5.</strong>
        </strong>
        <strong> Direitos do usuário</strong>
      </p>
      <p>
        O usuário do aplicativo possui os seguintes direitos, conferidos pela
        Lei de Proteção de Dados Pessoais:
      </p>
      <p>
        - confirmação da existência de tratamento;
        <br />- acesso aos dados;
        <br />- correção de dados incompletos, inexatos ou desatualizados;
        <br />- anonimização, bloqueio ou eliminação de dados desnecessários,
        excessivos ou tratados em desconformidade com o disposto na lei;
        <br />- portabilidade dos dados a outro fornecedor de serviço ou
        produto, mediante requisição expressa, de acordo com a regulamentação da
        autoridade nacional, observados os segredos comercial e industrial;
        <br />- eliminação dos dados pessoais tratados com o consentimento do
        titular, exceto nos casos previstos em lei;
        <br />- informação das entidades públicas e privadas com as quais o
        controlador realizou uso compartilhado de dados;
        <br />- informação sobre a possibilidade de não fornecer consentimento e
        sobre as consequências da negativa;
        <br />- revogação do consentimento.
      </p>
      <p>
        É importante destacar que, nos termos da LGPD, não existe um direito de
        eliminação de dados tratados com fundamento em bases legais distintas do
        consentimento, a menos que os dados seja desnecessários, excessivos ou
        tratados em desconformidade com o previsto na lei.
      </p>
      <p>
        <strong>5.1. Como o titular pode exercer seus direitos</strong>
      </p>
      <p>
        Os titulares de dados pessoais tratados por nós poderão exercer seus
        direitos por meio do formulário disponibilizado no seguinte caminho:{' '}
        <strong>https://suporteambiental.com/login</strong>. Alternativamente,
        se desejar, o titular poderá enviar um <strong>e-mail</strong> ou uma
        correspondência ao nosso Encarregado de Proteção de Dados Pessoais. As
        informações necessárias para isso estão na seção &quot;Como entrar em
        contato conosco&quot; desta Política de Privacidade.
      </p>
      <p>
        Os titulares de dados pessoais tratados por nós poderão exercer seus
        direitos a partir do envio de mensagem ao nosso Encarregado de Proteção
        de Dados Pessoais, seja por <strong>e-mail</strong> ou por
        correspondência. As informações necessárias para isso estão na seção
        &quot;Como entrar em contato conosco&quot; desta Política de
        Privacidade.
      </p>
      <p>
        Para garantir que o usuário que pretende exercer seus direitos é, de
        fato, o titular dos dados pessoais objeto da requisição, poderemos
        solicitar documentos ou outras informações que possam auxiliar em sua
        correta identificaçãoo, a fim de resguardar nossos direitos e os
        direitos de terceiros. Isto somente será feito, porém, se for
        absolutamente necessário, e o requerente receberá todas as informações
        relacionadas.
      </p>
      <p>
        <br />
        <strong>6.</strong>
        <strong>
          Medidas de segurança no tratamento de dados pessoais
          <br />
        </strong>
      </p>
      <p>
        Empregamos medidas técnicas e organizativas aptas a proteger os dados
        pessoais de acessos não autorizados e de situações de destruição, perda,
        extravio ou alteração desses dados.
      </p>
      <p>
        As medidas que utilizamos levam em consideração a natureza dos dados, o
        contexto e a finalidade do tratamento, os riscos que uma eventual
        violação geraria para os direitos e liberdades do usuário, e os padrões
        atualmente empregados no mercado por empresas semelhantes à nossa.
      </p>
      <p>
        Entre as medidas de segurança adotadas por nós, destacamos as seguintes:
      </p>
      <p>
        <strong>
          - armazenamento de senhas criptografadas;
          <br />- restrições de acessos;
          <br />- monitoramento de servidores em tempo real.
        </strong>
      </p>
      <p>
        Ainda que adote tudo o que está ao seu alcance para evitar incidentes de
        segurança, é possível que ocorra algum problema motivado exclusivamente
        por um terceiro - como em caso de ataques de <strong>hackers</strong> ou{' '}
        <strong>crackers</strong> ou, ainda, em caso de culpa exclusiva do
        usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados
        a terceiro. Assim, embora sejamos, em geral, responsáveis pelos dados
        pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma
        situação excepcional como essas, sobre as quais não temos nenhum tipo de
        controle.
      </p>
      <p>
        De qualquer forma, caso ocorra qualquer tipo de incidente de segurança
        que possa gerar risco ou dano relevante para qualquer de nossos
        usuários, comunicaremos os afetados e a Autoridade Nacional de Proteção
        de Dados acerca do ocorrido, em conformidade com o disposto na Lei Geral
        de Proteção de Dados.
      </p>
      <p>
        <br />
        <strong>7.</strong>{' '}
        <strong>
          <strong>Reclamação</strong>
        </strong>
      </p>
      <p>Reclamações devem ser enviadas para o email informado abaixo</p>
      <p>
        <strong>
          <br />
          8. Alterações nesta política
          <br />
        </strong>
      </p>
      <p>
        A presente versão desta Política de Privacidade foi atualizada pela
        última vez em: <strong>10/11/2020</strong>.
      </p>
      <p>
        Nos reservamos o direito de modificar, a qualquer momento, as presentes
        normas, especialmente para adaptá-las às eventuais alterações feitas em
        nosso aplicativo, seja pela disponibilização de novas funcionalidades,
        seja pela supressão ou modificação daquelas já existentes.
      </p>
      <p>
        Sempre que houver uma modifição, nossos usuários serão notificados
        acerca da mudança.
      </p>
      <p>
        <strong>
          <br />
          9.
        </strong>
        <strong> Como entrar em contato conosco</strong>
      </p>
      <p>
        Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou
        sobre os dados pessoais que tratamos, entre em contato com nosso
        Encarregado de Proteção de Dados Pessoais, por algum dos canais
        mencionados abaixo:
      </p>
      <p>
        <strong>E-mail</strong>: <strong>suporte@suporteambiental.com</strong>
      </p>
      <p>
        Endereço postal:{' '}
        <strong>
          Rua Rivadavio Lucas Mendes, 81 - Morada do Sol - Montes Claros/MG
        </strong>
      </p>
    </div>
  );
};

export default Privacidade;

import React, {useEffect, useState} from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import {format} from 'date-fns';

import Grid from '@material-ui/core/Grid';

import Loading from '../../../components/Loading';

import PDF from './NovoNordiskUserDailyDetailedPDF';

const DailyDetailed = ({
  opName,
  start,
  end,
  data = null,
  pegadaAmbiental = {},
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [dates, setDates] = useState(null);
  const [pAs, setPegadas] = useState(null);

  useEffect(() => {
    if (result) return;

    const reducedResult = data.reduce((a, c) => {
      const oiDate = format(new Date(c.createdAt), 'yyyy-MM-dd');
      const userNN = c.oiUser.userNN ? c.oiUser.userNN.id : 'Externo';

      const newA = {...a};
      newA[oiDate] = !newA[oiDate]
        ? {
            [userNN]: {
              [c.product_id]: {name: c.oiItem.name, quantity: c.quantity},
            },
          }
        : {
            ...newA[oiDate],
            [userNN]: !newA[oiDate][userNN]
              ? {[c.product_id]: {name: c.oiItem.name, quantity: c.quantity}}
              : {
                  ...newA[oiDate][userNN],
                  [c.product_id]: !newA[oiDate][userNN][c.product_id]
                    ? {name: c.oiItem.name, quantity: c.quantity}
                    : {
                        ...newA[oiDate][userNN][c.product_id],
                        quantity:
                          newA[oiDate][userNN][c.product_id].quantity +
                          c.quantity,
                      },
                },
          };

      return newA;
    }, {});

    const reportPegadas = Object.keys(reducedResult).reduce((acc, rDate) => {
      acc[rDate] = {};

      Object.keys(reducedResult[rDate]).forEach(userId => {
        const userItems = reducedResult[rDate][userId];
        acc[rDate][userId] = pegadaAmbiental.calculate(userItems);
      });

      return acc;
    }, {});

    const orderByDate = Object.keys(reducedResult).sort();

    setResult(reducedResult);
    setPegadas(reportPegadas);
    setDates(orderByDate);
    setIsLoading(false);
  }, [data, pegadaAmbiental, result]);

  return (
    <Grid container direction="column">
      {isLoading ? (
        <Loading />
      ) : dates && pAs && result ? (
        <PDFViewer style={{height: 600}}>
          <PDF
            opName={opName}
            start={start}
            end={end}
            dates={dates}
            pAs={pAs}
            result={result}
          />
        </PDFViewer>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default DailyDetailed;

import React, {useContext, useCallback, useEffect, useState} from 'react';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Loading from '../../components/Loading';
import Header from '../../components/Header';

Numeral.locale('pt-br');

const columns = [
  {
    id: 'created_at',
    label: 'Cadastro',
    minWidth: 80,
    width: 150,
    format: 'date',
  },
  {id: 'name', label: 'Nome', minWidth: 150},
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const Operations = ({history}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();

  // const {category} = match.params;

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [resultInfo, setResultInfo] = useState({
    filter: '',
    page: 1,
    pages: 1,
    total: 0,
    limit: 10,
    orderBy: 'date',
    sortBy: 'DESC',
  });

  const loadData = useCallback(
    async (
      filter = '',
      page = 1,
      limit = 25,
      orderBy = 'date',
      sortBy = 'DESC',
    ) => {
      setIsLoading(true);

      await api
        .get(
          `/core/operations?&p=${page}&l=${limit}&o=${orderBy}&s=${sortBy}&f=${filter}`,
        )
        .then(async res => {
          if (res.data) {
            const {operations, ...info} = res.data;
            setResult(operations);
            setResultInfo(info);
            setIsLoading(false);
          }
        })
        .catch(e => {
          showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
          setIsLoading(false);
        });
    },
    [showAlert],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleRow = id => {
    history.push(`/dashboard/zer0/operacao/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    loadData(
      resultInfo.filter,
      newPage + 1,
      resultInfo.limit,
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  const handleChangeRowsPerPage = ({target}) => {
    loadData(
      resultInfo.filter,
      1,
      parseInt(target.value),
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  return (
    <>
      <Header
        title="Operações"
        btnTxt="Adicionar operação"
        btnIcon={<AddCircleOutlineOutlinedIcon />}
        onBtn={() => history.push('/dashboard/zer0/operacao/incluir')}
      />

      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      ...(column.width ? {width: column.width} : {}),
                      ...(column.minWidth ? {minWidth: column.minWidth} : {}),
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                result.map(row => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => handleRow(row.id)}
                    >
                      {columns.map(column => {
                        // console.log('column', column);
                        const {id, align, format: valFormat} = column;

                        const value = column.id === 'name' ? row[id] : row[id];

                        return (
                          <TableCell key={`${id}.${valFormat}`} align={align}>
                            {valFormat === 'number' ? (
                              Numeral(value).format('0,0')
                            ) : valFormat === 'price' ? (
                              Numeral(value).format('$0,0')
                            ) : valFormat === 'date' ? (
                              format(new Date(value), 'dd/MM/yyyy HH:mm')
                            ) : valFormat === 'bool' ? (
                              <Checkbox checked={!!value} disabled />
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={resultInfo.total}
          rowsPerPage={resultInfo.limit}
          page={resultInfo.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Exibir:"
          // labelDisplayedRows={({from, to, count, page}) => {
          //   console.log('a', a);
          //   console.log('b', b);
          //   console.log('c', c);
          // }}
        />
      </Paper>
    </>
  );
};

export default Operations;

/* global FB */
import React, {useState, useCallback, useEffect} from 'react';
import {Element} from 'react-scroll';

import api from '../../../services/api';
import whichError from '../../../services/whichError';

import social0 from '../../../assets/social-img1.png';
import social1 from '../../../assets/social-img2.png';
import social2 from '../../../assets/social-img3.png';
import social3 from '../../../assets/social-img4.png';
import followUs from '../../../assets/icon-follow-us.png';

import './styles.css';

const appt = '372373280447803|ba887a8b0035a5dcb6e1f683a17a0d60';

const Social = () => {
  const [posts, setPosts] = useState(null);

  const [ig0, setIg0] = useState(null);
  const [ig1, setIg1] = useState(null);
  const [ig2, setIg2] = useState(null);
  const [ig3, setIg3] = useState(null);

  const loadData = useCallback(async () => {
    await api
      .get(`/instagram`)
      .then(async res => {
        if (res.data) {
          setPosts(res.data);

          setTimeout(async () => {
            res.data.map(async (p, i) => {
              await FB.api(
                '/instagram_oembed',
                {
                  access_token: appt,
                  url: `https://www.instagram.com/p/${p}`,
                  maxwidth: 320,
                  hidecaption: true,
                  // omitscript: true,
                },
                response => {
                  // if (response.error)
                  // console.log('errorMsg:>> ', response.error.message);

                  // console.log('response :>> ', response);

                  if (i === 0) setIg0(response);
                  if (i === 1) setIg1(response);
                  if (i === 2) setIg2(response);
                  if (i === 3) setIg3(response);
                },
              );
            });
          }, 1000);
        }
      })
      .catch(err => whichError(err));
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Element name="social" className="social" id="teste">
      <h1 className="section-title">Acompanhe nossas redes sociais</h1>

      <div className="social-imgs-container">
        <div className="social-imgs">
          {ig0 ? (
            // <div dangerouslySetInnerHTML={{__html: ig0.html}} />
            <a href={`https://www.instagram.com/p/${posts[0]}`}>
              <img src={ig0.thumbnail_url} alt="Instagram" />
            </a>
          ) : (
            <img src={social0} alt="Instagram" />
          )}
        </div>

        <div className="social-imgs">
          {ig1 ? (
            // <div dangerouslySetInnerHTML={{__html: ig1.html}} />
            <a href={`https://www.instagram.com/p/${posts[1]}`}>
              <img src={ig1.thumbnail_url} alt="Instagram" />
            </a>
          ) : (
            <img src={social1} alt="Instagram" />
          )}
        </div>

        <div className="social-imgs">
          {ig2 ? (
            // <div dangerouslySetInnerHTML={{__html: ig2.html}} />
            <a href={`https://www.instagram.com/p/${posts[2]}`}>
              <img src={ig2.thumbnail_url} alt="Instagram" />
            </a>
          ) : (
            <img src={social2} alt="Instagram" />
          )}
        </div>

        <div className="social-imgs">
          {ig3 ? (
            // <div dangerouslySetInnerHTML={{__html: ig3.html}} />
            <a href={`https://www.instagram.com/p/${posts[3]}`}>
              <img src={ig3.thumbnail_url} alt="Instagram" />
            </a>
          ) : (
            <img src={social3} alt="Instagram" />
          )}
        </div>
      </div>

      <div className="social-follow-us">
        <img src={followUs} alt="Instagram" />
        <br />
        Siga-nos!
      </div>
    </Element>
  );
};

export default Social;

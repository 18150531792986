// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/background-institucional.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/background-institucional-footer.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".politicas {\n  color: #033;\n}\n\n.politicas-header {\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: cover;\n  height: 40vh;\n  display: flex;\n  align-items: flex-end;\n  padding-left: 5vw;\n  padding-bottom: 5vh;\n  font-size: 15px;\n  color: #2d853b;\n}\n\n.politicas-header img {\n  position: absolute;\n  width: 30vh;\n  top: 13vh;\n  right: 10vw;\n}\n\n.institucional-index p {\n  font-size: 18px;\n  margin: 10px 0;\n  color: #777;\n}\n\n.institucional-index p a {\n  color: #777;\n  text-decoration: none;\n}\n\n.politicas h2 {\n  font-size: 24px;\n  padding: 20px;\n}\n\n.institucional-text {\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-size: cover;\n  background-position: bottom;\n  font-size: 18px;\n  padding: 40px;\n  min-height: 60vh;\n  padding-bottom: 15vw;\n}\n\n.institucional-text h4 {\n  margin-bottom: 25px;\n}\n\n.institucional-text p {\n  margin-bottom: 15px;\n}\n\n.institucional-text ul {\n  font-size: 15px;\n  margin-bottom: 15px;\n}\n", ""]);
// Exports
module.exports = exports;

import React, {useContext, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import LibraryAddIcon from '@material-ui/icons/LibraryAdd';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Select from '../../../../components/Select';

import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Search from '../../components/Search';

Numeral.locale('pt-br');

const columns = [
  {
    id: 'created_at',
    label: 'Cadastro',
    minWidth: 80,
    width: 150,
    format: 'date',
  },
  {id: 'name', label: 'Nome', minWidth: 150},
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    // maxHeight: 440,
  },
});

const categories = {
  coleta: {id: 10, subTitle: 'Coleta'},
  corporativo: {id: 12, subTitle: 'Corporativo'},
  pontos: {id: 9, subTitle: 'Troca de Pontos'},
};

const userOptions = Object.keys(categories).map(ck => ({
  label: categories[ck].subTitle,
  value: ck,
}));

const Partnerships = ({history, match}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();

  const userId = useSelector(state => state.Login.userId);
  const userStatus = useSelector(state => state.Login.status);

  const {category: selectedCategory} = match.params;
  const category = categories[selectedCategory] ? selectedCategory : null;

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [resultInfo, setResultInfo] = useState({
    filter: '',
    page: 1,
    pages: 1,
    total: 0,
    limit: 10,
    orderBy: 'date',
    sortBy: 'DESC',
  });

  const loadData = useCallback(
    async (
      filter = '',
      page = 1,
      limit = 25,
      orderBy = 'date',
      sortBy = 'DESC',
    ) => {
      setIsLoading(true);

      await api
        .get(
          `/core/partnerships?&p=${page}&l=${limit}&o=${orderBy}&s=${sortBy}&f=${filter}${
            userStatus === 3 ? `&partner=${userId}` : ''
          }${
            category
              ? `&c=${categories[category].id ? categories[category].id : ''}`
              : ''
          }`,
        )
        .then(async res => {
          if (res.data) {
            const {partnerships, ...info} = res.data;
            setResult(partnerships);
            setResultInfo(info);
            setIsLoading(false);
          }
        })
        .catch(e => {
          showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
          setIsLoading(false);
        });
    },
    [category, showAlert, userId, userStatus],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onPartershipChange = userType => {
    history.push(`/dashboard/zer0/parcerias/${userType}`);
  };

  const handleRow = id => {
    history.push(
      `/dashboard/${userStatus === 2 ? 'zer0/parceiro' : 'parceria'}/${id}`,
    );
  };

  const handleChangePage = (event, newPage) => {
    loadData(
      resultInfo.filter,
      newPage + 1,
      resultInfo.limit,
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  const handleChangeRowsPerPage = ({target}) => {
    loadData(
      resultInfo.filter,
      1,
      parseInt(target.value),
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  return (
    <>
      <Header
        title="Parcerias"
        // subTitle={category ? categories[category].subTitle : null}
        btnTxt={userStatus === 2 ? 'Adicionar parceria' : null}
        btnIcon={<LibraryAddIcon />}
        onBtn={() => history.push('/dashboard/zer0/parceiro/incluir')}
      />

      {userStatus === 2 && (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-end"
          className={classes.filters}
        >
          <Select
            label="Tipo de Usuário"
            options={[{value: ' ', label: 'Todos'}, ...(userOptions || [])]}
            selected={category || null}
            onChangeValue={val => onPartershipChange(val)}
            className={classes.filter}
            empty={false}
          />

          <Search onChange={searchVal => loadData(searchVal)} />
        </Grid>
      )}

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      ...(column.width ? {width: column.width} : {}),
                      ...(column.minWidth ? {minWidth: column.minWidth} : {}),
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                result.map(row => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => handleRow(row.id)}
                    >
                      {columns.map(column => {
                        // console.log('column', column);
                        const {id, align, format: valFormat} = column;

                        const value = column.id === 'name' ? row[id] : row[id];

                        return (
                          <TableCell key={`${id}.${valFormat}`} align={align}>
                            {valFormat === 'number' ? (
                              Numeral(value).format('0,0')
                            ) : valFormat === 'price' ? (
                              Numeral(value).format('$0,0')
                            ) : valFormat === 'date' ? (
                              format(new Date(value), 'dd/MM/yyyy HH:mm')
                            ) : valFormat === 'bool' ? (
                              <Checkbox checked={!!value} disabled />
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={resultInfo.total}
          rowsPerPage={resultInfo.limit}
          page={resultInfo.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Exibir:"
          // labelDisplayedRows={({from, to, count, page}) => {
          //   console.log('a', a);
          //   console.log('b', b);
          //   console.log('c', c);
          // }}
        />
      </Paper>
    </>
  );
};

export default Partnerships;

import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Input from '../../../../components/Form/Input';
import ImgPicker from '../../../../components/ImgPicker';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },

  optionsBtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const Machine = ({history, match}) => {
  const showAlert = useContext(AlertContext);

  const {params} = match;
  const {id} = params;
  const formRef = useRef(null);

  const [img, setImg] = useState(null);

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    await api
      .get(`/core/machines/${id}`)
      .then(async res => {
        setResult(res.data);
        setIsLoading(false);
      })
      .catch(async e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [id, showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = async (unformData, {reset}) => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      name: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor informar o id da publicação 1'),
      description: Yup.string()
        // .transform((cv, ov) => (ov === '' ? null : cv))
        // .nullable(true)
        .trim(),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);
    // console.log('unformData :>> ', unformData);
    // return;

    // unformData.imgAction =
    //   userHasImg && imgAction && img === null
    //     ? 'remove'
    //     : userHasImg && !imgAction && img === null
    //     ? 'keep'
    //     : 'update';

    // eslint-disable-next-line no-undef
    const formData = new FormData();

    Object.entries(data).forEach(field =>
      formData.append(
        field[0],
        field[1] === undefined || field[1] === null ? '' : field[1],
      ),
    );

    if (img) formData.append('img', img);

    const save = await api
      .put(`/core/machines/${id}`, formData)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (save) {
      reset();
      history.goBack();
    }
  };

  const iD = {
    name: result ? result.name : '',
    description: result ? result.description : '',
  };

  return (
    <>
      <Header title="Detalhes da Máquina" />

      <Paper className={classes.root}>
        {isLoading ? (
          <Loading />
        ) : !result ? (
          // <p>REDIRECIONAR</p>
          <></>
        ) : (
          <Form ref={formRef} onSubmit={handleSubmit} initialData={iD}>
            {/* <ImgPicker height={153} width={272} onChange={setImg} /> */}
            <ImgPicker
              height={150}
              width={150}
              onChange={setImg}
              image={result.img}
            />

            <Input
              label="Nome do máquina *"
              name="name"
              className={classes.textInput}
              variant="outlined"
              fullWidth
            />

            <br />
            <br />

            <Input
              label="Descrição"
              name="description"
              multiline
              variant="outlined"
              fullWidth
              className={classes.description}
              // inputProp
            />

            <br />
            <br />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              className={classes.button}
              startIcon={<SaveOutlinedIcon />}
            >
              Salvar
            </Button>
          </Form>
        )}
      </Paper>
    </>
  );
};

export default Machine;

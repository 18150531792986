import React, {useContext, useRef, useState} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {makeStyles} from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Input from '../../../../components/Form/Input';

const useStyles = makeStyles(theme => ({
  name: {
    width: '100%',
  },
  point: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const AddLocation = ({
  visible = false,
  onSave = () => null,
  onClose = () => null,
}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();
  const formRef = useRef(null);

  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async (unformData, {reset}) => {
    // console.log(unformData);

    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      name: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor informar o nome do local'),
      latitude: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .required('Favor informar a latitude'),
      longitude: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .required('Favor informar a longitude'),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);

    setIsSaving(true);

    const save = await api
      .post(`/core/locations`, data)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setIsSaving(false);

    if (save) {
      reset();
      onSave();
      onClose();
    }
  };

  return (
    <Dialog
      open={!!visible}
      onClose={() => (isSaving ? null : onClose())}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Adicionar Local de Operação
      </DialogTitle>

      <DialogContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            label="Nome do local"
            name="name"
            className={classes.name}
            variant="outlined"
            disabled={isSaving}
          />

          <br />
          <br />

          <Input
            label="Latitude"
            name="latitude"
            className={classes.point}
            variant="outlined"
            inputProps={{
              step: 0.000001,
              type: 'number',
            }}
            disabled={isSaving}
          />

          <Input
            label="Longitude"
            name="longitude"
            className={classes.point}
            variant="outlined"
            inputProps={{
              step: 0.000001,
              type: 'number',
            }}
            disabled={isSaving}
          />
        </Form>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Cancelar
        </Button>

        <Button
          onClick={() => formRef.current.submitForm()}
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLocation;

import React from 'react';
import {Font, StyleSheet, View, Text} from '@react-pdf/renderer';

import ArialNarrow from '../../../../../../assets/fonts/Arial-Narrow.ttf';
import RubikBold from '../../../../../../assets/fonts/Rubik-Bold.ttf';

Font.register({family: 'Arial Narrow', fonts: [{src: ArialNarrow}]});
Font.register({family: 'Rubik', fonts: [{src: RubikBold, fontWeight: 600}]});

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Arial Narrow',
    textAlign: 'center',
    fontSize: 22,
  },
  subTitle: {
    fontFamily: 'Rubik',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
  },
});

const Title = ({name = ''}) => (
  <View>
    <Text style={styles.title}>RELATÓRIO RECYCLABLE RETURN MACHINE ZER0</Text>
    <Text style={styles.subTitle}>{name}</Text>
  </View>
);

export default Title;

import React, {useRef, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {SHA512} from 'crypto-js';
import {deviceDetect} from 'react-device-detect';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import Icon from '@mdi/react';
import {mdiLoginVariant, mdiLogoutVariant} from '@mdi/js';

import {Spinner} from 'react-activity';
import 'react-activity/dist/react-activity.css';

import * as loginActions from '../../../store/actions/login';

import api from '../../../services/api';
import whichError from '../../../services/whichError';

import {SocketContext} from '../../../App';

import Input from '../../../components/Input';
import Checkbox from '../../../components/Checkbox';

export default function Login({
  showRecover = false,
  showRegister = false,
  showBtnIcon = true,
  showBtnText = false,
  showKeepConnected = false,
  className = '',
}) {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const history = useHistory();
  const socket = useContext(SocketContext);

  const appToken = localStorage.getItem('apptoken');

  const [userMsg, setUserMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (data, {reset}, event) => {
    event.preventDefault();

    setIsLoading(true);
    setUserMsg('');
    formRef.current.setErrors({});

    const device = JSON.stringify(deviceDetect());

    const schema = Yup.object().shape({
      email: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        // .email('Favor inserir um e-mail válido')
        .required('Favor informar um e-mail válido!'),
      password: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'Mínimo 1 caractere!')
        .required('Favor informar a senha'),
      keepConnected: Yup.boolean(),
    });

    try {
      await schema.validate(data, {abortEarly: false});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      setIsLoading(false);
      return;
    }

    const formData = schema.cast(data);

    const loginData = {
      email: formData.email,
      password: SHA512(formData.password).toString(),
      keepConnected: formData.keepConnected,
    };

    reset();
    await api
      .post('/signin', {...loginData}, {headers: {device}})
      .then(res => {
        if (!res) {
          setUserMsg('Erro ao tentar fazer login.');
          setIsLoading(false);
          return;
        }

        socket.disconnect();

        const {userId, token, sToken} = res.data;
        const {changePassword, ...rest} = res.data;

        api.defaults.headers.common.user = userId;
        api.defaults.headers.common.apptoken = token;
        api.defaults.headers.common.stoken = sToken;

        if (changePassword)
          localStorage.setItem('changePassword', changePassword);

        localStorage.setItem('user', userId);
        localStorage.setItem('apptoken', token);
        localStorage.setItem('stoken', sToken);

        dispatch(loginActions.addLoginUser(rest));

        reset();

        if (changePassword) history.push('/password');
      })
      .catch(err => setUserMsg(whichError(err).errorMsg));

    setIsLoading(false);
  };

  const handleRecover = () => {
    history.push('/recover');
  };

  const handleRegister = () => {
    history.push('/signup');
  };

  const handleLogout = async event => {
    event.preventDefault();

    setIsLoading(true);

    api.get('/logout').catch(err => whichError(err));

    socket.disconnect();
    localStorage.removeItem('user');
    localStorage.removeItem('apptoken');
    localStorage.removeItem('stoken');

    dispatch(loginActions.resetLogin());

    setIsLoading(false);
  };

  return appToken ? (
    <div className={className}>
      <button type="button" onClick={handleLogout} disabled={isLoading}>
        {isLoading && <Spinner color="#0f0" size={10} speed={1} animating />}

        {showBtnIcon && !isLoading && (
          <Icon path={mdiLogoutVariant} size={0.8} />
        )}

        {showBtnText && (
          <span>{showBtnText === true ? 'Desconectar' : showBtnText}</span>
        )}
      </button>
    </div>
  ) : (
    <Form onSubmit={handleSubmit} ref={formRef} className="login-page">
      <div className={className}>
        <Input name="email" placeholder="E-mail" />
        <Input name="password" placeholder="Senha" type="password" />
        {/* <Input name="email" value="u1" /> */}
        {/* <Input name="password" type="password" value="123" /> */}

        {showKeepConnected && (
          <Checkbox name="keepConnected" label="Manter conectado" />
        )}

        <div className="user-msg-container">{userMsg}</div>

        <button type="submit" disabled={isLoading}>
          {isLoading && <Spinner color="#0f0" size={10} speed={1} animating />}

          {showBtnIcon && !isLoading && (
            <Icon path={mdiLoginVariant} size={0.8} />
          )}

          {showBtnText && (
            <span>{showBtnText === true ? 'Conectar' : showBtnText}</span>
          )}
        </button>

        {showRecover && (
          <button type="button" onClick={handleRecover} className="recover">
            Recuperar a senha
          </button>
        )}

        {showRegister && (
          <button type="button" onClick={handleRegister} className="register">
            Registrar
          </button>
        )}
      </div>
    </Form>
  );
}

const Numeral = require('numeral');
require('numeral/locales/pt-br');

Numeral.locale('pt-br');

const pegadaInitial = {water: 0, energy: 0, co2: 0, weigth: 0};

class Pegada {
  constructor(items) {
    this.items = items;
  }

  // calculateItem = (itemId, quantity) =>
  //   new Promise(resolve => {
  //     const item = this.items[itemId] || {...pegadaInitial};
  //     const {water, energy, co2, weight} = item;

  //     return resolve({
  //       weight: Numeral(weight).multiply(quantity).value(),
  //       water: Numeral(water).multiply(quantity).value(),
  //       energy: Numeral(energy).multiply(quantity).value(),
  //       co2: Numeral(co2).multiply(quantity).value(),
  //     });
  //   });

  calculateItem = (itemId, quantity) => {
    const item = this.items[itemId] || {...pegadaInitial};
    const {water, energy, co2, weight} = item;

    return {
      weight: Numeral(weight).multiply(quantity).value(),
      water: Numeral(water).multiply(quantity).value(),
      energy: Numeral(energy).multiply(quantity).value(),
      co2: Numeral(co2).multiply(quantity).value(),
    };
  };

  calculate = items => {
    const pegada = {...pegadaInitial};

    const pegadaAmbiental = Object.keys(items).reduce((acc, cur) => {
      const pegadaItem = this.calculateItem(cur, items[cur].quantity);

      return {
        weight: Numeral(acc.weight).add(pegadaItem.weight).value(),
        water: Numeral(acc.water).add(pegadaItem.water).value(),
        energy: Numeral(acc.energy).add(pegadaItem.energy).value(),
        co2: Numeral(acc.co2).add(pegadaItem.co2).value(),
      };
    }, pegada);

    return pegadaAmbiental;
  };
}

export default Pegada;

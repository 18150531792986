import React from 'react';
import {Font, StyleSheet, View, Text, Image} from '@react-pdf/renderer';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import Rubik from '../../../../../../assets/fonts/Rubik.ttf';
import RubikBold from '../../../../../../assets/fonts/Rubik-Bold.ttf';

import logo from '../../../../../../assets/logo-suporte-ambiental-vertical.png';

Numeral.locale('pt-br');

Font.register({
  family: 'Rubik',
  fonts: [{src: Rubik}, {src: RubikBold, fontWeight: 600}],
});

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  logo: {
    width: 50,
  },
  saContainer: {
    alignSelf: 'center',
    alignItems: 'center',
  },
  suporteAmbiental: {
    fontSize: 12,
    fontWeight: '900',
  },
  cnpj: {
    fontSize: 10,
  },
  pages: {
    width: 50,
  },
  // pages: {
  //   width: 50,
  //   fontSize: 8,
  //   textAlign: 'right',
  // },
});

// {pages ? (
//   <Text
//     render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
//     style={styles.pages}
//     fixed
//   />
// ) : (
//   )}

const Header = () => (
  <View style={styles.header}>
    <Image src={logo} style={styles.logo} />

    <View style={styles.saContainer}>
      <Text style={styles.suporteAmbiental}>
        Suporte Ambiental - Soluções em Engenharia Ambiental e Sanitária
      </Text>

      <Text style={styles.cnpj}>CNPJ: 13.825.109/0001-00</Text>
    </View>

    <Text style={styles.pages}>&nbsp;</Text>
  </View>
);

export default Header;

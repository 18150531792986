import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {makeStyles} from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Select from '../../../../components/Form/Select';
import Input from '../../../../components/Form/Input';

import Loading from '../Loading';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  input: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const AddColeta = ({eventId, userId, onClose = () => null}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();
  const formRef = useRef(null);

  const toSelectEvent = eventId === userId;

  const [isLoading, setIsLoading] = useState(true);
  const [operations, setOperations] = useState(null);
  const [items, setItems] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    if (toSelectEvent) {
      await api
        .get(`/core/operations`)
        .then(async res => {
          const operationsOpts = res.data.operations.map(item => ({
            value: item.id,
            label: item.name,
          }));
          setOperations(operationsOpts);
        })
        .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));
    }

    await api
      .get(`/core/items`)
      .then(async res => {
        const itemsOpts = res.data.products.map(item => ({
          value: item.id,
          label: item.name,
        }));
        setItems(itemsOpts);
        // setIsLoading(false);
      })
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setIsLoading(false);
  }, [showAlert, toSelectEvent]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = async (unformData, {reset}) => {
    formRef.current.setErrors({});

    const withEvent = {
      operation: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(36, 'Informe um valor válido')
        .required('Informe o item'),
    };
    const witoutEvent = {
      item: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(36, 'Informe um valor válido')
        .required('Informe o item'),
      quantity: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido')
        .required('Informe o peso'),
    };

    const schema = Yup.object().shape(
      toSelectEvent ? {...withEvent, ...witoutEvent} : witoutEvent,
    );

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);
    const oi = {userId, items: [{item: data.item, quantity: data.quantity}]};

    setIsSaving(true);

    const save = await api
      .post(
        `/core/operations/${toSelectEvent ? data.operation : eventId}/items`,
        oi,
      )
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    setIsSaving(false);

    if (save) {
      reset();
      onClose();
    }
  };

  return (
    <Dialog
      open={!!userId}
      onClose={() => (isSaving ? null : onClose())}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Adicionar Coleta</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
          To subscribe to this website, please enter your email address here. We
          will send updates occasionally.
        </DialogContentText> */}

        {isLoading ? (
          <Loading />
        ) : (
          <Form ref={formRef} onSubmit={handleSubmit}>
            {toSelectEvent && (
              <Select
                label="Operação *"
                name="operation"
                options={operations}
                variant="outlined"
                disabled={isSaving}
                fullWidth
                className={classes.input}
              />
            )}

            <Select
              label="Item *"
              name="item"
              options={items}
              variant="outlined"
              disabled={isSaving}
              fullWidth
              className={classes.input}
            />

            <Input
              label="Quantidade *"
              name="quantity"
              className={classes.input}
              fullWidth
              disabled={isSaving}
              variant="outlined"
              inputProps={{
                step: 1,
                type: 'number',
              }}
            />
          </Form>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Cancelar
        </Button>

        <Button
          onClick={() => formRef.current.submitForm()}
          variant="contained"
          color="primary"
          disabled={!items || isSaving}
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddColeta;

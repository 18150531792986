import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import {AlertContext} from '../../../../App';

import {menuList} from '../../../../tools/dataManager';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },
}));

const categoryOptions = [
  {value: 3, label: 'Público'},
  {value: 2, label: 'Corporativo'},
  {value: 4, label: 'Promocional'},
];

const OperationAdd = ({history}) => {
  const showAlert = useContext(AlertContext);
  const formRef = useRef(null);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState(null);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    const machines = api.get('/core/machines');
    const locations = api.get('/core/locations');

    const error = [];
    const get = await Promise.all(
      [machines, locations].map((p, i) =>
        p.catch(e => {
          error[i] = whichError(e).errorMsg;
        }),
      ),
    );

    const errors = [...new Set(error)];
    if (errors.length > 1) {
      showAlert({
        title: 'Atenção',
        msg: `Ocorreram os seguintes erros:\n\n${errors.join('\n')}`,
      });
    } else if (errors.length >= 1) {
      showAlert({
        title: 'Atenção',
        msg: `Ocorreu o seguinte erro:\n\n${errors[0]}`,
      });
    }

    setParams(
      get[0] && get[1]
        ? {
            machines: menuList(get[0].data.machines, 'name', 'id'),
            locations: menuList(get[1].data.locations, 'name', 'id'),
          }
        : null,
    );

    setIsLoading(false);
  }, [showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = async (unformData, {reset}) => {
    // console.log('unformData :>> ', unformData);
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      category: Yup.number()
        .transform((cv, or) =>
          or === '' || or === '0' ? null : or === '$null$' ? 0 : cv,
        )
        .nullable(true)
        .typeError('Favor informar um valor válido')
        .positive('Favor informar um valor válido')
        .required('Favor informar a categoria'),
      machine: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .typeError('Favor informar um valor válido')
        .min(36, 'Favor informar um valor válido')
        .max(36, 'Favor informar um valor válido')
        .required('Favor informar a máquina'),
      location: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .typeError('Favor informar um valor válido')
        .min(36, 'Favor informar um valor válido')
        .max(36, 'Favor informar um valor válido')
        .required('Favor informar a localização'),
      name: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor o nome da operação'),
      description: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim(),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);
    // console.log('data :>> ', data);
    // return;

    const save = await api
      .post(`/core/operations`, data)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (save) {
      reset();
      history.goBack();
    }
  };

  const iD = {};

  return (
    <>
      <Header title="Adicionar Operação" />

      <Paper className={classes.root}>
        {isLoading ? (
          <Loading />
        ) : !params ? (
          // <p>REDIRECIONAR</p>
          <></>
        ) : (
          <Form ref={formRef} onSubmit={handleSubmit} initialData={iD}>
            <Select
              label="Categoria *"
              name="category"
              options={categoryOptions}
              variant="outlined"
              fullWidth
              className={classes.textInput}
            />

            <br />
            <br />

            <Select
              label="Máquina *"
              name="machine"
              // options={[
              //   {
              //     value: '00c7e1b8-9f82-4a35-b5af-e51473033906',
              //     label: '- SEM MÁQUINA -',
              //   },
              //   ...params.machines,
              // ]}
              options={params.machines}
              variant="outlined"
              fullWidth
              className={classes.textInput}
            />

            <br />
            <br />

            <Select
              label="Localização *"
              name="location"
              options={params.locations}
              variant="outlined"
              fullWidth
              className={classes.textInput}
            />

            <br />
            <br />

            <Input
              label="Nome da operação *"
              name="name"
              className={classes.textInput}
              variant="outlined"
              fullWidth
            />

            <br />
            <br />

            <Input
              label="Descrição"
              name="description"
              multiline
              variant="outlined"
              fullWidth
              className={classes.description}
              // inputProp
            />

            <br />
            <br />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              className={classes.button}
              startIcon={<AddCircleOutlineOutlinedIcon />}
            >
              Adicionar
            </Button>
          </Form>
        )}
      </Paper>
    </>
  );
};

export default OperationAdd;

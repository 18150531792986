import React, {useContext, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {SocketContext} from '../../../App';

import * as loginActions from '../../../store/actions/login';

import api from '../../../services/api';
import whichError from '../../../services/whichError';

const Logout = ({match}) => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();

  const {redirect} = match.params;

  const [disconnected, setDisconnected] = useState(false);

  useEffect(() => {
    const logout = async () => {
      await api.get('/logout').catch(err => whichError(err));
      socket.disconnect();
      localStorage.removeItem('user');
      localStorage.removeItem('apptoken');
      localStorage.removeItem('stoken');
      dispatch(loginActions.resetLogin());
      setDisconnected(true);
    };

    logout();
  }, [dispatch, socket]);

  return disconnected ? <Redirect to={{pathname: `/${redirect}`}} /> : <></>;
};

export default Logout;

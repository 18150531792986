import React, {useContext, useCallback, useState, useEffect} from 'react';
import ReactPlayer from 'react-player';
import Carousel from 'nuka-carousel';
// import MetaTags from 'react-meta-tags';

import {SocketContext} from '../../App';

import URL from '../../configs/baseURL';

import api from '../../services/api';
import whichError from '../../services/whichError';

import transition from '../../assets/mkt-transition.png';

import './styles.css';

const PegadaMkt = () => {
  const io = useContext(SocketContext);

  const [slideIndex, setSlideIndex] = useState(0);
  const [mkt2, setMkt2] = useState(null);

  useEffect(() => {
    io.on('RefreshMkt', () => {
      window.location.reload();
    });
  }, [io]);

  const loadData = useCallback(async () => {
    await api
      .get(`/mkt`)
      .then(async ({data}) => {
        setMkt2([
          {transition, timer: 5},
          ...(data || []),
          {transition, timer: 2},
        ]);
      })
      .catch(err => whichError(err));
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!mkt2 || mkt2.length === 0) return;

    if (!mkt2[slideIndex]) {
      // setSlideIndex(0); // trava depois de um tempo
      window.location.reload();
      return;
    }

    if (mkt2[slideIndex].img || mkt2[slideIndex].transition) {
      setTimeout(() => {
        if (slideIndex === mkt2.length - 1) {
          // setSlideIndex(0); // trava depois de um tempo
          window.location.reload();
        } else {
          setSlideIndex(slideIndex + 1);
        }
      }, mkt2[slideIndex].timer * 1000);
    }
  }, [mkt2, slideIndex]);

  const handleOnEnded = () => {
    if (slideIndex === mkt2.length - 1) {
      // setSlideIndex(0); // trava depois de um tempo
      window.location.reload();
    } else {
      setSlideIndex(slideIndex + 1);
    }
  };

  return (
    <>
      {/* <MetaTags>
        <meta httpEquiv="refresh" content="300" />
      </MetaTags> */}

      <div className="mkt-container">
        <Carousel
          slideIndex={slideIndex}
          afterSlide={i => setSlideIndex(i)}
          disableAnimation
          heightMode="max"
          pauseOnHover={false}
          swiping={false}
          withoutControls
        >
          {mkt2 ? (
            mkt2.map((m, i) => (
              <div className="slide" key={`${m.yt || m.img}`}>
                <div className="slide-container">
                  <div className="mkt-img-container">
                    {m.yt ? (
                      <ReactPlayer
                        url={`https://www.youtube.com/watch?v=${m.yt}`}
                        width="1920px"
                        height="1080px"
                        muted
                        playing={i === slideIndex}
                        onEnded={handleOnEnded}
                        config={{
                          playerVars: {
                            enablejsapi: 1,
                            autoplay: 0,
                            color: 'green',
                            // origin: 'http://localhost:3000',
                            origin: 'https://suporteambiental.com',
                          },
                        }}
                      />
                    ) : (
                      <img
                        src={m.transition || `${URL.addr}/imgs/mkt/${m.img}`}
                        className="mkt-img"
                        alt="Suporte Ambiental"
                      />
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </Carousel>
      </div>
    </>
  );
};

export default PegadaMkt;

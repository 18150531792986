import React from 'react';
import {Element} from 'react-scroll';
import Carousel from 'nuka-carousel';

import c01 from '../../../assets/clientes-logo-01.png';
import c02 from '../../../assets/clientes-logo-02.png';
import c03 from '../../../assets/clientes-logo-03.png';
import c04 from '../../../assets/clientes-logo-04.png';
import c05 from '../../../assets/clientes-logo-05.png';
import c06 from '../../../assets/clientes-logo-06.png';
import c07 from '../../../assets/clientes-logo-07.png';
import c08 from '../../../assets/clientes-logo-08.png';
import c09 from '../../../assets/clientes-logo-09.png';
import c10 from '../../../assets/clientes-logo-10.png';
import c11 from '../../../assets/clientes-logo-11.png';

import './styles.css';

const clientes = [c01, c02, c03, c04, c05, c06, c07, c08, c09, c10, c11];

const Clientes = () => {
  return (
    <Element name="clientes" className="clientes">
      <h1 className="section-title">Clientes</h1>

      <Carousel
        wrapAround
        autoplay
        autoplayInterval={1000}
        heightMode="max"
        cellAlign="center"
        slidesToShow={3}
        slidesToScroll={1}
        slideWidth="250px"
        renderBottomCenterControls={null}
        defaultControlsConfig={{
          prevButtonText: '<',
          nextButtonText: '>',
        }}
      >
        {clientes.map(c => (
          <div className="slide" key={`${c}`}>
            <div className="clientes-slide-container">
              <div className="clientes-imgs">
                <img src={c} alt="Cliente" />
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </Element>
  );
};

export default Clientes;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/background-latinhas.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".latinhas {\n  background: #fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-position: left bottom;\n  background-size: cover;\n  color: #13a15a;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.latinhas-content {\n  margin: 4vw 4vw 20vw 0vw;\n  right: 10px;\n  text-align: right;\n}\n\n.latinhas-reciclagem {\n  width: 20vw;\n  margin-right: 7vw;\n}\n\n.latinhas-content h1 {\n  font-size: 6vw;\n}\n\n.latinhas-content p {\n  font-size: 2.5vw;\n}\n", ""]);
// Exports
module.exports = exports;

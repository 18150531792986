import React, {forwardRef, useState, useImperativeHandle} from 'react';

// import Slide from '@material-ui/core/Slide';
// import Grow from '@material-ui/core/Grow';
import Zoom from '@material-ui/core/Zoom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Transition = forwardRef((props, ref) => {
  // return <Slide direction="down" ref={ref} {...props} />;
  // return <Grow ref={ref} {...props} />;
  return <Zoom ref={ref} {...props} />;
});

const AlertDialog = forwardRef((_props, ref) => {
  const [params, setParams] = useState(false);

  const handleCancel = onCancel => {
    if (onCancel) onCancel();
    setParams(false);
  };

  const handleOk = onOk => {
    if (onOk) onOk();
    setParams(false);
  };

  const set = alertParams => setParams(alertParams);
  useImperativeHandle(ref, () => ({set}));

  return params ? (
    <Dialog
      open={!!params}
      onClose={() => setParams(false)}
      TransitionComponent={Transition}
    >
      {params.title && <DialogTitle>{`${params.title}`}</DialogTitle>}

      {params.msg && (
        <DialogContent>
          <DialogContentText>{`${params.msg}`}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        {params.onOk && (
          <Button
            onClick={() => handleCancel(params.onCancel || false)}
            color="primary"
          >
            Cancelar
          </Button>
        )}

        <Button
          onClick={() => handleOk(params.onOk || false)}
          color="primary"
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
});

export default AlertDialog;

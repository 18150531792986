import React from 'react';
import {Element} from 'react-scroll';
// import { GoogleMap, LoadScript } from "@react-google-maps/api";

import './styles.css';

const Mapa = () => {
  return (
    <Element name="mapa" className="mapa">
      {/* <LoadScript googleMapsApiKey="AIzaSyBYjsSMVM1SJnh24WZdQ-5I2udB_lJ6p5M">
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "400px",
        }}
        center={{
          lat: -16.7413594,
          lng: -43.8832214,
        }}
        zoom={15}
      >
        <></>
      </GoogleMap>
    </LoadScript> */}
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2999.8032810156546!2d-43.878827606336046!3d-16.73251173000578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xab53434a845413%3A0xb0ac9a8ce484d6d4!2sR.%20Raul%20Corr%C3%AAa%2C%2058%20-%20Candida%2C%20Montes%20Claros%20-%20MG%2C%2039401-029!5e0!3m2!1spt-BR!2sbr!4v1672346637401!5m2!1spt-BR!2sbr"
        width="100%"
        height="400"
        style={{border: 0}}
      />

    
  

    </Element>
  );
};

export default Mapa;

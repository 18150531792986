import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';

import './styles.css';

import LoginForm from './components/Form';
import VerifySession from './components/Session';

const Login = ({history, match, location}) => {
  const {redirect} = match.params;

  const appToken = localStorage.getItem('apptoken');

  const [gotSession, setGotSession] = useState(false);

  return (
    <div className="s-a login-background">
      <div className="login-space">
        <div className="login-container">
          <div className="login-content">
            <h1>Login</h1>

            {appToken ? (
              <>
                <VerifySession gotSession={() => setGotSession(true)} />

                {gotSession && redirect && (
                  <Redirect
                    to={{
                      pathname: `/${redirect}`,
                      state: {from: location},
                    }}
                  />
                )}

                <h3>Você está conectado!</h3>
                <br />
                <br />

                <button
                  type="button"
                  onClick={() => history.push('/dashboard')}
                >
                  Ir para o Painel de Controle
                </button>

                <br />

                <LoginForm showBtnText />
              </>
            ) : (
              <LoginForm
                showKeepConnected
                showBtnText
                showRecover
                showRegister
                className="login-page"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useRef, useState, useEffect } from 'react';
import { Element } from 'react-scroll';

import ios from '../../../assets/icon-download-ios.png';
import google from '../../../assets/icon-download-google.png';
import zer0 from '../../../assets/img-download-app-zer0.png';
import zer01 from '../../../assets/zero-init.jpeg';
import { motion } from "framer-motion/dist/framer-motion";
import './styles.css';
import * as icon from 'react-icons/fa';

import ReactPlayer from 'react-player'

import video from '../../../assets/videos/maquina.mp4'


 
const Download = () => {
  const [mute, setMute] = useState(true)
  
  const carousel = useRef()
  const item = useRef()
  const [width, setWidth] = useState(0)
  const [cont, setCont] = useState(0)
  const [itemWidth, setItemWidth] = useState(0)
  const sleep = ms => new Promise(
    resolve => {
      setTimeout(resolve, ms)
    }
    // if(imgs.length >= 0){
    //   OnDot(cont+1)
    //   alert(cont)
    //   sleep(5000)
    // }
    // else{
    //   cont = 0 ;
    //   OnDot(cont)
    // }



  );
  useEffect(async () => {
    setWidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)

    OnDot(cont)

  }, [])

  function OnDot(i) {

    setCont(cont + 1)
    // alert(imgs.length)
    setItemWidth(cont * item.current?.offsetWidth)
    sleep(2000).then(() => {

      OnDot(cont)
    })

  }



  return (
    <Element name="download" className="download">

      <div className="pegada-sobre">


        <div className="grid center d-none-lg">
          <ReactPlayer width={"350px"} height={"250px"} muted={mute} onClick={()=> mute== false ? setMute(true):setMute(false)} url={video} />
        </div>
        <div className="grid center d-none-md">
          <ReactPlayer width={"650px"} height={"450px"} muted={mute} onClick={()=> mute== false ? setMute(true):setMute(false)} playing={true} loop={true} url={video} />
        </div>
      </div>


  

    </Element>
  );
};

export default Download;

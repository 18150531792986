import React from 'react';

import Principal from './Principal';
import Sobre from './Sobre';
import Servicos from './Servicos';
import Social from './Social';
import Marcas from './Marcas';
import Clientes from './Clientes';
import Contato from './Contato';
import Mapa from './Mapa';
import Footer from './Footer';

import './style.css';
import '../../css/css.scss'
export default function SuporteAmbiental() {
  return (
    <div className="s-a">
      <Principal />

      <Sobre />

      <Servicos />

      {/* <Social /> */}

      <Marcas />

      <Clientes />

      <Contato />

      <Mapa />

      <Footer />
    </div>
  );
}

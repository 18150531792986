import React, {useRef, useContext} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import {AlertContext} from '../../../../../App';

import api from '../../../../../services/api';
import whichError from '../../../../../services/whichError';

import Input from '../../../../../components/Form/Input';
import Select from '../../../../../components/Form/Select';

Numeral.locale('pt-br');

const statusOptions = [
  {value: 31, label: 'Ativo'},
  {value: 32, label: 'Inativo'},
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },

  optionsBtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  description: {
    minHeight: 1200,
  },
}));

const PointsTrade = ({result}) => {
  const showAlert = useContext(AlertContext);
  const formRef = useRef(null);

  const classes = useStyles();

  const handleSubmit = async unformData => {
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      name: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .typeError('Favor informar um valor válido')
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor o nome da parceria'),
      points: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido')
        .integer('Informe um valor válido')
        .required('Informe os pontos'),
      description: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .required('Informe a descrição'),
      status: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido')
        .integer('Informe um valor válido')
        .required('Informe o status'),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);
    // console.log('unformData :>> ', unformData);
    // return;

    const save = await api
      .put(`/core/partnerships/${result.id}`, data)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (save) {
      // reset();
      showAlert({title: 'Sucesso', msg: 'Alterações salvas!'});
    }
  };

  const iD = {
    name: result ? result.name : '',
    points: result ? result.points : '',
    description: result ? result.description : '',
    status: result ? result.status : '',
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={iD}>
      <Typography color="textPrimary" variant="h5">
        {result.partner.userInformations.name}
      </Typography>

      <br />

      <Input
        label="Item de Troca *"
        name="name"
        className={classes.textInput}
        variant="outlined"
        fullWidth
      />

      <br />

      <Input
        label="Pontos *"
        name="points"
        className={classes.textInput}
        variant="outlined"
        fullWidth
        inputProps={{
          step: 1,
          type: 'number',
        }}
      />

      <br />

      <Input
        label="Descrição"
        name="description"
        multiline
        variant="outlined"
        fullWidth
        className={classes.description}
      />

      <br />

      <Select
        label="Status *"
        name="status"
        options={statusOptions}
        variant="outlined"
        fullWidth
        className={classes.textInput}
      />

      <br />
      <br />

      <Button
        variant="contained"
        color="primary"
        type="submit"
        size="large"
        className={classes.button}
        startIcon={<SaveOutlinedIcon />}
      >
        Salvar
      </Button>
    </Form>
  );
};

export default PointsTrade;

import React from 'react';
import {Switch} from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRoute';

import Home from './pages/Home';
import UserHistory from './pages/UserHistory';

import Mkt from './pages/Mkt';

import Users from './pages/Users';
import UserAdd from './pages/UserAdd';
import User from './pages/User';
import Recyclables from './pages/Recyclables';
import Recyclable from './pages/Recyclable';
import RecyclableAdd from './pages/RecyclableAdd';
import Machines from './pages/Machines';
import Machine from './pages/Machine';
import MachineAdd from './pages/MachineAdd';
import Locations from './pages/Locations';
import Location from './pages/Location';
import Operations from './pages/Operations';
import Operation from './pages/Operation';
import OperationAdd from './pages/OperationAdd';
import OpHistory from './pages/OperationHistory';
import OpReports from './pages/OperationReports';
import Partnerships from './pages/Partnerships';
import Partnership from './pages/Partnership';
import PartnerPartnership from './pages/PartnerPartnership';
import PartnershipAdd from './pages/PartnershipAdd';
import Notifications from './pages/Notifications';

const Empty = () => <></>;

const components = {
  Empty,
  Home,
  UserHistory,
  Mkt,
  Users,
  UserAdd,
  User,
  Recyclables,
  Recyclable,
  RecyclableAdd,
  Machines,
  Machine,
  MachineAdd,
  Locations,
  Location,
  Operations,
  Operation,
  OperationAdd,
  OpHistory,
  OpReports,
  Partnerships,
  Partnership,
  PartnerPartnership,
  PartnershipAdd,
  Notifications,
};

export const userRoutes = [
  {path: '/dashboard', componentName: 'Home'},
  {path: '/dashboard/perfil', componentName: 'Empty'},
  {path: '/dashboard/senha', componentName: 'Empty'},
  {path: '/dashboard/historico', componentName: 'UserHistory'},
  {path: '/dashboard/parcerias', componentName: 'Partnerships'},
  {path: '/dashboard/parceria/:id', componentName: 'PartnerPartnership'},
];

export const admRoutes = [
  {path: '/dashboard/mkt', componentName: 'Mkt'},
  {path: '/dashboard/zer0', componentName: 'Empty'},
  {path: '/dashboard/zer0/usuarios', componentName: 'Users'},
  {path: '/dashboard/zer0/usuarios/incluir', componentName: 'UserAdd'},
  {path: '/dashboard/zer0/usuarios/:category', componentName: 'Users'},
  {path: '/dashboard/zer0/usuario', componentName: 'Users'},
  {path: '/dashboard/zer0/usuario/incluir', componentName: 'UserAdd'},
  {path: '/dashboard/zer0/usuario/:userId', componentName: 'User'},
  {path: '/dashboard/zer0/usuario/:id/historico', componentName: 'UserHistory'},
  {path: '/dashboard/zer0/reciclaveis', componentName: 'Recyclables'},
  {path: '/dashboard/zer0/reciclaveis/incluir', componentName: 'RecyclableAdd'},
  {path: '/dashboard/zer0/reciclaveis/:id', componentName: 'Recyclable'},
  {path: '/dashboard/zer0/reciclavel/incluir', componentName: 'RecyclableAdd'},
  {path: '/dashboard/zer0/reciclavel/:id', componentName: 'Recyclable'},
  {path: '/dashboard/zer0/maquinas', componentName: 'Machines'},
  {path: '/dashboard/zer0/maquinas/incluir', componentName: 'MachineAdd'},
  {path: '/dashboard/zer0/maquina', componentName: 'Machines'},
  {path: '/dashboard/zer0/maquina/incluir', componentName: 'MachineAdd'},
  {path: '/dashboard/zer0/maquina/:id', componentName: 'Machine'},
  {path: '/dashboard/zer0/locais', componentName: 'Locations'},
  {path: '/dashboard/zer0/local', componentName: 'Locations'},
  {path: '/dashboard/zer0/local/:id', componentName: 'Location'},
  {path: '/dashboard/zer0/operacoes', componentName: 'Operations'},
  {path: '/dashboard/zer0/operacao', componentName: 'Operations'},
  {path: '/dashboard/zer0/operacao/incluir', componentName: 'OperationAdd'},
  {path: '/dashboard/zer0/operacao/:id', componentName: 'Operation'},
  {path: '/dashboard/zer0/operacao/:id/historico', componentName: 'OpHistory'},
  {path: '/dashboard/zer0/operacao/:id/relatorios', componentName: 'OpReports'},
  {path: '/dashboard/zer0/parcerias', componentName: 'Partnerships'},
  {path: '/dashboard/zer0/parcerias/:category', componentName: 'Partnerships'},
  {path: '/dashboard/zer0/parceiro', componentName: 'Partnerships'},
  {path: '/dashboard/zer0/parceira', componentName: 'Partnerships'},
  {path: '/dashboard/zer0/parceiro/incluir', componentName: 'PartnershipAdd'},
  {path: '/dashboard/zer0/parceira/incluir', componentName: 'PartnershipAdd'},
  {path: '/dashboard/zer0/parceiro/:id', componentName: 'Partnership'},
  {path: '/dashboard/zer0/parceira/:id', componentName: 'Partnership'},
  {path: '/dashboard/zer0/notificacoes', componentName: 'Notifications'},
];

const Routes = ({classes}) => {
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Switch>
        {userRoutes.map(dr => (
          <PrivateRoute
            key={`${dr.componentName}:${dr.path}`}
            path={dr.path}
            exact
            component={components[dr.componentName]}
          />
        ))}

        {admRoutes.map(dr => (
          <PrivateRoute
            key={`${dr.componentName}:${dr.path}`}
            path={dr.path}
            status={[2]}
            exact
            component={components[dr.componentName]}
          />
        ))}
      </Switch>
    </main>
  );
};

export default Routes;

import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';

import SuporteAmbiental from './pages/SuporteAmbiental';
import PegadaAmbiental from './pages/PegadaAmbiental';
import PegadaDisplay from './pages/PegadaDisplay';
import PegadaMkt from './pages/PegadaMkt';
import Institucional from './pages/SuporteAmbiental/Institucional';

import Login from './pages/Login';
import SignUp from './pages/Login/SignUp';
import Password from './pages/Login/Password';
import Recover from './pages/Login/Recover';
import Logout from './pages/Login/components/Logout';

import Dashboard from './pages/Dashboard';

import {userRoutes, admRoutes} from './pages/Dashboard/routes';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={SuporteAmbiental} />
        <Route path="/login/:redirect" component={Login} />
        <Route path="/login" component={Login} />
        <Route path="/logout/:redirect" component={Logout} />
        <Route path="/logout" component={Logout} />
        <Route path="/signup" component={SignUp} />
        <Route path="/recover" component={Recover} />
        <Route path="/pegada" component={PegadaAmbiental} />
        <Route path="/zero" component={PegadaAmbiental} />
        <Route path="/display/:operation" component={PegadaDisplay} />
        <Route path="/display" component={PegadaDisplay} />
        <Route path="/mkt" component={PegadaMkt} />
        <Route path="/institucional" component={Institucional} />
        <Route path="/institucional/privacidade" component={Institucional} />
        <Route path="/institucional/termos" component={Institucional} />

        <PrivateRoute path="/password" component={Password} />

        {userRoutes.map(dr => (
          <PrivateRoute
            path={dr.path}
            exact
            component={Dashboard}
            key={`base:${dr.componentName}:${dr.path}`}
          />
        ))}

        {admRoutes.map(dr => (
          <PrivateRoute
            path={dr.path}
            exact
            component={Dashboard}
            key={`base:${dr.componentName}:${dr.path}`}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
}

import React, {useState} from 'react';
// import {useHistory} from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';

import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const OptionsBtn = ({options = null, onSelect = () => null}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOption = selectedOpt => {
    onSelect(selectedOpt);
    setAnchorEl(null);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>

      {options && (
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          {options.map(opt =>
            opt.divider ? (
              <Divider key={`${Math.random()}.${opt.label}`} />
            ) : (
              <MenuItem
                key={`${Math.random()}.${opt.label}`}
                onClick={() => handleOption(opt)}
              >
                {opt.label}
              </MenuItem>
            ),
          )}
        </Menu>
      )}
    </div>
  );
};

export default OptionsBtn;

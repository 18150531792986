import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Element } from 'react-scroll';
import { useHistory } from 'react-router-dom';

import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';

import { SocketContext } from '../../../App';

import api from '../../../services/api';

import iconWater from '../../../assets/icon-pegada-water-p.png';
import iconEnergy from '../../../assets/icon-pegada-energy-p.png';
import iconCO2 from '../../../assets/icon-pegada-co2-p.png';

Numeral.locale('pt-br');

const Calculadora = () => {
  const io = useContext(SocketContext);
  const history = useHistory();

  const LoginData = useSelector(state => state.Login);
  const { userId, token } = LoginData;

  const [pegada, setPegada] = useState({ water: 0, energy: 0, co2: 0 });
  const [pegadaSrc, setPegadaSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    setPegadaSrc(null);
    console.log(userId)
    const getPegada = await api
      .get(userId ? `/core/pegada` : '/pegada', { headers: { apptoken: token } })
      // .get(userId ? `/core/pegada` : '/pegada')
      .catch(err => {
        // alert('Erro ao buscar dados!');
        console.log('err :>> ', err);
      });
    if (getPegada) setPegada(getPegada.data);

    setIsLoading(false);
  }, [userId, token]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    io.on('pegada', data => {
      setPegada(data);
    });
  }, [io]);

  const handleCalculadora = async filter => {
    setIsLoading(true);

    setPegadaSrc(filter);
    const handleFilter = f => {
      switch (f) {
        case 'full': {
          return 'pegada';
        }

        case 'user': {
          return `core/pegada`;
        }

        case 'santosreis': {
          return `pegada?o=6a12b02e-76c6-11eb-94f4-0242ac110002`;
        }

        case 'varanda': {
          return `pegada?o=ae1755d0-7b6e-486e-b92d-ffe567fdd8ec`;
        }

        case 'novo': {
          return `pegada?o=82eac9bb-d6f7-4992-99b1-c95966fc99dd`;
        }
        case 'outeiro': {
          return `pegada?o=c495dbe7-516b-4001-8416-2f625d60300a`;
        }

        // case "teste": {
        //   return `?o=ae1755d0-7b6e-486e-b92d-ffe567fdd8ec`;
        // }

        default: {
          return 'pegada';
        }
      }
    };
    const route = handleFilter(filter);

    const getPegada = await api
      .get(`/${route}`, { headers: { apptoken: token } })
      .catch(err => {
        // alert('Erro ao buscar dadddos!');
        userId = null;
        console.log('err :>> ', err);
      });
    if (getPegada) setPegada(getPegada.data);
    setIsLoading(false);
    console.log('3232')
  };

  return (
    <div className="calculadora-content">
      <div className='d-flex center calc-content-title'>
        <h3>Calculadora Ambiental </h3>
      </div>
      <Element name="projetos" className="pegada-projects">


        <ul className="pegada-project">
          {userId && (
            <li
              className={
                (userId && !pegadaSrc) || pegadaSrc === 'user' ? 'bold' : 'link'
              }
              onClick={() => handleCalculadora('user')}
              aria-hidden="true" // provisório
            >
              Sua Pegada
            </li>
          )}

          <li
            className={
              (!userId && !pegadaSrc) || pegadaSrc === 'full' ? 'bold' : 'link'
            }
            onClick={() => handleCalculadora('full')}
            aria-hidden="true" // provisório
          >
            Todos os projetos
          </li>

          {/* <li
            className={pegadaSrc === 'santosreis' ? 'bold' : 'link'}
            onClick={() => handleCalculadora('santosreis')}
            aria-hidden="true" // provisório
          >
            Eco Galpão Santos Reis
          </li> */}

          <li
            className={pegadaSrc === 'outeiro' ? 'bold' : 'link'}
            onClick={() => handleCalculadora('outeiro')}
            aria-hidden="true" // provisório
          >
            Outeiro das Brisas
          </li>
          <li
            className={pegadaSrc === 'varanda' ? 'bold' : 'link'}
            onClick={() => handleCalculadora('varanda')}
            aria-hidden="true" // provisório
          >
            Posto Varanda
          </li>

          <li
            className={pegadaSrc === 'novo' ? 'bold' : 'link'}
            onClick={() => handleCalculadora('novo')}
            aria-hidden="true" // provisório
          >
            Novo Nordisk
          </li>

          {!userId && (
            <li
              className="link btn-link"
              onClick={() => history.push('/login')}
              aria-hidden="true" // provisório
            >
              Logue para ver sua Pegada
            </li>
          )}
        </ul>
      </Element>

      <Element name="calculadora" className="pegada-container">
        <div className="pegada-item">
          <div className="pegada-icon-container">
            <img src={iconWater} alt="Água" />
          </div>

          <div className="pegada-text-container">
            {isLoading ? (
              <Dots color="#727981" size={10} speed={1} animating />
            ) : (
              <p>{`${Numeral(pegada.water).format('0,0')}`} litros de água</p>
            )}
          </div>
        </div>

        <div className="pegada-item">
          <div className="pegada-icon-container">
            <img src={iconEnergy} alt="Energia" />
          </div>

          <div className="pegada-text-container">
            {isLoading ? (
              <Dots color="#727981" size={10} speed={1} animating />
            ) : (
              <p>{`${Numeral(pegada.energy).format('0,0')}`} kWh de energia</p>
            )}
          </div>
        </div>

        <div className="pegada-item">
          <div className="pegada-icon-container">
            <img src={iconCO2} alt="CO2" />
          </div>

          <div className="pegada-text-container">
            {isLoading ? (
              <Dots color="#727981" size={10} speed={1} animating />
            ) : (
              <p>{`${Numeral(pegada.co2).format('0,0')}`} Kg de CO₂eq</p>
            )}
          </div>
        </div>
      </Element>
    </div>
  );
};

export default Calculadora;

import React, {useContext, useState, useCallback, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import {Link} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/ListAltOutlined';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';
import AddColeta from '../../components/AddColeta';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },

  optionsBtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const partnerships = {
  9: 'Troca de pontos',
  10: 'Coleta',
  12: 'Corporativo',
};

const User = ({history, match}) => {
  const showAlert = useContext(AlertContext);
  const {params} = match;
  const {userId} = params;

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);

  const [isLoadingPegada, setIsLoadingPegada] = useState(true);
  const [pegada, setPegada] = useState({water: 0, energy: 0, co2: 0});

  const [addColeta, setAddColeta] = useState(false);

  const loadPegada = useCallback(async () => {
    setIsLoadingPegada(true);

    await api
      .get(`/core/pegada?u=${userId}`)
      .then(async res => {
        setPegada(res.data);
        setIsLoadingPegada(false);
      })
      .catch(async e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoadingPegada(false);
      });
  }, [showAlert, userId]);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    await api
      .get(`/core/users/${userId}`)
      .then(async res => {
        setResult(res.data);
        loadPegada();
        setIsLoading(false);
      })
      .catch(async e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [userId, loadPegada, showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData, loadPegada]);

  const onCloseColeta = () => {
    setAddColeta(false);
    loadPegada();
  };

  return (
    <>
      <Header title="Detalhes do Usuário" />

      <Paper className={classes.root}>
        {isLoading ? (
          <Loading />
        ) : !result ? (
          // <p>REDIRECIONAR</p>
          <></>
        ) : (
          <>
            <Typography color="textPrimary" variant="h5">
              {result.userInformations.name}
            </Typography>

            <br />

            <Typography variant="h6">E-mails</Typography>
            {result.userEmails.map(ue => (
              <Typography key={ue.email}>{ue.email}</Typography>
            ))}

            {result.status === 4 && (
              <>
                <br />

                <Typography variant="h6">Pegada</Typography>

                {isLoadingPegada ? (
                  <Loading />
                ) : (
                  <>
                    <Typography variant="subtitle2">
                      Água: {pegada.water}
                    </Typography>
                    <Typography variant="subtitle2">
                      Energia: {pegada.energy}
                    </Typography>
                    <Typography variant="subtitle2">
                      CO2: {pegada.co2}
                    </Typography>
                  </>
                )}
              </>
            )}

            <Box mt={3} />
            <Divider pt={2} />
            <Box mt={3} />

            {result.status === 4 ? (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.optionsBtns}
                  onClick={() => setAddColeta(result.id)}
                  startIcon={<AddIcon />}
                >
                  Adicionar coleta
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.optionsBtns}
                  onClick={() =>
                    history.push(`/dashboard/zer0/usuario/${userId}/historico`)
                  }
                  startIcon={<ListIcon />}
                >
                  Histórico
                </Button>
              </Box>
            ) : result.partnerships.length > 0 ? (
              <>
                <Typography variant="h6">Parcerias</Typography>

                {result.partnerships.map(up => (
                  <Link
                    component={RouterLink}
                    to={`/dashboard/zer0/parceiro/${up.id}`}
                  >
                    <Typography>
                      {`${up.name} (${partnerships[up.category_id]})`}
                    </Typography>
                  </Link>
                ))}
              </>
            ) : (
              <Typography variant="caption">
                Não há parceria cadastrada para este usuário
              </Typography>
            )}

            {addColeta && (
              <AddColeta
                eventId={addColeta}
                userId={addColeta}
                onClose={onCloseColeta}
              />
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default User;

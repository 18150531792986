import React, {useContext, useState, useCallback, useEffect} from 'react';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import EditLocationRoundedIcon from '@material-ui/icons/EditLocationRounded';
import LocationOffRoundedIcon from '@material-ui/icons/LocationOffRounded';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

import EditLocation from './EditLocation';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },

  editBtn: {
    marginTop: 15,
    marginBottom: 7,
  },
}));

const Location = ({history, match}) => {
  const showAlert = useContext(AlertContext);

  const {params} = match;
  const {id} = params;

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(null);

  const [editLocation, setEditLocation] = useState(null);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    await api
      .get(`/core/locations/${id}`)
      .then(async res => {
        const {name, location} = res.data;
        const [latitude, longitude] = location.coordinates;

        setResult({id, name, latitude, longitude});
        setIsLoading(false);
      })
      .catch(async e => {
        showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
        setIsLoading(false);
      });
  }, [id, showAlert]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleDelete = async () => {
    setIsLoading(true);

    await api
      .delete(`/core/locations/${id}`)
      .then(async () => {
        setIsLoading(false);
        history.goBack();
      })
      .catch(async e => {
        showAlert({
          title: 'Não foi possível apagar',
          msg: whichError(e).errorMsg,
        });
        setIsLoading(false);
      });
  };

  const askDelete = () => {
    showAlert({
      title: 'Confirmação',
      msg: 'Deseja realmente apagar esta local de operação?',
      onOk: handleDelete,
    });
  };

  return (
    <>
      <Header title="Local de operação" />

      <Paper className={classes.root}>
        {isLoading ? (
          <Loading />
        ) : !result ? (
          // <p>REDIRECIONAR</p>
          <></>
        ) : (
          <>
            <Typography variant="h5">{result.name}</Typography>

            <br />

            <iframe
              title="Google Maps"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCL5URexgc7aYas58fuenbB7Y8k4mRmTVY&q=${result.latitude},${result.longitude}`}
              width="100%"
              height="500"
              style={{border: 0}}
            />

            <Divider pt={2} />

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setEditLocation(result)}
                startIcon={<EditLocationRoundedIcon />}
                className={classes.editBtn}
              >
                Editar local
              </Button>

              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={askDelete}
                startIcon={<LocationOffRoundedIcon />}
                className={classes.editBtn}
              >
                Apagar local
              </Button>
            </Grid>

            {editLocation && (
              <EditLocation
                location={editLocation}
                onSave={() => loadData()}
                onClose={() => setEditLocation(false)}
              />
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default Location;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/background-servicos.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".servicos {\n  background: #f5f5f5 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: cover;\n  background-position: center top;\n  background-color: #d0e39e;\n  padding: 10px 10px 40px 10px;\n  color: #13a15a;\n}\n\n.servicos-title {\n  text-align: center;\n  margin-top: 20px;\n}\n\n.servicos-cards {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n}\n\n.servicos-pre-card {\n  margin: 10px;\n}\n\n.servicos-icons-container {\n  padding: 20px;\n}\n\n.servicos-icons {\n  height: 200px;\n  width: auto;\n  margin-bottom: 30px;\n}\n\n.servicos-card {\n  flex: 1 1;\n  background-color: #13a15a;\n  color: #f6f9f8;\n  max-width: 420px;\n  padding: 20px 10px;\n  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.75);\n}\n\n.servicos-card h3 {\n  text-align: center;\n  margin: 10px;\n  text-transform: uppercase;\n}\n\n.servicos-card ul {\n  margin-left: 30px;\n}\n", ""]);
// Exports
module.exports = exports;

import React, {useContext, useRef, useState} from 'react';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import AddIcon from '@material-ui/icons/Add';

import {AlertContext} from '../../../../App';

import api from '../../../../services/api';
import whichError from '../../../../services/whichError';

import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import ImgPicker from '../../../../components/ImgPicker';

import Header from '../../components/Header';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    padding: theme.spacing(2),
  },

  textInput: {
    maxWidth: 250,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  number: {
    width: 121,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  description: {
    maxWidth: 508,
  },
}));

const options = [
  {value: 5, label: 'Metal'},
  {value: 6, label: 'Plástico'},
  {value: 7, label: 'Vidro'},
  {value: 8, label: 'Papel'},
];

const RecyclableAdd = ({history}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();
  const formRef = useRef(null);

  const [img, setImg] = useState(null);

  const handleSubmit = async (unformData, {reset}) => {
    // console.log('img :>> ', img);
    // console.log('unformData :>> ', unformData);
    // return;
    formRef.current.setErrors({});

    const schema = Yup.object().shape({
      category: Yup.number()
        .transform((cv, or) =>
          or === '' || or === '0' ? null : or === '$null$' ? 0 : cv,
        )
        .nullable(true)
        .typeError('Favor informar um id válido')
        .positive('Favor informar um id válido')
        .required('Favor informar o id da categoria'),
      name: Yup.string()
        .transform((cv, ov) => (ov === '' ? null : cv))
        .nullable(true)
        .trim()
        .min(1, 'O nome deve ter no mínimo 1 caractere.')
        .required('Favor informar o id da publicação 1'),
      weight: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido')
        .required('Informe o peso'),
      water: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido')
        .required('Informe a economia de água'),
      energy: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido')
        .required('Informe a economia de energia'),
      co2: Yup.number()
        .transform((cv, or) => (or === '' ? null : cv))
        .nullable(true)
        .typeError('Informe um valor válido')
        .positive('Informe um valor válido')
        .required('Informe a economia de CO2'),
      description: Yup.string()
        // .transform((cv, ov) => (ov === '' ? null : cv))
        // .nullable(true)
        .trim(),
    });

    try {
      await schema.validate(unformData, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }

      return;
    }

    const data = schema.cast(unformData);
    // console.log('unformData :>> ', unformData);
    // return;

    // unformData.imgAction =
    //   userHasImg && imgAction && img === null
    //     ? 'remove'
    //     : userHasImg && !imgAction && img === null
    //     ? 'keep'
    //     : 'update';

    // eslint-disable-next-line no-undef
    const formData = new FormData();

    Object.entries(data).forEach(field =>
      formData.append(
        field[0],
        field[1] === undefined || field[1] === null ? '' : field[1],
      ),
    );

    if (img) formData.append('img', img);

    const save = await api
      .post(`/core/items`, formData)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (save) {
      reset();
      history.goBack();
    }
  };

  const iD = {
    // category: '7',
    // name: 'Teste',
    // weight: '0.123',
    // water: '0.123',
    // energy: '0.123',
    // co2: '0.123',
  };

  return (
    <>
      <Header title="Adicionar Reciclável" />

      <Paper className={classes.root}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={iD}>
          {/* <ImgPicker height={153} width={272} onChange={setImg} /> */}
          <ImgPicker height={150} width={150} onChange={setImg} />

          <Select
            label="Categoria *"
            name="category"
            options={options}
            variant="outlined"
            fullWidth
            className={classes.textInput}
          />

          <Input
            label="Nome do reciclável *"
            name="name"
            className={classes.textInput}
            variant="outlined"
            fullWidth
          />

          <br />
          <br />

          <Input
            label="Água *"
            name="water"
            className={classes.number}
            variant="outlined"
            inputProps={{
              step: 0.001,
              min: 0.001,
              max: 100,
              type: 'number',
            }}
          />

          <Input
            label="Energia *"
            name="energy"
            className={classes.number}
            variant="outlined"
            inputProps={{
              step: 0.001,
              min: 0.001,
              max: 100,
              type: 'number',
            }}
          />

          <Input
            label="CO2 *"
            name="co2"
            className={classes.number}
            variant="outlined"
            inputProps={{
              step: 0.001,
              min: 0.001,
              max: 100,
              type: 'number',
            }}
          />

          <Input
            label="Peso *"
            name="weight"
            className={classes.number}
            variant="outlined"
            inputProps={{
              step: 0.001,
              min: 0.001,
              max: 100,
              type: 'number',
            }}
          />

          <br />
          <br />

          <Input
            label="Descrição"
            name="description"
            multiline
            variant="outlined"
            fullWidth
            className={classes.description}
            // inputProp
          />

          <br />
          <br />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            className={classes.button}
            startIcon={<AddIcon />}
          >
            Adicionar
          </Button>
        </Form>
      </Paper>
    </>
  );
};

export default RecyclableAdd;

import React, {useContext, useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import {format} from 'date-fns';
import Numeral from 'numeral';
import 'numeral/locales/pt-br';

import {makeStyles} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import VideoIcon from '@material-ui/icons/YouTube';
import PhotoIcon from '@material-ui/icons/PhotoSizeSelectActualTwoTone';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import BackupIcon from '@material-ui/icons/Backup';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SearchIcon from '@material-ui/icons/Search';

import {AlertContext} from '../../../../../App';

import baseURL from '../../../../../configs/baseURL';

import api from '../../../../../services/api';
import whichError from '../../../../../services/whichError';

import TimeSlider from './TimeSlider';
import Upload from './Upload';

Numeral.locale('pt-br');

const useStyles = makeStyles(theme => ({
  newCampaign: {maxWidth: 450},
  youtube: {width: 215},
  gallery: {maxWidth: 350},
  button: {
    margin: theme.spacing(1),
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const columns = [
  {
    id: 'createdAt',
    label: 'Data',
    minWidth: 80,
    width: 80,
    format: 'date',
  },
  {id: 'img', label: 'Mídia', align: 'center', minWidth: 100, format: 'img'},
];

const NewMkt = ({refreshCurrentCampaign = () => null}) => {
  const showAlert = useContext(AlertContext);
  const classes = useStyles();

  const [newCampaign, setNewCampaign] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [empty, setEmpy] = useState('loading');
  const [resultInfo, setResultInfo] = useState({
    filter: '',
    page: 1,
    pages: 1,
    total: 0,
    limit: 10,
    orderBy: 'date',
    sortBy: 'DESC',
  });

  // const [yt, setYt] = useState('ZIJAgEStXPA');
  const [yt, setYt] = useState('');
  const [ytPreview, setYtPreview] = useState(false);

  const loadData = useCallback(
    async (
      filter = '',
      page = 1,
      limit = 10,
      orderBy = 'date',
      sortBy = 'DESC',
    ) => {
      await api
        .get(
          `/core/mkt/media?&p=${page}&l=${limit}&o=${orderBy}&s=${sortBy}&f=${filter}`,
        )
        .then(async res => {
          if (res.data) {
            const {media, ...info} = res.data;

            setResult(media);
            setResultInfo(info);
            setEmpy('empty');
            setIsLoading(false);
          }
        })
        .catch(e => {
          showAlert({title: 'Atenção', msg: whichError(e).errorMsg});
          setIsLoading(false);
        });
    },
    [showAlert],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const submitCampaign = async () => {
    const save = await api
      .post(`/core/mkt`, newCampaign)
      .catch(e => showAlert({title: 'Atenção', msg: whichError(e).errorMsg}));

    if (save.data.saved) {
      refreshCurrentCampaign(newCampaign);
      setNewCampaign([]);
    }
  };

  const handleAddCampaignItem = (mediaType, mediaId) => {
    setNewCampaign(prev => [...prev, {[mediaType]: mediaId, timer: 15}]);
  };

  const handleItems = (itemIndex, itemAction) => {
    function moveItem(data, from, to) {
      const f = data.splice(from, 1)[0];
      data.splice(to, 0, f);
      return data;
    }

    switch (itemAction) {
      case 'del':
        setNewCampaign(prev => {
          const newCamp = [...prev];
          newCamp.splice(itemIndex, 1);
          return newCamp;
        });

        break;

      case 'up':
        setNewCampaign(prev =>
          itemIndex > 0 ? moveItem([...prev], itemIndex, itemIndex - 1) : prev,
        );

        break;

      case 'down':
        setNewCampaign(prev =>
          itemIndex < prev.length
            ? moveItem([...prev], itemIndex, itemIndex + 1)
            : prev,
        );

        break;

      default:
        break;
    }
  };

  const handleItemTimer = (index, timer) => {
    setNewCampaign(prev => {
      const before = [...prev].splice(0, index);
      const after = [...prev].splice(index + 1, prev.length - 1);

      const current = [...prev].splice(index, 1)[0];
      current.timer = timer;

      return [...before, current, ...after];
    });
  };

  const handleChangePage = (event, newPage) => {
    loadData(
      resultInfo.filter,
      newPage + 1,
      resultInfo.limit,
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  const handleChangeRowsPerPage = ({target}) => {
    loadData(
      resultInfo.filter,
      1,
      parseInt(target.value),
      resultInfo.orderBy,
      resultInfo.sortBy,
    );
  };

  return (
    <>
      <Typography variant="h6">Nova campanha</Typography>

      {newCampaign.length > 0 && (
        <Paper className={classes.newCampaign}>
          <TableContainer>
            <Table aria-label="sticky table">
              <TableBody>
                {newCampaign.map((campaignItem, index) => {
                  return (
                    <TableRow
                      hover
                      style={{height: 120}}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${index}.${campaignItem.id}`}
                    >
                      <TableCell style={{width: 80, textAlign: 'center'}}>
                        <IconButton onClick={() => handleItems(index, 'up')}>
                          <ArrowUp />
                        </IconButton>

                        <Typography>{index + 1}</Typography>

                        <IconButton onClick={() => handleItems(index, 'down')}>
                          <ArrowDown />
                        </IconButton>
                      </TableCell>

                      <TableCell style={{width: 60, textAlign: 'center'}}>
                        {campaignItem.img ? <PhotoIcon /> : <VideoIcon />}
                      </TableCell>

                      <TableCell>
                        {campaignItem.img ? (
                          <>
                            <img
                              src={`${baseURL.addr}/imgs/mkt/${campaignItem.img}`}
                              alt={campaignItem.img}
                              style={{width: 192, height: 108}}
                            />

                            <TimeSlider
                              itemIndex={index}
                              timer={campaignItem.timer}
                              onTimerChange={handleItemTimer}
                            />
                          </>
                        ) : (
                          <img
                            src={`https://img.youtube.com/vi/${campaignItem.yt}/mqdefault.jpg`}
                            alt="Video Thumbnail Preview"
                            style={{width: 192, height: 108}}
                          />
                        )}
                      </TableCell>

                      {/* <TableCell>id.</TableCell> */}

                      <TableCell style={{width: 80, textAlign: 'center'}}>
                        <IconButton onClick={() => handleItems(index, 'del')}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            variant="contained"
            color="primary"
            onClick={submitCampaign}
            className={classes.button}
            startIcon={<BackupIcon />}
          >
            Publicar
          </Button>
        </Paper>
      )}

      <Box mt={3} />
      <Divider pt={2} />
      <Box mt={3} />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Vídeos do YouTube</Typography>
          <Paper className={classes.youtube}>
            <form className={classes.form} noValidate autoComplete="off">
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Id. do YouTube
                </InputLabel>

                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  value={yt}
                  onChange={event => {
                    const {value} = event.target;
                    setYt(value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setYtPreview(yt)}
                        onMouseDown={() => null}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={110}
                />
              </FormControl>

              {ytPreview && (
                <>
                  <img
                    src={`https://img.youtube.com/vi/${ytPreview}/mqdefault.jpg`}
                    alt="Video Thumbnail Preview"
                    style={{width: 192, height: 108}}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddCampaignItem('yt', `${ytPreview}`)}
                    // size="large"
                    className={classes.button}
                    startIcon={<PlaylistAddIcon />}
                  >
                    Adicionar
                  </Button>
                </>
              )}
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Lista de imagens</Typography>

          <Paper className={classes.gallery}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={`${column.id}.${column.label}`}
                        align={column.align}
                        style={{
                          ...(column.width ? {width: column.width} : {}),
                          ...(column.minWidth
                            ? {minWidth: column.minWidth}
                            : {}),
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {result.map(row => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => handleAddCampaignItem('img', row.img)}
                      >
                        {columns.map(column => {
                          const {id, align, format: valFormat} = column;

                          const value =
                            column.id === 'name'
                              ? row.userInformations[id]
                              : row[id];

                          return (
                            <TableCell key={`${id}.${valFormat}`} align={align}>
                              {valFormat === 'number' ? (
                                Numeral(value).format('0,0')
                              ) : valFormat === 'price' ? (
                                Numeral(value).format('$0,0')
                              ) : valFormat === 'date' ? (
                                format(new Date(value), 'dd/MM/yyyy HH:mm')
                              ) : valFormat === 'bool' ? (
                                <Checkbox checked={!!value} disabled />
                              ) : valFormat === 'img' ? (
                                <img
                                  src={`${baseURL.addr}/imgs/mkt/${value}`}
                                  alt={value}
                                  style={{width: 192, height: 108}}
                                />
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={resultInfo.total}
              rowsPerPage={resultInfo.limit}
              page={resultInfo.page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Exibir:"
              // labelDisplayedRows={({from, to, count, page}) => {
              //   console.log('a', a);
              //   console.log('b', b);
              //   console.log('c', c);
              // }}
            />

            <div style={{textAlign: 'center', paddingBottom: 20}}>
              <Upload onUploaded={loadData} />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default NewMkt;
